import React from 'react'
import {CompanyUser} from "@app/mock/MockCompanyData";

interface CompanyUserDetailsProps {
    currentUser: CompanyUser | undefined
}

export function CompanyUserDetails({currentUser}: CompanyUserDetailsProps) {
    return (
        <div className={'pb-6 space-y-4'}>
            <p className={'font-roboto text-lg font-semibold text-primary'}>User Information</p>
            <div className={'flex flex-col gap-y-3 font-medium'}>
                <div className={'flex items-center gap-x-1'}>
                    <p>Name:</p> <p className={'font-semibold'}>{currentUser?.name}</p>
                </div>
                <div className={'flex items-center gap-x-1'}>
                    <p>Email:</p> <p className={'font-semibold'}>{currentUser?.email}</p>
                </div>
                <div className={'flex items-center gap-x-1'}>
                    <p>Mobile No.:</p> <p className={'font-semibold'}>{currentUser?.mobileNo}</p>
                </div>
            </div>
        </div>
    )
}