import React from "react";

export function IDEALIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
            <g clipPath="url(#clip0_7907_2466)">
                <path
                    d="M0.225281 0.225098V19.7865H11.6298C19.1529 19.7865 22.4153 15.5734 22.4153 9.98665C22.4153 4.42158 19.1529 0.225098 11.6298 0.225098H0.225281Z"
                    fill="white"/>
                <path
                    d="M1.45789 1.45898H11.6313C18.5363 1.45898 21.183 5.22973 21.183 9.98644C21.183 15.6902 17.487 18.5522 11.6313 18.5522H1.45789V1.45898ZM2.44414 2.44524V17.5665H11.6313C17.178 17.5665 20.1958 14.9819 20.1958 9.98644C20.1958 4.85254 16.9244 2.44494 11.6313 2.44494H2.44414V2.44524Z"
                    fill="black"/>
                <path d="M3.67673 11.0984H6.61771V16.3327H3.67673V11.0984Z" fill="black"/>
                <path
                    d="M5.14691 10.2889C6.15636 10.2889 6.97469 9.47054 6.97469 8.46109C6.97469 7.45163 6.15636 6.6333 5.14691 6.6333C4.13745 6.6333 3.31912 7.45163 3.31912 8.46109C3.31912 9.47054 4.13745 10.2889 5.14691 10.2889Z"
                    fill="black"/>
                <path
                    d="M12.7852 9.31627V10.1638H10.6897V6.76274H12.7183V7.60999H11.5369V8.00377H12.654V8.85133H11.5369V9.31657L12.7852 9.31627ZM13.1518 10.1644L14.1773 6.76153H15.3827L16.4079 10.1644H15.526L15.3336 9.50622H14.2252L14.0326 10.1644H13.1518ZM14.4737 8.65957H15.087L14.8056 7.69834H14.7544L14.4737 8.65957ZM16.8339 6.76183H17.6818V9.31657H18.9367C18.5924 4.68108 14.9465 3.67944 11.6316 3.67944H8.09875V6.76364H8.62157C9.57497 6.76364 10.1668 7.41009 10.1668 8.45032C10.1668 9.52371 9.58914 10.1644 8.62157 10.1644H8.09875V16.3352H11.6319C17.0188 16.3352 18.8948 13.8333 18.9611 10.1638H16.8339V6.76183ZM8.09814 7.6109V9.31657H8.62157C8.9843 9.31657 9.31958 9.21164 9.31958 8.45002C9.31958 7.70618 8.94661 7.6109 8.62157 7.6109H8.09814Z"
                    fill="#D50072"/>
            </g>
            <defs>
                <clipPath id="clip0_7907_2466">
                    <rect width="22.42" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}