import React, {FC} from 'react';
import BillingBadge, {BillingPaymentStatus} from "@pages/merchant/settings/billing/components/BillingBadge";
import {BillingDate} from "@app/entities/Billing.types";


export type BillingItem = {
    company: string;
    invoice: string
    amount: number;
    invoiceDate: BillingDate;
    status: BillingPaymentStatus;
}

type Props = BillingItem
const BillingItemComponent: FC<Props> = ({
    company,
    amount,
    invoiceDate,
    status
}) => {

    return (
        <div className={'px-shell pt-3'}>
            <div className={'grid grid-cols-2 gap-y-0.5'}>
                <span className={'font-semibold text-lg text-primary'}>{company}</span>
                <div className={'font-medium text-md text-primary text-right'}>€{amount}</div>
                <span className={'font-medium text-sm text-neutral-700'}>{invoiceDate}</span>
                <div>
                    <BillingBadge className={'float-right'} status={status}/>
                </div>
            </div>
        </div>
    );
};

export default BillingItemComponent;
