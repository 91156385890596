import {APP_NAME} from "@config/variables";
import {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {ChildRoute, IndexRoute} from "@config/router.config";
import Routes from "@src/Router";

/**
 *
 */
export interface RouteItem {
    title: string,
    path: string,
    parent: RouteItem | null,
}

/**
 *
 */
type ChildRouteDef = ChildRoute | Omit<ChildRoute, 'index'>;

/**
 *
 */
const getRouteList = () => {

    const appIndexRoute = Routes.find(route => route.path == '/');
    const rootListItem: RouteItem = {
        title: appIndexRoute?.title ?? APP_NAME,
        path: appIndexRoute?.path ?? '/',
        parent: null
    }


    const findFauxParent = (path: string, nextRoutes?: ChildRoute[], prevPath?: string): IndexRoute | ChildRoute | null => {
        const routeSet: (ChildRoute | IndexRoute)[] = (nextRoutes ?? Routes);
        let parent = routeSet.find(route => route.path
            && path?.includes(route.path)
            && route.path != '/'
        );

        if (!parent?.path) {
            return null
        }

        // @todo prepend prev parent path in front of new parent path
        let newPath = `${prevPath ?? ''}/${parent.path}`
        if ((parent.children?.length ?? 0) > 0) {
            const newParent = findFauxParent(path, parent.children ?? [], newPath)
            if (newParent !== null) {
                parent = newParent;
                if (newParent.path) {
                    newPath = newParent.path
                }
            }
        }

        return {
            ...parent,
            path: newPath
        };
    }


    const parseRoutes = (
        parentRoute: IndexRoute,
        indexRoute: ChildRoute | undefined,
        currentRoute: ChildRouteDef,
        prev: RouteItem | null = null
    ): RouteItem[] => {

        const pathUri = currentRoute.path ? `/${currentRoute.path}` : '';
        const initialPath = `/${parentRoute.path}${pathUri}`;
        let prevItem = prev;
        let hasFauxParent = false;
        if ((currentRoute.path?.split('/') ?? []).length > 1) {
            const fauxParent = findFauxParent(initialPath)
            if (fauxParent?.path) {
                hasFauxParent = true
                prevItem = {
                    title: fauxParent.title ?? APP_NAME,
                    path: fauxParent.path,
                    parent: null
                }
            }
        }

        if (!prevItem && indexRoute && !("index" in currentRoute && currentRoute.index)) {
            prevItem = {
                title: indexRoute.title,
                path: parentRoute.path != currentRoute.path ? `/${parentRoute.path}` : rootListItem.path,
                parent: null
            }
        }

        let path = prevItem?.path && !hasFauxParent
            ? `${prevItem.path}/${currentRoute.path ?? ''}`
            : initialPath

        if (path == '//') {
            path = '/'
        }

        const routeListItem: RouteItem = {
            title: currentRoute.title,
            path,
            parent: prevItem
        };

        const routeListItems: RouteItem[] = [routeListItem];
        currentRoute.children?.forEach(child => {
            routeListItems.push(...parseRoutes(parentRoute, indexRoute, child, routeListItem))
        })

        return routeListItems;
    }

    const arr: RouteItem[] = []
    Routes.forEach(parentRoute => {
        const indexRoute = parentRoute.children.find(c => c.index);
        parentRoute.children.forEach(childRoute => {
            arr.push(...parseRoutes(parentRoute, indexRoute, childRoute))
        })
    })

    return arr;
}


/**
 *
 */
export const useRouteList = () => useMemo((): RouteItem[] => getRouteList(), [])


/**
 *
 * @param path
 */
const routeNotFoundError = (path: string) => Error(`Route '${path}' not found`)


/**
 *
 * @param path
 * @param contestant
 */
const testRoute = (path: string, contestant: RouteItem) => {

    const contestantParts = contestant.path.split('/');
    const pathParts = path.split('/');

    if (pathParts.length == 0 && contestantParts.length == 0) {
        return path;
    }

    if (pathParts[1] != contestantParts[1]) {
        return undefined;
    }

    let lastIndex = 2;
    for (let i = 1; i < contestantParts.length; i++, lastIndex++) {
        const contestantItem = contestantParts[i];
        const pathItem = pathParts[i];
        if (contestantItem.includes(':')) {
            continue;
        }
        if (contestantItem != pathItem) {
            return undefined;
        }
    }
    return pathParts.slice(0, lastIndex-1).join('/')
}

/**
 *
 * @param path
 * @param routeList
 */
const findRouteItem = (path: string, routeList: RouteItem[]): RouteItem => {
    let parentPath: string|null = null;
    const routeItem = routeList.find(contestant => {
        const parent = testRoute(path, contestant)
        if (parent != undefined && parent != path) {
            parentPath = parent
        }
        return parent == path
    })
    if (!routeItem) {
        throw routeNotFoundError(path)
    }

    if (routeItem.parent != null && parentPath != null) {
        routeItem.parent.path = parentPath;
    }

    return routeItem;
}


/**
 *
 * @param path
 * @param routeList
 */
const findRouteIndex = (path: string, routeList: RouteItem[]): number => {
    const routeIndex = routeList.findIndex((contestant) => testRoute(path, contestant) != undefined)
    if (routeIndex < 0) {
        throw routeNotFoundError(path)
    }
    return routeIndex;
}

/**
 *
 */
type RouteItemReturnType = {
    item: RouteItem,
    index: number
}


/**
 *
 * @param path
 */
export const getRouteItem = (path: string): RouteItemReturnType => {
    const routeList = getRouteList();
    return {
        item: findRouteItem(path, routeList),
        index: findRouteIndex(path, routeList)
    }
}

type RouteItemHookReturnType = RouteItemReturnType & {
    reset: () => void
}

/**
 *
 * @param path
 */
export const useRouteItem = (path: string): RouteItemHookReturnType => {

    const routeList = useRouteList()
    const [shouldReset, setShouldReset] = useState(false)
    const [itemState, setItemState] = useState(findRouteItem(path, routeList))
    const [indexState, setIndexState] = useState(findRouteIndex(path, routeList))

    const item = useMemo(() => {
        if (!shouldReset) {
            return itemState;
        }
        const routeItem = findRouteItem(path, routeList)
        setItemState(routeItem);
        return routeItem;
    }, [shouldReset])

    const index = useMemo(() => {
        if (!shouldReset) {
            return indexState;
        }
        const routeIndex = findRouteIndex(path, routeList);
        setIndexState(routeIndex)
        return routeIndex;
    }, [shouldReset])

    useEffect(() => {
        if (shouldReset) {
            setShouldReset(false);
        }
    }, [shouldReset]);

    const reset = () => setShouldReset(true);

    return {
        item,
        index,
        reset
    }
}


/**
 *
 */
export const useCurrentRoute = (): RouteItemHookReturnType => {
    const loc = useLocation();
    return useRouteItem(loc.pathname);
}
