import React, {useState} from 'react'
import {ChevronRight} from "@mui/icons-material";
import {CreatePaymentLinkSuccess} from "@pages/merchant/payment/index/components/CreatePaymentLinkSuccess";
import PullOver from "@components/pullover/PullOver.component";
import {z} from "zod";
import {FormFields} from "@components/form/fields/FormField.component";
import PullOverForm from "@components/pullover/PullOverForm.component";
import useGql from "@modules/graphql/useGql";


const shareData = {
    title: 'Payment Link',
    text: 'Share Payment Link',
    url: 'https://google.com'
}

const currencyOption = [
    {
        title: 'USD',
        value: 'USD'
    },
    {
        title: 'EUR',
        value: 'EUR'
    },
    {
        title: 'GPB',
        value: 'GPB'
    },
]

const taxOption = [
    {
        title: '17%',
        value: '17%'
    },
    {
        title: '18%',
        value: '18%'
    },
    {
        title: '19%',
        value: '19%'
    },
    {
        title: '20%',
        value: '20%'
    },
    {
        title: '21%',
        value: '21%'
    },
]

const createPaymentLinkFormSchema = z.object({
    title: z.string().min(1, `Can't be empty!`),
    description: z.string().min(1, `Can't be empty!`),
    currency: z.string(),
    amount: z.string().min(1, `Can't be empty!`),
    tax: z.string(),
})

export type CreatePaymentLinkFormSchemaType = z.infer<typeof createPaymentLinkFormSchema>

type TestType = {
    input: CreatePaymentLinkFormSchemaType
}

export function CreatePaymentLinkPullover() {
    const [showCreatePaymentLinkScreen, setShowCreatePaymentLinkScreen] = useState(false);
    const [showShareScreen, setShowShareScreen] = useState(false);

    const gql = useGql()

    // Temp
    const fields: FormFields<CreatePaymentLinkFormSchemaType> = {
        title: {
            zodType: z.string().min(1, `Can't be empty!`),
            fieldType: 'input',
            label: 'Title'
        },
        description: {
            zodType: z.string().min(1, `Can't be empty!`),
            fieldType: 'textarea',
            label: 'Description'
        },
        currency: {
            zodType: z.string().min(1, `Can't be empty!`),
            fieldType: 'select',
            options: currencyOption,
            label: 'Currency',
            defaultValue: 'EUR',
            colSpan: 'col-span-3'
        },
        amount: {
            zodType: z.string().min(1, `Can't be empty!`),
            fieldType: 'input',
            label: 'Amount',
            colSpan: 'col-span-9'
        },
        tax: {
            zodType: z.string().min(1, `Can't be empty!`),
            fieldType: 'select',
            defaultValue: '18%',
            options: taxOption,
            label: 'Tax'
        },
    }

    function handleShare() {
        console.log(shareData)
        navigator.share(shareData).then(r => console.log(r)).catch(err => console.log(err))
    }

    function handleCloseShareScreen() {
        setShowShareScreen(false)
    }

    /**
     *
     * Need Proper Mutation/Type from Graphql
     *
     * **/

    async function handleOnSubmit() {
        await gql.GetUsers().then(r => r) // Temp
    }

    async function handleSubmit(value: TestType) {
        console.log(value)
    }

    const onSubmitSuccess = () => {
        setShowShareScreen(true)
    }

    return (
        <>
            <PullOver
                title={'Share Payment Link'}
                submit={
                    {
                        title: 'Submit',
                        onClick: handleOnSubmit,
                        hidden: true
                    }
                }
                onClose={handleCloseShareScreen}
                variations={'max-height'}
                openState={[showShareScreen, setShowShareScreen]}
            >
                <CreatePaymentLinkSuccess handleShare={handleShare}
                    handleClose={handleCloseShareScreen}/>
            </PullOver>
            <PullOverForm
                trigger={{
                    title: <div className={'flex items-center justify-between w-full'}>Create Payment
                        Link <ChevronRight/>
                    </div>
                }}
                form={
                    {
                        submit: handleSubmit,
                        onSubmitSuccess: onSubmitSuccess,
                        fields,
                        payloadInjection: {}

                    }
                }
                openState={[showCreatePaymentLinkScreen, setShowCreatePaymentLinkScreen]}
                title={'Create Payment Link'}
                cancel={
                    {
                        title: 'Cancel',
                    }
                }
                submit={
                    {
                        title: 'Save'
                    }
                }

            />
        </>
    )
}