import React, {FC} from 'react';
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {ImplementFormProps} from "@components/form/Form.component";
import {CreateTierInput, CreateTierMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import useGql from "@modules/graphql/useGql";


const TierCreateForm: FC<ImplementFormProps<CreateTierMutation> & {
    subscriptionId: string,
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}> = ({
    onSubmitSuccess,
    subscriptionId,
    openState
}) => {


    const gql = useGql();

    const fields: FormFields<Omit<CreateTierInput, 'subscriptionId'>> = {
        title: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Tier Name',
            description: 'Name of your tier'
        },
        price: {
            zodType: z.coerce.number().min(1).step(0.01).default(0),
            fieldType: 'input',
            label: 'Price',
            description: 'Price excluding VAT'
        },
        frequency: {
            zodType: z.enum(['yearly', 'monthly', 'quarterly', 'variable']).default('monthly'),
            fieldType: 'input',
            label: 'Billing interval',
        },
        description: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'textarea',
            label: 'Tier description'
        },
    }

    return (
        <>
            <PullOverForm
                title={'Tier test form'}
                openState={openState}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    submit: gql.CreateTier,
                    onSubmitSuccess: onSubmitSuccess,
                    fields,
                    payloadInjection: {
                        subscriptionId
                    }
                }}
            />
        </>
    );
};

export default TierCreateForm;
