import {RadioGroup, RadioGroupItem} from "@src/@/components/ui/radio-group";
import React from "react";
import {Button} from "@src/@/components/ui/button";
import {ChevronDown, ChevronUp} from "lucide-react";
import {Label} from "@src/@/components/ui/label";
import {State} from "@pages/merchant/subscriber/list/components/SubscriberFilterPullover";

interface SortBySectionProps {
    handleChange: (value: string | boolean, category: string) => void
    handleReset: (category: string) => void | null
    filter: State
}


const category = ['Name', 'Subscription Name', 'Amount', 'Tier']
const order = ['Descending', 'Ascending']

export function SortBySection({handleChange, handleReset, filter}: SortBySectionProps) {
    return (
        <div className={'space-y-4'}>
            <div className={'flex items-center justify-between'}>
                <p className={'text-xl w-full font-roboto font-medium text-primary-800'}>Sort By</p>
                <Button size={'default'}
                    className={'flex-grow-0 w-auto h-auto bg-neutral-200 text-neutral-700'}
                    onClick={() => handleReset('sortBy')}>Reset</Button>
            </div>
            <RadioGroup value={filter.sortBy.category}
                onValueChange={(value) => handleChange(value, 'category')}
                className={'flex-wrap flex gap-2'}>
                {
                    category.map(item => {
                        return (
                            <div key={item}>
                                <RadioGroupItem value={item} id={item} className={'peer hidden'}/>
                                <Label htmlFor={item} className={'peer-data-[state=checked]:bg-primary-800' +
                                    ' peer-data-[state=checked]:text-neutral-100' +
                                    ' peer-data-[state=checked]:border-primary-800' +
                                    ' w-auto h-auto rounded-md px-2 py-1 aspect-auto border' +
                                    ' border-neutral-400 font-medium text-neutral-600 shadow-none'}>
                                    {item}
                                </Label>
                            </div>
                        )
                    })
                }
            </RadioGroup>
            <RadioGroup value={filter.sortBy.order} onValueChange={(value) => handleChange(value, 'order')}
                className={'flex-wrap flex gap-2'}>
                {
                    order.map(item => {
                        return (
                            <div key={item}>
                                <RadioGroupItem value={item} id={item} className={'peer hidden'}/>
                                <Label htmlFor={item} className={'peer-data-[state=checked]:bg-primary-800' +
                                    ' peer-data-[state=checked]:text-neutral-100' +
                                    ' peer-data-[state=checked]:border-primary-800' +
                                    ' w-auto h-auto rounded-md px-2 py-1 aspect-auto border' +
                                    ' border-neutral-400 font-medium text-neutral-600 shadow-none flex items-center' +
                                    ' gap-x-1'}>
                                    {
                                        item === 'Descending' ? <ChevronUp className={'h-4 w-4'}/>
                                            : <ChevronDown className={'h-4 w-4'}/>
                                    }
                                    {item}
                                </Label>
                            </div>
                        )
                    })
                }
            </RadioGroup>
        </div>
    )
}