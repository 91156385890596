import React from 'react';
import IndexPage from "../../../../templates/page/IndexPage.template";
import {SubscriberList} from "@pages/merchant/subscriber/index/components/SubscriberList";
import SubscriberChart from "@pages/merchant/subscriber/index/components/SubscriberChart";
import {InviteSubscriberPullover} from "@pages/merchant/subscriber/index/components/InviteSubscriberPullover";

const SubscriberIndexPage = () => {
    return (
        <IndexPage>
            <SubscriberChart/>
            <SubscriberList/>
            <InviteSubscriberPullover/>
        </IndexPage>
    );
};

export default SubscriberIndexPage;
