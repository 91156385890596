import React from 'react';
import SubscriptionCreateForm from "@pages/merchant/subscriptions/index/components/SubscriptionCreate.form";
import SearchBar from "@components/search/SearchBar.component";

const SubscriptionSearchBar = () => {
    return <SearchBar
        className={'pt-shell'}
        inputProps={{placeholder: 'Find Subscription'}}
        button={<SubscriptionCreateForm/>}
    />;
};

export default SubscriptionSearchBar;
