import {classNames} from "@modules/casting/String";

export const InputStyle = classNames(
    'flex items-center justify-between whitespace-nowrap',
    'border border-input border-neutral-500',
    'text-[0.85rem] text-neutral placeholder:text-muted-foreground',
    'rounded-md shadow-sm ring-offset-background  focus:outline-none focus:ring-1 focus:ring-ring',
    'bg-white disabled:opacity-50',
    "h-8 px-2 py-2 w-full [&>span]:line-clamp-1",
    'disabled:cursor-not-allowed'
);