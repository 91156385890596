export type CompanyUser = {
    id: string;
    name: string;
    role: string[];
    email: string;
    mobileNo: string;
}


export const mockCompanyUsersData: CompanyUser[] = [
    {
        id: '1',
        name: 'Robert Parks',
        role: ['Admin'],
        email: 'RParks@gmail.com',
        mobileNo: '+1 234 567 890'
    },
    {
        id: '2',
        name: 'Jane Smith',
        role: ['Subscription Manager', 'Subscriber Manager'],
        email: 'JaneS@gmail.com',
        mobileNo: '+1 234 567 891'
    },
    {
        id: '3',
        name: 'Mark Bradley',
        role: ['Subscription Manager'],
        email: 'MarkBradley@gmail.com',
        mobileNo: '+1 234 567 892'
    },
    {
        id: '4',
        name: 'Jennifer Lee',
        role: ['Financial Manager'],
        email: 'JLee@gmail.com',
        mobileNo: '+1 234 567 893'
    },
    {
        id: '5',
        name: 'Liza Taylor',
        role: ['Subscriber Manager', 'User Manager'],
        email: 'LizaTaylor@gmail.com',
        mobileNo: '+1 234 567 894'
    },
]
