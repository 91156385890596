import React, {FC} from 'react';
import {FilterAltOutlined} from "@mui/icons-material";
import {Button, ButtonProps} from "@src/@/components/ui/button";


export const FilterButtonInner = () => <><FilterAltOutlined className={'!text-[1rem] mr-1'}/> Filter</>

type Props = Omit<ButtonProps, 'children' | 'variant'>
const FilterButton: FC<Props> = (props) => {
    return (
        <Button variant={'primary'} {...props}>
            <FilterButtonInner/>
        </Button>
    );
};

export default FilterButton;
