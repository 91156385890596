import React, {useEffect, useState} from 'react'
import ListPage from "@templates/page/ListPage.template";
import {CompanyUserCardList} from "@pages/merchant/settings/company/components/CompanyUserCardList";
import {FooterAddUserButton} from "@pages/merchant/settings/company/components/FooterAddUserButton";
import {CompanyUser, mockCompanyUsersData} from "@app/mock/MockCompanyData";


export function CompanyManageUsers() {
    const [users, setUsers] = useState<CompanyUser[]>()

    const addUser = (newUser: CompanyUser) => {
        setUsers(prevUsers => [...(prevUsers || []), newUser]);
    };

    const changeUserRole = (userId: string, role: string, isChecked: boolean | 'indeterminate') => {
        setUsers(prevUsers =>
            prevUsers?.map(user => {
                if (user.id === userId) {
                    let updatedRoles: string[];

                    if (role === 'Admin') {
                        updatedRoles = isChecked ? ['Admin'] : [];
                    } else {
                        updatedRoles = isChecked
                            ? [...user.role.filter(currRole => currRole !== 'Admin'), role]
                            : user.role.filter(currRole => currRole !== role);
                    }

                    return {
                        ...user,
                        role: updatedRoles
                    };
                }
                return user;
            })
        );
    }


    const removeUser = (userId: string | undefined) => {
        setUsers(prevUsers => prevUsers?.filter(user => user.id !== userId))
    }

    useEffect(() => {
        setUsers(mockCompanyUsersData)
    }, []);

    return (
        <ListPage>
            <CompanyUserCardList users={users} setUsers={setUsers} changeUserRole={changeUserRole}
                removeUser={removeUser}/>
            <FooterAddUserButton addUser={addUser}/>
        </ListPage>
    )
}