import React, {FC, JSX, ReactNode} from 'react';
import {datetimeToLocale} from '@modules/casting/Date';
import {HashMap} from "@modules/casting/Object";
import {Link} from "react-router-dom";
import {classNames} from "@modules/casting/String";


export type ListItem = {
    id?: string | number
    symbol?: ReactNode,
    title: string,
    description: string,
    feedback?: ReactNode,
    dateTime: string // @todo evaluate property name
    target?: string
}

export type ListItems = HashMap<string, ListItem[]>;

type Props = {
    height: string
    adjustWidth?: boolean
    items: ListItems
}


const ListComponent: FC<Props> = ({items, height, adjustWidth = false}): JSX.Element => {

    const itemWrapper = (item: ListItem, children: ReactNode) => {
        if (item.target) {
            return <Link to={item.target}>
                {children}
            </Link>
        }

        return <>{children}</>
    }

    return <>
        <div className={classNames('overflow-y-auto', adjustWidth && '-mx-shell')} style={{
            height
        }}>
            {Object.keys(items).map((key) => (
                <div key={key}>

                    <div
                        className="sticky top-0 z-[2] border-y border-t-neutral-300/50 border-b-neutral-300/75 bg-white px-shell text-sm font-semibold leading-6 text-primary">
                        <h3 className={'py-1.5 bg-white'}>{datetimeToLocale(key)}</h3>
                    </div>

                    <ul className="divide-y divide-neutral-200 bg-white">
                        {(items[key]).map((item, i) => (
                            <li key={i} className="py-1.5 px-shell">
                                {itemWrapper(item, <div className={'grid grid-cols-10 gap-x-1 h-[3rem]'}>

                                    <div className={'col-span-8 flex content-center items-center'}>
                                        <div
                                            className={'w-10 h-10 rounded-full bg-secondary-200 text-primary font-bold mr-4 flex content-center items-center'}>
                                            <p className={'w-8 h-4 text-center text-[0.8rem] leading-4 mx-auto'}>
                                                {item.symbol}
                                            </p>
                                        </div>
                                        <div className={'inline-block'}>
                                            <p className="text-primary font-medium text-sm ">{item.title}</p>
                                            <p className="-mt-0.5 truncate text-sm leading-5 text-gray-500">{item.description}</p>
                                        </div>
                                    </div>
                                    <div className={'col-span-2'}>
                                        <span
                                            className={'text-lg font-medium text-primary text-right w-full block leading-[1.75rem] translate-y-[calc(50%-0.25rem)]'}>
                                            {item.feedback}
                                        </span>
                                    </div>

                                </div>)}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    </>;
};

export default ListComponent;