import React, {forwardRef} from 'react';
import {Chart as ChartJs} from "react-chartjs-2";
import {ChartJSOrUndefined, ChartProps} from "react-chartjs-2/dist/types";
import {ChartData, type ChartOptions, ChartTypeRegistry} from "chart.js";
import {classNames} from "@modules/casting/String";
import './chart.scss'

/**
 * Default options for all charts in Tjecko
 */
const ChartJsDefaultOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    animation: {
        easing: 'easeInOutQuad',
        duration: 520
    },

    elements: {
        line: {
            tension: 0.333
        },
        point: {
            pointStyle: false,
        },
    },

    line: {
        datasets: {},
    },

    scales: {
        x: {
            grid: {
                display: false,
            },
            border: {
                display: false,
            },
            ticks: {
                align: 'inner',
                autoSkip: false,
                crossAlign: 'center',

            },

        },
        y: {
            beginAtZero: true,
            grace: 1,
            offset: true,
            grid: {
                offset: false,
                color: (ctx) => (ctx.index === 0 ? 'transparent' : 'rgba(0, 0, 0, 0.1)')
            },
            ticks: {
                display: false,
                maxTicksLimit: 4,
                padding: 0,
            },
            border: {
                display: false,
            },
        },
    },
    layout: {
        padding: {
            left: 16,
            right: 18
        },
    },
    plugins: {
        datalabels: {
            display: (ctx) => {
                const index = ctx.dataIndex;
                const data = ctx.dataset.data as number[];
                const currentDataItem = data[index]
                const highestValue = Math.max(...data)
                const lowestValue = Math.min(...data)
                const lastIndex = data.length - 1
                return index == lastIndex || [highestValue, lowestValue].includes(currentDataItem)
            },
            anchor: 'end',
            align: ['end', 'left'],
            offset: [0, 4],
            textAlign: 'left',
            labels: {
                title: {
                    font: {
                        weight: 'bold'
                    },
                },
                value: {
                    color: (ctx) => {
                        const index = ctx.dataIndex;
                        const data = ctx.dataset.data as number[];
                        const lastIndex = data.length - 1
                        return index == lastIndex ? '#139b13' : '#ff0000'
                    }
                }
            }
        },
        legend: {
            display: false,
            labels: {
                usePointStyle: false,
                font: {
                    family: 'Montserrat'
                }
            }
        },
        tooltip: {
            enabled: true
        },
    },
}

/**
 * Wrapper for base chart that implements base options, options can be overwritten
 */
const Chart = forwardRef<
    ChartJSOrUndefined,
    ChartProps
>(({options, className, ...props}, ref) => {
    return <ChartJs
        ref={ref}
        {...props}
        className={classNames('tjecko-chart w-full px-2', className)}
        options={{...ChartJsDefaultOptions, ...options}}
    />;
});
Chart.displayName = 'BaseChart'


/**
 * Simplified properties for chartJS extensions like line, bar etc.
 */
type ExtendChartProps<TType extends keyof ChartTypeRegistry> = {
    data: ChartData<TType>
    options?: ChartOptions<TType>
    className?: string
}

/**
 * Wrapper for creating forward refs for chart extensions.
 * @param type
 * @param displayName
 */
const getChartRefComponent = <TType extends keyof ChartTypeRegistry>(type: TType, displayName: string) => {
    const refComponent = forwardRef<
        ChartJSOrUndefined,
        ExtendChartProps<TType>
    >((
        props,
        ref
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    ) => <Chart type={type} ref={ref} {...props}  />)
    refComponent.displayName = displayName
    return refComponent
}

export const LineChart = getChartRefComponent<'line'>('line', 'LineChart');