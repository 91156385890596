import {I18nDictionary} from "@config/i18n";


export default {
    'payments': {
        'title': {
            'singular': 'Payment',
            'plural': 'Payments'
        }
    },
    'subscriptions': {
        'title': {
            'singular': 'Subscription',
            'plural': 'Subscriptions'
        }
    },
    'subscribers': {
        'title': {
            'singular': 'Subscriber',
            'plural': 'Subscribers'
        }
    },
    'settings': {
        'title': {
            'singular': 'Setting',
            'plural': 'Settings'
        }
    },
    'auth': {
        'login': 'Log in',
        'logout': 'Log out'
    },
    'otp': {
        'text': {
            'verify': 'Please enter the verification code from your Authenticator App',
            'incorrect': 'The given verification code is incorrect!',
            'enter': 'Enter verification Code',
            'block': 'Your authenticator was blocked.',
            'qr': {
                'failure': 'Not logged in or invalid account',
                'scan': 'Scan the QR code below using a supported authenticator app. (Google Authenticator & Microsoft Authenticator)',
                'scant': 'Cant scan the QR Code? Enter this code into your authenticator app instead:',
            }
        }
    }
} satisfies I18nDictionary