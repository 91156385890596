import React from 'react'
import ListPage from "@templates/page/ListPage.template";
import {SubscriberListComponent} from "@pages/merchant/subscriber/list/components/SubscriberListComponent";
import {SubscriberSearchFilterSection} from "@pages/merchant/subscriber/list/components/SubscriberSearchFilterSection";
import {mockSubscriberData} from "@app/mock/MockSubscriberData";

export function SubscriberListPage() {
    return (
        <ListPage navChildren={<SubscriberSearchFilterSection/>}>
            <SubscriberListComponent data={mockSubscriberData}/>
        </ListPage>
    )
}