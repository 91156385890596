import React, {FC, useMemo} from "react";
import Card from "@components/card/Card.component";
import {IconType} from "@app/types/Icons";


export type DescriptionCardType = {
    title: string,
    description?: string,
    action: {
        Icon: IconType,
        onClick: () => void
    }
}

type Props = DescriptionCardType


const HeaderCard: FC<Props> = ({
    title,
    description,
    action
}) => {

    const {Icon, onClick} = useMemo(() => {
        return action
    }, [action]);

    return (
        <Card className={'!bg-primary !text-white !py-3'}>
            <div className="grid grid-cols-12 gap-x-2">
                <dl className={'grid grid-cols-1 gap-y-1 col-span-11'}>
                    <dt className={'text-lg text-white font-medium font-roboto'}>{title}</dt>
                    <dd className={'text-[0.8rem] text-neutral-100 line-clamp-2'}>{description}</dd>
                </dl>
                <div className={'col-span-1'}>
                    <div className={'w-full relative'}>
                        <button onClick={onClick} className={'absolute w-fit right-0'}>
                            <Icon className={'!text-[1.1rem]'}/>
                        </button>
                    </div>
                </div>
            </div>
        </Card>
    )
}
export default HeaderCard