import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {LoginSection} from "@pages/auth/login/components/LoginSection";
import {LoginHeader} from "@pages/auth/login/components/LoginHeader";
import {LoginFooter} from "@pages/auth/login/components/LoginFooter";
import GuestPageTemplate from "@templates/page/GuestPage.template";
import {Apple, Google, Microsoft} from "@mui/icons-material";

const LoginPage = () => {

    const {loginWithRedirect, isLoading, isAuthenticated} = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (isAuthenticated) {
            navigate('/authorize', {replace: true});
        }
    }, [isLoading]);


    const handleLoginWithGoogle = async () => {
        await loginWithRedirect({
            authorizationParams: {
                connection: 'google-oauth2'
            },
            appState: {
                returnTo: `${window.location.origin}/authorize`
            },
        });
    };


    return <>
        {!isLoading && <GuestPageTemplate>
            <div className={'text-center grid grid-cols-1 gap-y-12 translate-y-[calc(50%-4rem)]'}>
                <LoginHeader/>
                <LoginSection
                    loginItems={[
                        {
                            title: 'Google',
                            Icon: Google,
                            onClick: handleLoginWithGoogle
                        },
                        {
                            title: 'Apple',
                            Icon: Apple,
                            // @fixme Apple login
                            onClick: handleLoginWithGoogle
                        },
                        {
                            title: 'Microsoft',
                            Icon: Microsoft,
                            // @fixme Microsoft login
                            onClick: handleLoginWithGoogle
                        },
                    ]}
                />
                <LoginFooter/>
            </div>
        </GuestPageTemplate>}
    </>;
};

export default LoginPage;
