import React, {FC, ReactNode, useEffect} from 'react';
import {motion} from "framer-motion";
import usePageTransition from "@modules/routing/usePageTransition";
import {Helmet} from "react-helmet";
import {useCurrentRoute} from "@modules/routing/useRouter";
import {NavRouteItem, useNavbarTop} from "@components/navigation/NavbarTop/NavbarTop.context";
import {classNames} from "@modules/casting/String";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";

export type ExtendPageProps = {
    pageTitle?: string,
    back?: NavRouteItem,
    next?: NavRouteItem
    children: ReactNode,
    navChildren?: ReactNode
}

type Props = ExtendPageProps & {
    fullWidth?: boolean,
    fullHeight?: boolean,
    hideNavbarTop?: boolean,
    className?: string
}

const BasePageTemplate: FC<Props> = ({
    children,
    fullWidth,
    fullHeight,
    hideNavbarTop = false,
    pageTitle,
    className = '',
    back,
    next,
    navChildren
}) => {

    const pageTransition = usePageTransition();
    const currentRoute = useCurrentRoute();
    const {available} = useWorkspaceHeight();

    const navTop = useNavbarTop();
    const setHideNavbarTop = navTop.hide[1];
    const setNavTitle = navTop.title[1];
    const setBackRoute = navTop.back[1]
    const setNextRoute = navTop.next[1]
    const setChildren = navTop.children[1]

    useEffect(() => {
        setHideNavbarTop(hideNavbarTop)
    }, []);

    useEffect(() => {
        setNavTitle(pageTitle)
    }, [pageTitle]);

    useEffect(() => {
        setBackRoute(back)
    }, [back]);

    useEffect(() => {
        setNextRoute(next)
    }, [next]);

    useEffect(() => {
        setChildren(navChildren)
    }, [navChildren]);

    return (
        <>
            <Helmet>
                <title>{pageTitle ?? currentRoute.item.title}</title>
            </Helmet>

            {pageTransition.transitionReady && <motion.div
                initial={pageTransition.initial}
                onLayoutAnimationComplete={pageTransition.setIsTransitioned}
                animate={pageTransition.animate}
                exit={pageTransition.exit}
                variants={pageTransition.variants}
                transition={pageTransition.transition}
                className={classNames(
                    'w-full z-0 bg-neutral-100 shadow-lg drop-shadow overflow-y-auto',
                    className
                )}
                style={{
                    height: `${available}px`
                }}
            >
                {React.Children.toArray(children).map((child, i) => <div
                    key={i}
                    className={classNames(!fullWidth && 'mx-shell', !fullHeight && 'my-shell')}
                >
                    {child}
                </div>)}
            </motion.div>}
        </>

    );
};

export default BasePageTemplate;
