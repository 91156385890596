import React, {useMemo} from "react";
import {CustomerCardList} from "@pages/merchant/subscriber/show/components/CustomerCardList";
import {Badge} from "@src/@/components/ui/badge";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import {Link} from "react-router-dom";
import ManageSubscriptionPullover from "@pages/merchant/subscriber/show/components/ManageSubscriptionPullover";
import {SubscriberItem} from "@app/mock/MockSubscriberData";


interface PaymentSubscriptionTabProps {
    customer: SubscriberItem
}

export function PaymentSubscriptionTab({customer}: PaymentSubscriptionTabProps) {

    const {available} = useWorkspaceHeight()

    function formatDate() {
        const newDate = new Date(customer.nextInvoiceData);
        return newDate.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
    }

    const listHeight = useMemo(() => {
        return `calc(${available - 505 - 41 - 30}px - 4.0625rem)`
    }, [available]);
    return (
        <>
            <div className={'flex-grow flex flex-col gap-y-4 m-shell text-neutral-900'}>

                {/* Subscription Information */}

                <div className={'space-y-2'}>
                    <div className={'flex justify-between items-center'}>
                        <p className={'font-medium text-sm font-roboto w-full'}>Subscription Information</p>
                        <ManageSubscriptionPullover>
                            <p className={'font-medium text-sm text-neutral-600 font-roboto'}>Manage</p>
                        </ManageSubscriptionPullover>
                    </div>
                    <div className={'border rounded-md flex items-center p-4 gap-x-2 relative bg-white'}>
                        <div className={'h-10 w-10 border rounded-md'}></div>
                        <div className={'space-y-1'}>
                            <p className={'font-semibold'}>{customer.subscriptionType}</p>
                            <p className={'text-xs'}>Next invoice
                                on {formatDate()} for €{customer.amount}</p>
                        </div>
                        <Badge
                            className={`absolute -right-[1px] h-auto -top-[1px] rounded-none rounded-bl-md z-10 text-xs rounded-tr-md 
                            ${customer.isActive ? 'bg-positive-600' : 'bg-neutral-400 text-neutral-800'}`}
                            label={customer.isActive ? 'Active' : 'Inactive'}/>
                    </div>
                </div>


                {/* Credit Balance */}

                <div className={'space-y-2'}>
                    <div className={'flex justify-between'}>
                        <p className={'font-medium text-sm font-roboto'}>Credit Balance</p>
                    </div>
                    <div className={'border rounded-md flex items-center p-4 gap-x-2 relative bg-white'}>
                        <p className={'text-2xl font-bold'}>€{customer.balance}</p>
                    </div>
                </div>

                {/* Default Payment Method */}

                {customer.paymentMethods.map((paymentMethod, index) => {
                    if (paymentMethod.default) {
                        return (

                            <div key={index} className={'space-y-2'}>
                                <div className={'flex justify-between'}>
                                    <p className={'font-medium text-sm font-roboto'}>Default Payment Method</p>
                                </div>
                                <div className={'border rounded-md flex items-center p-4 gap-x-2 relative bg-white'}>
                                    <div
                                        className={'rounded-md text-center flex items-center justify-center'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="30"
                                            viewBox="0 0 93 30"
                                            fill="none">
                                            <g clipPath="url(#clip0_6228_1757)">
                                                <path
                                                    d="M60.1604 0.0365906C53.6021 0.0365906 47.7414 3.43586 47.7414 9.71628C47.7414 16.9187 58.1357 17.4161 58.1357 21.0344C58.1357 22.5579 56.3897 23.9217 53.4077 23.9217C49.1757 23.9217 46.0126 22.0161 46.0126 22.0161L44.6592 28.3538C44.6592 28.3538 48.303 29.9634 53.1407 29.9634C60.3109 29.9634 65.953 26.3972 65.953 20.0095C65.953 12.3988 55.5155 11.9161 55.5155 8.5578C55.5155 7.36418 56.9487 6.05656 59.9222 6.05656C63.2771 6.05656 66.0144 7.44249 66.0144 7.44249L67.3389 1.32147C67.3389 1.32147 64.3606 0.0365906 60.1604 0.0365906ZM0.158841 0.498598L0 1.42252C0 1.42252 2.7591 1.92743 5.24407 2.93475C8.44363 4.08972 8.67163 4.76216 9.21047 6.85058L15.0824 29.487H22.954L35.0807 0.498598H27.2272L19.4351 20.2079L16.2555 3.50132C15.9639 1.58931 14.4868 0.498598 12.6788 0.498598H0.158841ZM38.2386 0.498598L32.0779 29.4869H39.5668L45.7059 0.498506H38.2386V0.498598ZM80.0069 0.498598C78.201 0.498598 77.2443 1.46542 76.5422 3.15489L65.5704 29.4869H73.4239L74.9433 25.0982H84.511L85.4351 29.4869H92.3645L86.3192 0.498598H80.0069ZM81.0283 8.33035L83.3562 19.2082H77.1196L81.0283 8.33035Z"
                                                    fill="#1434CB"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_6228_1757">
                                                    <rect width="92.3645" height="30" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className={'flex flex-col'}>
                                        <p className={'font-bold'}>{paymentMethod.cardNum}</p>
                                        <p className={'text-xs font-medium'}>{paymentMethod.expiration}</p>
                                    </div>
                                </div>
                            </div>


                        )
                    }

                    return null
                })}

            </div>

            {/* Customer's Invoice History */}

            <div className={'flex flex-col gap-y-2'}>
                <div className={'flex justify-between text-sm px-4'}>
                    <p className={'font-medium font-roboto'}>Invoice History</p>
                    <Link to={`invoice`} className={'font-medium font-roboto'}>See all</Link>
                </div>
                <CustomerCardList customer={customer} height={listHeight}/>
            </div>
        </>
    )
}