import React, {FC, useState} from 'react';
import CreateButton from "@components/button/CreateButton";
import SubscriptionItemCard from "@pages/merchant/subscriptions/show/components/SubscriptionItemCard";
import ArchiveButton from "@components/button/ArchiveButton";
import useAsyncMemo from "@hooks/useAsyncMemo";
import useGql from "@modules/graphql/useGql";
import ExtraCreateForm from "@pages/merchant/subscriptions/show/components/extras/ExtraCreate.form";
import ExtraUpdateForm from "@pages/merchant/subscriptions/show/components/extras/ExtraUpdate.form";
import SearchBar from "@components/search/SearchBar.component";
import SubscriptionItemList from "@pages/merchant/subscriptions/show/components/SubscriptionItemList";


type Props = {
    subscriptionId: string
}
const ExtraTab: FC<Props> = ({subscriptionId}) => {

    const gql = useGql()

    const [currentExtraId, setCurrentExtraId] = useState<string>()
    const [openUpdateExtra, setOpenUpdateExtra] = useState(false)

    const [openCreateExtra, setOpenCreateExtra] = useState(false)
    const [updateExtras, setUpdateExtras] = useState(0)
    const extras = useAsyncMemo(async () => {
        return await gql.GetExtrasBySubscriptionId({id: subscriptionId}).then(r => r.getExtrasBySubscriptionId ?? [])
    }, [updateExtras], [])


    return (
        <>
            <SearchBar
                className={'mb-shell'}
                inputProps={{placeholder: 'Find extras'}}
                button={<CreateButton onClick={() => setOpenCreateExtra(true)}/>}
            />


            <SubscriptionItemList archiveButton={<ArchiveButton/>}>
                {extras.map(extra => <SubscriptionItemCard
                    key={extra.id}
                    title={extra.title}
                    description={extra.description}
                    price={14.95}
                    activeSubscribers={23}
                    disabled={!extra.active}
                    onClick={() => {
                        setCurrentExtraId(extra.id)
                        setOpenUpdateExtra(true)
                    }}
                />)}
            </SubscriptionItemList>

            <ExtraCreateForm
                openState={[openCreateExtra, setOpenCreateExtra]}
                subscriptionId={subscriptionId}
                onSubmitSuccess={() => setUpdateExtras(updateExtras + 1)}
            />

            {currentExtraId && <ExtraUpdateForm
                openState={[openUpdateExtra, setOpenUpdateExtra]}
                id={currentExtraId}
                onSubmitSuccess={() => {
                    setUpdateExtras(updateExtras + 1)
                    setCurrentExtraId(undefined)
                }}
            />}
        </>
    );
};

export default ExtraTab;
