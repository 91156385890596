import React, {Dispatch, FC, ReactNode, SetStateAction, useMemo, useState} from 'react';
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger
} from "@shadcn/components/ui/drawer";
import {Button} from "@shadcn/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@shadcn/components/ui/dialog";
import {useMeasure} from "@uidotdev/usehooks";
import {isMobile} from 'react-device-detect';
import {classNames} from "@modules/casting/String";
import useScrollSpy from "@hooks/useScrollSpy";
import PrimaryButton from "@components/button/PrimaryButton";
import {ColorVariations} from "@app/types/ColorVariations";

export type Action = {
    title: ReactNode
    onClick?: () => Promise<void> | void,
    disabled?: boolean,
    allowClickIfDisabled?: boolean
    hidden?: boolean
}

export type PullOverProps = {
    title: string,
    description?: string,
    trigger?: Action & {
        variation?: ColorVariations
    },
    submit: Action,
    cancel?: Action & {
        dialog?: {
            title: string,
            description?: string
        }
    }
    onClose?: () => void,
    variations?: 'max-height'
}

type Props = PullOverProps & {
    children: ReactNode,
    openState?: [boolean, Dispatch<SetStateAction<boolean>>]
}

const PullOver: FC<Props> = ({
    trigger,
    title,
    description,
    children,
    submit,
    cancel,
    onClose,
    variations,
    ...props
}) => {

    const [drawerRef, drawerDim] = useMeasure();
    // @todo get content height to set min height, should not exceed 80vh
    const [contentRef, contentDim] = useMeasure();

    const {scrollRef, isTop, isBottom} = useScrollSpy<HTMLDivElement>({
        offsetTop: 16,
        offsetBottom: 16
    })


    const internalOpenState = useState(false)

    const openState = useMemo(() => {
        if (props.openState) {
            return props.openState
        }
        return internalOpenState
    }, [props.openState]);

    const [open, setOpen] = openState
    const [openCancelDialog, setOpenCancelDialog] = useState(false)

    const handleTrigger = async () => {
        await trigger?.onClick?.()
    }

    const handleSubmit = async () => {
        await submit.onClick?.()?.then(() => {
            setOpen(false);
        })
        // @todo validate if should close
    }

    const handleCancel = async () => {
        await cancel?.onClick?.()
        // @todo prevent exit dialog for confirmation
    }

    const handleOpenChange = (isOpen: boolean) => {

        if (open && !isOpen && cancel?.dialog !== undefined && !openCancelDialog) {
            setOpenCancelDialog(true)
            return;
        }

        setOpen(openCancelDialog ? true : isOpen)

    }

    const handleClose = () => {
        onClose?.()
    }


    const heightOffset = useMemo(() => {
        return 232 // 16*14.5 -> px to rem -> 1rem * 14.5rem
    }, []);

    const contentHeight = useMemo(() => {
        return contentDim.height ?? Infinity
    }, [contentDim.height]);

    const maxContentHeight = useMemo(() => {
        return contentHeight + heightOffset + 26
    }, [contentHeight]);

    const drawerHeight = useMemo(() => {
        if (!drawerDim.height) {
            return window.innerHeight * 0.9
        }
        return drawerDim.height
    }, [drawerDim.height]);

    const maxDrawerHeight = useMemo(() => {
        const maxHeight = window.innerHeight * 0.9;
        const bestFit = maxContentHeight < drawerHeight ? maxContentHeight : drawerHeight;
        return maxHeight < bestFit ? maxHeight : bestFit
    }, [window.innerHeight, contentHeight]);

    const contentOverflown = useMemo(() => {
        return !(contentHeight + 48 <= drawerHeight - heightOffset)
    }, [contentHeight, drawerHeight]);


    return (
        <>
            <Drawer open={open} onOpenChange={handleOpenChange} onClose={handleClose}>
                {trigger && <DrawerTrigger asChild>
                    <Button variant={trigger.variation} onClick={handleTrigger}
                        disabled={trigger.disabled}>{trigger.title}</Button>
                </DrawerTrigger>}

                {/** TEMP - Just to remove the warning in the console everytime we open the pullover*/}
                <DrawerContent ref={drawerRef} aria-describedby={undefined}>
                    <div className="mx-auto w-full min-h-[33vh] max-h-[85vh]" style={{
                        height: !variations ? `${drawerHeight}px` : (variations == 'max-height' ? '85vh' : '100%'),
                        maxHeight: !variations ? `${maxDrawerHeight}px` : (variations == 'max-height' ? '85vh' : '100%'),
                    }}>
                        <DrawerHeader className={classNames(!isTop && 'shadow-sm shadow-y-0.5')}>
                            <DrawerTitle>{title}</DrawerTitle>
                            {description && <DrawerDescription>{description}</DrawerDescription>}
                        </DrawerHeader>
                        <div ref={scrollRef} className="pl-6 overflow-y-auto scroll-smooth" style={{
                            height: `${drawerHeight - heightOffset}px`,
                            paddingRight: contentOverflown ? `${isMobile ? 1.55 : 0.55}rem` : '1.5rem'
                        }}>
                            <div ref={contentRef} className={'inline-block w-full'}>
                                {children}
                            </div>
                        </div>
                        {
                            !submit.hidden &&
                            <DrawerFooter className={classNames(!isBottom && 'shadow-sm -shadow-y-0.5')}>
                                <PrimaryButton
                                    type={'submit'}
                                    className={classNames('text-white', !cancel && 'mt-12')}
                                    onClick={handleSubmit}
                                    disabled={!submit.allowClickIfDisabled && submit.disabled}
                                >{submit.title}</PrimaryButton>
                                {cancel && <DrawerClose asChild>
                                    <Button onClick={handleCancel} variant="outline">Cancel</Button>
                                </DrawerClose>}
                            </DrawerFooter>
                        }

                    </div>
                </DrawerContent>
            </Drawer>


            <Dialog open={openCancelDialog} onOpenChange={setOpenCancelDialog}>

                <DialogContent className="sm:max-w-[425px] bg-white">
                    <DialogHeader>
                        <DialogTitle>{cancel?.dialog?.title}</DialogTitle>
                        <DialogDescription>
                            {cancel?.dialog?.description}
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className={'grid gap-y-2'}>
                        <Button onClick={() => {
                            setOpen(true)
                            setOpenCancelDialog(false);
                        }} variant="outline">Continue editing</Button>
                        <Button className={'bg-negative-500 text-white'} onClick={async () => {
                            setOpenCancelDialog(false);
                            setOpen(false)
                            await handleCancel()
                        }} variant="outline">Cancel editing</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>

    );
};

export default PullOver;
