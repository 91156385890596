import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import React, {ComponentType, useEffect, useState} from "react";
import {INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND} from "@lexical/list";
import {$getSelection, $isRangeSelection, LexicalCommand} from "lexical";
import {FormatListBulleted, FormatListNumbered} from "@mui/icons-material";
import {classNames} from "@modules/casting/String";


type ListTypeItem = {
    type: LexicalCommand<void>,
    Icon: ComponentType<{ className?: string }>
}

const listTypeItems: ListTypeItem[] = [
    {
        type: INSERT_ORDERED_LIST_COMMAND,
        Icon: FormatListNumbered
    },
    {
        type: INSERT_UNORDERED_LIST_COMMAND,
        Icon: FormatListBulleted
    },
]

export function ListGroup() {

    const [editor] = useLexicalComposerContext()
    const [activeListType, setActiveListType] = useState<LexicalCommand<void>>(REMOVE_LIST_COMMAND)


    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.getEditorState().read(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    const anchorNode = selection.anchor.getNode();
                    const topBlock = anchorNode.getTopLevelElementOrThrow();

                    if (topBlock.getType() === 'list') {
                        const listNode = topBlock as ListNode;

                        const command = listNode.__listType == 'number' ? INSERT_ORDERED_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND
                        setActiveListType(command)
                    } else {
                        setActiveListType(REMOVE_LIST_COMMAND)
                    }
                }
            });
        });
    }, [editor]);

    const handleClick = (command: LexicalCommand<void>) => {
        if (command == activeListType) {
            setActiveListType(REMOVE_LIST_COMMAND)
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
            return;
        }

        editor.dispatchCommand(command, undefined)
        setActiveListType(command)
    }

    return (
        <div className={'flex gap-x-0.5 my-4 w-full justify-between'}>
            {listTypeItems.map((item, i) => <button
                key={i}
                onClick={() => handleClick(item.type)}
                className={classNames('w-full h-10 !text-xl',
                    i == 0 && 'rounded-l-lg',
                    i == listTypeItems.length - 1 && 'rounded-r-lg',
                    activeListType.type == item.type.type ? 'bg-primary text-neutral-100' : 'bg-neutral-300 text-primary'
                )}
            >
                <item.Icon className={'text-2xl'}/>
            </button>)}
        </div>
    )
}