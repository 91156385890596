import React from 'react';
import {PaymentCustomerDetails} from "@pages/merchant/subscriber/show/components/PaymentCustomerDetails";
import {PaymentTab} from "@pages/merchant/subscriber/show/components/PaymentTab";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import {mockSubscriberData} from "@app/mock/MockSubscriberData";
import useParamId from "@hooks/useParamId";
import useAsyncMemo from "@hooks/useAsyncMemo";

const SubscriberShowPage = () => {
    const id = useParamId()

    const customer = useAsyncMemo(async () => {
        return mockSubscriberData.find(customer => customer.id === id)
    }, [id])

    return <ShowPageTemplate
        back={{
            title: 'Subscriber List',
            path: '/subscribers/list'
        }}>
        {
            !customer ? <div>Loading ...</div> : <>
                <PaymentCustomerDetails customer={customer}/>
                <PaymentTab customer={customer}/>
            </>
        }

    </ShowPageTemplate>
};

export default SubscriberShowPage;
