import {Link} from "react-router-dom";
import React from "react";
import {motion} from "framer-motion";

export function LoginFooter() {
    return (
        <motion.div className={'flex flex-col gap-y-2'}
            initial={{opacity: 0, scale: 0.5, translateY: '100%'}}
            animate={{opacity: 1, scale: 1, translateY: 0}}
            transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}
        >
            <small className={'text-[0.7rem] text-neutral-800'}>
                <span>Don&apos;t have an account?</span>&nbsp;
                <wbr/>
                <span className={'whitespace-nowrap'}>
                Sign up&nbsp;
                    <Link to={'/'} className={'text-primary-500 underline'}>here!</Link>
                </span>
            </small>
        </motion.div>
    )
}