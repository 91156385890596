import React from 'react'
import ListPage from "@templates/page/ListPage.template";
import {ChevronRight} from "@mui/icons-material";
import {mockMerchantTOSData} from "@app/mock/MockMerchantTosData";
import {useNavigate} from "react-router-dom";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import Markdown from "react-markdown";

export function MerchantTOS() {

    const navigate = useNavigate()

    function handleOnClick(param: string) {
        navigate(`${param}`)
    }

    return (
        <ListPage>
            {
                mockMerchantTOSData.map(merchantTOS => {
                    return (
                        <button key={merchantTOS.title} className={'border-b p-4 flex text-left' +
                            ' items-center text-neutral-900 bg-white w-full justify-between'}
                        onClick={() => handleOnClick(merchantTOS.id)}>
                            <div className={'flex-col'}>
                                <p className={'font-medium font-roboto text-lg text-primary'}>{merchantTOS.title}</p>
                                <div className={'line-clamp-1 text-sm font-medium'}>

                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                    >
                                        {merchantTOS.tos}
                                    </Markdown>
                                </div>
                            </div>
                            <ChevronRight className={'text-primary'}/>
                        </button>
                    )
                })
            }
        </ListPage>
    )
}