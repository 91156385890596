import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    AccountBalanceWalletOutlined,
    AddCircle,
    GroupOutlined,
    InsertDriveFileOutlined,
    SettingsOutlined
} from "@mui/icons-material";
import {classNames} from "@modules/casting/String";
import {__t} from "@config/i18n";
import {useMeasure} from "@uidotdev/usehooks";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";


const NavbarBottom = () => {

    const location = useLocation();
    const [ref, {height}] = useMeasure()
    const navBottom = useNavbarBottom()
    const setHeight = navBottom.height[1]

    useEffect(() => {
        setHeight((height ?? 0) + 20)
    }, [height]);

    return (
        <nav ref={ref} className={'grid grid-cols-5 text-center h-11'}>
            <Link
                to={'/payments'}
                className={classNames(location.pathname.includes('payments') ? 'text-primary font-semibold' : 'text-neutral-700')}
            >
                <AccountBalanceWalletOutlined/>
                <span className={'text-[0.7rem] block'}>{__t('payments.title.plural')}</span>
            </Link>

            <Link
                to={'/subscriptions'}
                className={classNames(location.pathname.includes('subscriptions') ? 'text-primary font-semibold' : 'text-neutral-700')}
            >
                <InsertDriveFileOutlined/>
                <span className={'text-[0.7rem] block'}>{__t('subscriptions.title.plural')}</span>
            </Link>
            <div>
                {/* @todo action support */}
                <AddCircle className={'mt-2.5 scale-[1.8] text-primary'}/>
            </div>
            <Link
                to={'/subscribers'}
                className={classNames(location.pathname.includes('subscribers') ? 'text-primary font-semibold' : 'text-neutral-700')}
            >
                <GroupOutlined/>
                <span className={'text-[0.7rem] block'}>{__t('subscribers.title.plural')}</span>
            </Link>
            <Link
                to={'/settings'}
                className={classNames(location.pathname.includes('settings') ? 'text-primary font-semibold' : 'text-neutral-700')}
            >
                <SettingsOutlined/>
                <span className={'text-[0.7rem] block'}>{__t('settings.title.plural')}</span>
            </Link>
        </nav>
    )
};

export default NavbarBottom;
