import React, {FC, useMemo} from 'react'
import Badge, {BadgeType} from "@components/indicator/Badge.component";
import {
    CheckOutlined,
    CloseOutlined,
    CurrencyExchangeOutlined,
    HourglassEmptyOutlined,
    NotificationImportantOutlined,
    NotificationsOutlined,
    PendingOutlined
} from "@mui/icons-material";

export type BillingPaymentStatus =
    'pending_payment'
    | 'paid'
    | 'failed'
    | 'reversed'
    | 'refunded'
    | 'reminder'
    | 'first_reminder'
    | 'second_reminder';

type Props = {
    status: BillingPaymentStatus,
    className?: string
}

const BillingBadge: FC<Props> = ({status, className}) => {

    const badge = useMemo((): BadgeType => {
        switch (status) {
            case 'paid':
                return {
                    title: 'Paid',
                    Icon: CheckOutlined,
                    variation: 'positive'
                }
            case 'failed':
                return {
                    title: 'Failed',
                    Icon: CloseOutlined,
                    variation: 'negative'
                }
            case 'refunded':
                return {
                    title: 'Refunded',
                    Icon: CurrencyExchangeOutlined,
                    variation: 'neutral'
                }
            case 'pending_payment':
                return {
                    title: 'Pending',
                    Icon: PendingOutlined,
                    variation: 'neutral'
                }
            case 'first_reminder':
                return {
                    title: '1st reminder',
                    Icon: NotificationsOutlined,
                    variation: 'secondary'
                }
            case 'second_reminder':
                return {
                    title: '2nd reminder',
                    Icon: NotificationImportantOutlined,
                    variation: 'warning'
                }
            default:
                return {
                    title: 'Unknown',
                    Icon: HourglassEmptyOutlined,
                    variation: 'negative'
                }
        }
    }, [status]);

    return <Badge className={className} {...badge}  />
}
export default BillingBadge;