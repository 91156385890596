import {Button} from "@src/@/components/ui/button";
import React, {FC} from "react";
import {motion} from "framer-motion";
import {SvgIconComponent} from "@mui/icons-material";

type LoginItem = {
    title: string,
    Icon: SvgIconComponent,
    onClick: () => void
}

type Props = {
    loginItems: LoginItem[]
}
export const LoginSection: FC<Props> = ({loginItems}) => <>
    <motion.div
        className={'flex flex-col gap-y-2'}
        initial={{opacity: 0, scale: 0.5, translateY: '50%'}}
        animate={{opacity: 1, scale: 1, translateY: 0}}
        transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }}
    >
        {loginItems.map(item =>
            <Button
                key={item.title}
                variant={'outline'}
                size={'default'}
                onClick={item.onClick}
                className={'border-neutral-600 w-full gap-x-4 text-left py-5'}
            >
                <span className={'w-6 text-primary-800 text-[1.5rem]'}>{<item.Icon fontSize={'inherit'}/>}</span>
                <span className={'w-40 text-neutral-700 text-[1rem]'}>Login with {item.title}</span>
            </Button>
        )}
    </motion.div>
</>