import React from "react";
import {FormControl,} from "@shadcn/components/ui/form"
import {Checkbox as ShadCnCheckbox} from "@shadcn/components/ui/checkbox"
import {ExtendFormFieldProps} from "@components/form/fields/FormField.component";


type Props<T> = ExtendFormFieldProps<T>

const Checkbox = <T = boolean>({field}: Props<T>) => {
    return (
        <FormControl>
            <ShadCnCheckbox
                checked={field.value}
                onCheckedChange={field.onChange}
            />
        </FormControl>
    )
}
export default Checkbox