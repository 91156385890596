import React, {useCallback, useMemo, useState} from 'react';
import PricingTabComponent from "@pages/merchant/plans/components/PricingTabComponent";
import PricingCardComponent, {PricingCard} from "@pages/merchant/plans/components/PricingCardComponent";
import PricingIntervalComponent, {PricingInterval} from "@pages/merchant/plans/components/PricingIntervalComponent";

import ShowPageTemplate from "@templates/page/ShowPage.template";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";

type IntervalType = 'monthly' | 'yearly';
type DiscountType = 'percentage' | 'fixed';

const PlansPage: React.FC = () => {
    const gql = useGql()

    const tabState = useState<number>(0)


    const data = useAsyncMemo(async () => {
        return await gql.GetTjeckoSubscription()
            .then(r => r.getTjeckoSubscription)
    }, [])


    const intervals: PricingInterval[] = useMemo(() => {
        return (data?.intervals ?? []).map(interval => {
            return {
                id: interval.id,
                title: interval.interval,
                intervalType: interval.interval as IntervalType,
                discountType: interval.discountType as DiscountType,
                discount: interval.discountAmount ?? 0,
                toolTip: interval.discountAmount ? `${interval.discountAmount}% off` : undefined
            }
        })
    }, [data?.intervals]);

    const initialInterval = useMemo(() => {
        return intervals?.find(interval => interval.intervalType === 'monthly') || intervals?.[0];
    }, [intervals]);

    const [currentInterval, setCurrentInterval] = useState(initialInterval);


    const pricingFactor = useMemo(() => {
        switch (currentInterval?.intervalType) {
            case "monthly":
                return 1;
            case "yearly":
                return 12
            default:
                return 1
        }
    }, [currentInterval?.intervalType]);


    const getTierPrice = useCallback((price: number, forcePerMonth = false) => {

        // @todo switch between excl/incl VAT, currently including VAT displayed.
        const vatFactor = 1 + ((data?.vatPercentage ?? 0) / 100)
        const factor = forcePerMonth ? 1 : pricingFactor
        const intervalPrice = price * factor * vatFactor;
        const discount = currentInterval?.discount;
        const discountType = currentInterval?.discountType;

        if (!discount || !discountType) {
            return intervalPrice
        }

        if (currentInterval.discountType === 'percentage') {
            return intervalPrice * ((100 - (currentInterval.discount ?? 0)) / 100)
        }
        return intervalPrice - (currentInterval.discount ?? 0)
    }, [currentInterval, pricingFactor])


    const pricingCards: PricingCard[] = useMemo(() => {
        return (data?.tiers ?? []).map(tier => ({
            id: tier.id,
            title: tier.title,
            description: tier.description,
            price: getTierPrice(tier.price, currentInterval?.intervalType == 'yearly'),
            yearlyPrice: currentInterval?.intervalType == 'yearly' ? getTierPrice(tier.price) : undefined,
            usps: tier.benefits
                .sort((a, b) => a.title.localeCompare(b.title))
                .sort((a, b) => {
                    const aActive = a.active ? 1 : -1;
                    const bActive = b.active ? 1 : -1;
                    if (aActive === bActive) {
                        return aActive < 0 ? 1 : -1
                    }
                    return aActive < bActive ? bActive : aActive
                })
                .map(benefit => ({
                    title: benefit.title,
                    active: benefit.active
                })),
            isPopular: tier.title === 'Scale Up' && true
        }))
    }, [currentInterval, data?.tiers]);

    const pricingLabels = useMemo(() => pricingCards?.map(card => card.title), [pricingCards]);

    return (
        <ShowPageTemplate
            hideAvatar={true}
            back={{
                title: 'Create Company',
                path: '/register/company'
            }}
        >

            {!data && <div className={'absolute inset-0 flex justify-center items-center z-10'}>Loading ...</div>}
            <div className={'py-4'}>
                <PricingTabComponent
                    tabState={tabState}
                    labels={pricingLabels}
                />
            </div>

            <div className={'pt-4 pb-6 px-12'}>
                <PricingIntervalComponent
                    intervals={intervals}
                    intervalState={[currentInterval, setCurrentInterval]}
                />
            </div>

            <PricingCardComponent
                cards={pricingCards}
                pricingInterval={currentInterval}
                tabState={tabState}
            />

            <span className={'absolute bottom-6 text-xs left-0 right-0 block text-center font-medium'}>
                All prices including {data?.vatPercentage ?? 0}% VAT
            </span>
        </ShowPageTemplate>);
};

export default PlansPage;
