import React, {ButtonHTMLAttributes, FC} from 'react';
import {Button} from "@src/@/components/ui/button";
import {classNames} from "@modules/casting/String";


type Props = ButtonHTMLAttributes<HTMLButtonElement>
const ActionButton: FC<Props> = (props) => <Button
    {...props}
    variant={'primary'}
    className={classNames('!bg-primary !justify-start', props.className)}
/>

export default ActionButton;
