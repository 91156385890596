import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {IS_PRODUCTION} from "@config/variables";
import {GuardProps} from "@modules/auth/guards";


const DebugGuard: FC<GuardProps> = ({Page, children}) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (IS_PRODUCTION) {
            navigate('/');
        }
    }, []);
    return <>
        {Page ? <Page/> : children}
    </>;
};

export default DebugGuard;