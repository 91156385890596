import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

const useParamId = () => {
    const id = useParams().id;
    const [currentId, setCurrentId] = useState(id ?? '')
    useEffect(() => {
        if (!id) {
            throw new Error(`ID not provided!`)
        }
        setCurrentId(id)
    }, [id]);

    return currentId;
};

export default useParamId;
