import {FormControl, FormItem, FormLabel} from "@src/@/components/ui/form";
import {Controller, UseFormReturn} from "react-hook-form";
import {Checkbox} from "@src/@/components/ui/checkbox";
import {Label} from "@src/@/components/ui/label";
import React from "react";
import {FormSchema} from "@pages/merchant/settings/company/components/AddCompanyUserPulloverScreen";

const roles = ['Admin', 'Financial Manager', 'Subscription Manager', 'Subscriber Manager', 'User Manager']

interface AddCompanyUserRoleProps {
    form: UseFormReturn<FormSchema>
    handleRoleChange: (role: string, isChecked: boolean | 'indeterminate') => void
}

export function AddCompanyUserRole({form, handleRoleChange}: AddCompanyUserRoleProps) {
    return (
        <FormItem className={'pt-6 space-y-4'}>
            <FormLabel className={'font-roboto text-lg font-semibold text-primary'}>Roles</FormLabel>
            <div className={'flex flex-col gap-y-3'}>
                {roles.map(role => (
                    <FormControl key={role}>
                        <Controller
                            name='role'
                            control={form.control}
                            render={() => (
                                <div className={'flex'}>
                                    <Checkbox
                                        id={role}
                                        checked={form.watch('role').includes(role)}
                                        onCheckedChange={(isChecked) => handleRoleChange(role, isChecked)}
                                    />
                                    <Label htmlFor={role}
                                        className={'text-base ml-2 font-semibold hover:cursor-pointer'}>{role}</Label>
                                </div>
                            )}
                        />
                    </FormControl>
                ))}
            </div>
        </FormItem>
    )
}