import React from 'react'
import {CompanyUser} from "@app/mock/MockCompanyData";
import {CompanyUserDetails} from "@pages/merchant/settings/company/components/CompanyUserDetails";
import {CompanyUserRole} from "@pages/merchant/settings/company/components/CompanyUserRole";


interface EditCompanyUserPulloverScreenProps {
    user: CompanyUser | undefined
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
}

export function EditCompanyUserPulloverScreen({user, changeUserRole}: EditCompanyUserPulloverScreenProps) {

    return (
        <div className={'divide-y text-neutral-900'}>
            <CompanyUserDetails currentUser={user}/>
            <CompanyUserRole currentUser={user} changeUserRole={changeUserRole}/>
        </div>
    )
}