import React from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Form} from '@src/@/components/ui/form';
import {PaypalIcon} from "@pages/merchant/onboarding/icons/PayPal";
import {SEPAIcon} from "@pages/merchant/onboarding/icons/SEPA";
import {IDEALIcon} from "@pages/merchant/onboarding/icons/IDEAL";
import {PaymentMethodsInput} from "@pages/merchant/onboarding/components/PaymentMethodsInput";
import {VATRateInput} from "@pages/merchant/onboarding/components/VATRateInput";
import {FormTextInput} from "@pages/merchant/onboarding/components/FormTextInput";

export const availablePaymentMethods = [
    {
        name: 'SEPA',
        icon: <SEPAIcon/>
    },
    {
        name: 'iDEAL',
        icon: <IDEALIcon/>
    },
    {name: 'Credit Card', icon: ''},
    {
        name: 'PayPal',
        icon: <PaypalIcon/>
    },
    {name: 'Others', icon: ''},
];


const paymentMethodSchema = z.object({
    methodName: z.string().min(1),
    feeType: z.string().min(1),
    feeAmount: z.string().min(1),
});

const formSchema = z.object({
    title: z.string().min(1, 'Title is required'),
    description: z.string().min(1, 'Description is required'),
    VATRate: z.string().min(1, 'VATRate is required'),
    paymentMethods: z.array(paymentMethodSchema).min(1, 'Choose at least one (1) Payment method'),
});

export type FormSchema = z.infer<typeof formSchema>;

const CreateSubscriptionFormPullOverScreen = () => {
    const form = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: '',
            description: '',
            VATRate: '',
        }
    });

    const {errors} = form.formState

    const {fields, append, remove} = useFieldArray({
        control: form.control,
        name: 'paymentMethods',
    });

    const handleCheckboxChange = (method: string) => {
        const methodIndex = fields.findIndex((field) => field.methodName === method);

        if (methodIndex !== -1) {
            remove(methodIndex);
        } else {
            const selectedMethod = availablePaymentMethods.find(item => item.name === method);
            if (selectedMethod) {
                append({methodName: method, feeType: '', feeAmount: ''});
            }
        }
    };


    return (
        <>
            <Form {...form}>
                <form
                    className='h-full flex flex-col space-y-2 overflow-y-auto'>
                    {
                        ['title', 'description'].map((name, index) => {
                            return (
                                <FormTextInput key={index} name={name} errors={errors}/>
                            )
                        })
                    }
                    <VATRateInput errors={errors}/>
                    <PaymentMethodsInput fields={fields} form={form}
                        handleCheckboxChange={handleCheckboxChange} errors={errors}/>
                </form>
            </Form>
        </>
    );
}
export default CreateSubscriptionFormPullOverScreen;