import React from "react";
import {FormControl,} from "@shadcn/components/ui/form"
import {ExtendFormFieldProps} from "@components/form/fields/FormField.component";
import {BaseToggle} from "@components/toggle/Toggle.component";


type Props<T> = ExtendFormFieldProps<T>

const Toggle = <T = boolean>({field}: Props<T>) => {
    return (
        <FormControl>
            <BaseToggle onChange={field.onChange} value={field.value} />
        </FormControl>
    )
}
export default Toggle