import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import {$createHeadingNode, HeadingNode, HeadingTagType} from "@lexical/rich-text";
import {$createParagraphNode, $getSelection, $isRangeSelection} from "lexical";
import {$setBlocksType} from "@lexical/selection";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {classNames} from "@modules/casting/String";

type TextStyleTag = 'h1' | 'h2' | 'h3' | 'p';
type TextStyleName = 'Title' | 'Heading' | 'Subheading' | 'Body';
type TextStyleItem = {
    tag: TextStyleTag,
    name: TextStyleName,
    style: CSSProperties
}


const textStyleItems: TextStyleItem[] = [
    {
        tag: 'h1',
        name: 'Title',
        style: {
            fontSize: '1.375rem',
            fontWeight: 600
        }
    },
    {
        tag: 'h2',
        name: 'Heading',
        style: {
            fontSize: '1.125rem',
            fontWeight: 500
        }
    },
    {
        tag: 'h3',
        name: 'Subheading',
        style: {
            fontSize: '1rem',
            fontWeight: 600,
            letterSpacing: '-0.03125rem'
        }
    },
    {
        tag: 'p',
        name: 'Body',
        style: {
            fontSize: '0.875rem'
        }
    },
]

export function TextStyleGroup() {

    const [editor] = useLexicalComposerContext();

    const [activeTag, setActiveTag] = useState<TextStyleTag>('p')

    useEffect(() => {
        return editor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    const anchorNode = selection.anchor.getNode();
                    const topBlock = anchorNode.getTopLevelElementOrThrow();

                    if (topBlock.getType() === 'heading') {
                        const headingNode = topBlock as HeadingNode;
                        setActiveTag(headingNode.getTag() as TextStyleTag);
                    } else {
                        setActiveTag('p');
                    }
                }
            });
        });
    }, [editor]);

    const handleClick = (type: TextStyleItem) => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection) && type.tag != 'p') {
                $setBlocksType(selection, () => $createHeadingNode(type.tag as HeadingTagType));
            } else {
                editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        $setBlocksType(selection, () => $createParagraphNode());
                    }
                });
            }
        });
    }

    const item: TextStyleTag = useMemo(() => {
        return editor.getEditorState().read(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchorNode = selection.anchor.getNode();
                const topBlock = anchorNode.getTopLevelElementOrThrow();
                if (topBlock.getType() === 'heading') {
                    const headingNode = topBlock as HeadingNode;
                    return headingNode.getTag() as TextStyleTag
                } else {
                    return 'p';
                }
            }
            return 'p'
        })
    }, [editor.getEditorState()._selection, activeTag]);

    return (
        <div className={'w-full'}>
            <div
                className={'h-10 flex  gap-x-0 items-center justify-between w-full text-neutral-100'}>
                {textStyleItems.map(type => <button
                    onClick={() => handleClick(type)}
                    className={classNames('px-2.5 h-full rounded-lg', item == type.tag ? 'bg-primary text-neutral-100' : 'text-primary')}
                    key={type.tag}
                    style={type.style}
                >
                    {type.name}
                </button>)}
            </div>
        </div>
    );
}
