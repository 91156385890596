import React, {FC} from "react";
import Card from "@components/card/Card.component";


export type DescriptionCardType = {
    title: string,
    subject: string,
}

type Props = DescriptionCardType


const HighlightCard: FC<Props> = ({
    title,
    subject
}) => {


    return (
        <Card className={'opacity-85 grid grid-cols-12 !py-shell !px-0'}>
            <div className={'col-span-2 flex content-center items-center text-center'}>
                <span className={'!text-[2rem] mx-auto !text-primary-900'}>🥳</span>
            </div>
            <dl className={'col-span-10'}>
                <dt className={'text-md text-neutral font-normal font-roboto leading-5'}>{title}</dt>
                <dd className={'text-xl text-primary font-medium font-roboto leading-7'}>{subject}</dd>
            </dl>
        </Card>
    )
}
export default HighlightCard