import React, {ComponentType, HTMLInputTypeAttribute, useMemo} from "react";
import {Control, ControllerRenderProps, FieldValues, Path} from "react-hook-form";
import {baseObjectOutputType, ZodRawShape, ZodType} from "zod/lib/types";
import {GridColSpan} from "@modules/tailwind/Tailwind.module";
import {SelectOption} from "@components/form/fields/Select.component";
import {
    FormDescription,
    FormField as ShadCnFormField,
    FormItem as ShadCnFormItem,
    FormLabel,
    FormMessage,
} from "@src/@/components/ui/form";
import {objectUtil} from "zod";
import addQuestionMarks = objectUtil.addQuestionMarks;


/**
 * Shared properties for all fields.
 */
type BaseFormField<T> = {
    label?: string,
    description?: string,
    defaultValue?: T,
    placeholder?: string
    onChange?: (value: string | number) => void
}

/**
 * Shared properties for all fields.
 */
type BaseFormFieldImplementation<T, ZodT = ZodType<T>> = BaseFormField<T> & {
    /** Define the validation rules for the field with Zod */
    zodType: ZodT,
    colSpan?: GridColSpan | GridColSpan[],
    disabled?: boolean
}

export type InputFormField<T, ZodT = ZodType<T>> =
    BaseFormFieldImplementation<T, ZodT>
    & { fieldType: 'input' | 'textarea' }

export type CheckboxFormField<T = boolean, ZodT = ZodType<T>> =
    BaseFormFieldImplementation<T, ZodT>
    & { fieldType: 'checkbox' | 'toggle' }

export type SelectFormField<T = string | number | undefined, ZodT = ZodType<T>> =
    BaseFormFieldImplementation<string | number | undefined, ZodT>
    & {
    fieldType: 'select',
    options: SelectOption<string | number | undefined>[]
}
/**
 * Field definition switch between input, checkbox, toggle | select
 */
export type ImplementFormField<T, ZodT = ZodType<T>> =
    InputFormField<T, ZodT>
    | CheckboxFormField<T, ZodT>
    | SelectFormField<T, ZodT>

/**
 * Wrapper definition for form fields, use this type for providing form fields to the form component.
 */
export type FormFields<Fields> = { [K in keyof Fields]: ImplementFormField<Fields[keyof Fields]> }

/**
 * Type definition for form fields.
 */
export type ExtendFormFieldProps<T> = BaseFormField<T> & {
    field: ControllerRenderProps<FieldValues, string>
    name: string,
    className?: string,
    placeholder?: string,
    type?: HTMLInputTypeAttribute
}


export type FormFieldItem<T = never, P = never> = {
    Component: ComponentType<P>,
    className?: string,
    formField: ImplementFormField<T>
    props: P
}

type Props<T, P, ZodShape extends ZodRawShape> = {
    control: Control<{ [k in keyof addQuestionMarks<baseObjectOutputType<ZodShape>>]: addQuestionMarks<baseObjectOutputType<ZodShape>>[k]; }>
    name: string,
    item: FormFieldItem<T, P>
}
const FormField = <T, P, Z extends ZodRawShape>({control, name, item}: Props<T, P, Z>) => {

    const {Component, className, formField, props} = item


    type ZodValueType = 'ZodString' | 'ZodBoolean' | 'ZodNumber'
    type InputValueType = 'text' | 'number' | 'boolean'
    type ZodValueMap = {
        [K in ZodValueType]: InputValueType
    }

    const zodValueMap = useMemo((): ZodValueMap => ({
        'ZodString': 'text',
        'ZodBoolean': 'boolean',
        'ZodNumber': 'number'
    }), [])

    const valueType = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const zodValueType: ZodValueType = formField.zodType._def?.innerType?._def?.typeName ?? formField.zodType._def?.typeName
        return zodValueMap[zodValueType]
    }, [formField.zodType]);

    return <ShadCnFormField
        key={name}
        control={control}
        name={name as Path<never>}
        render={({field}) => {
            return (
                <ShadCnFormItem className={className}>
                    {formField.label && ["input", "textarea", "select", 'toggle'].includes(formField.fieldType) &&
                        <FormLabel>{formField.label}</FormLabel>}
                    <Component {...props} type={valueType} field={field} placeholder={item.formField.placeholder}
                        onChange={item.formField.onChange && item.formField.onChange(field.value)}/>
                    {formField.description && <FormDescription>{formField.description}</FormDescription>}
                    {formField.label && formField.fieldType == 'checkbox' &&
                        <FormLabel className={'inline-block relative left-2 bottom-0.5'}>{formField.label}</FormLabel>}
                    <FormMessage/>
                </ShadCnFormItem>

            )
        }}
    />
}

export default FormField