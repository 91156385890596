import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {API_URL} from "@config/variables";
import useIdToken from "@modules/auth/useIdToken";


type Config = {
    baseUrl?: string,
    axiosRequestConfig?: AxiosRequestConfig
}

const useAxios = () => {

    const {idToken} = useIdToken();

    const get = async <T, >(uri: string, config: Config = {baseUrl: API_URL}): Promise<AxiosResponse<T>> =>
        await axios.get<T>(`${config.baseUrl}/${uri}`, {
            ...config.axiosRequestConfig,
            headers: {
                Authorization: `Bearer ${idToken}`,
                ...config.axiosRequestConfig?.headers
            },
        })

    const post = async <T, >(uri: string, data: object, config: Config = {baseUrl: API_URL}): Promise<AxiosResponse<T>> =>
        await axios.post<T>(`${config.baseUrl}/${uri}`, data, {
            ...config.axiosRequestConfig,
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': "multipart/form-data",
                ...config.axiosRequestConfig?.headers
            },
        })

    return {
        get,
        post
    }
}

export default useAxios;
