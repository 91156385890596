import React, {FC, useMemo} from 'react';
import ListPageTemplate from "@templates/page/ListPage.template";
import PaymentListComponent from "@pages/merchant/payment/components/PaymentListComponent";
import {mockPaymentData} from "@app/mock/MockPaymentData";
import PaymentListFilter from "@pages/merchant/payment/list/components/PaymentListFilter";

const PaymentListPage: FC = () => {


    const items = useMemo(() => {
        return mockPaymentData
    }, []);


    return <ListPageTemplate navChildren={<PaymentListFilter/>}>
        <PaymentListComponent items={items}/>
    </ListPageTemplate>
};

export default PaymentListPage;
