import {RouterDefinition} from "@config/router.config";
import {__t} from "@config/i18n";
import PaymentIndexPage from "@pages/merchant/payment/index/Payment.index.page";
import PaymentListPage from "@pages/merchant/payment/list/Payment.list.page";
import SubscriberShowPage from "@pages/merchant/subscriber/show/Subscriber.show.page";
import SubscriptionIndexPage from "@pages/merchant/subscriptions/index/Subscription.index.page";
import SettingsIndexPage from "@pages/merchant/settings/index/Settings.index.page";
import SubscriberIndexPage from "@pages/merchant/subscriber/index/Subscriber.index.page";
import LoginPage from "@pages/auth/login/Login.page";
import AuthorizePage from "@pages/auth/authorize/Authorize.page";
import CompanySettingsIndexPage from "@pages/merchant/settings/company/CompanySettings.index.page";
import SubscriptionShowPage from "@pages/merchant/subscriptions/show/Subscription.show.page";
import TierShowPage from "@pages/merchant/tiers/Tier.show.page";
import OnboardingShowPage from "@pages/merchant/onboarding/Onboarding.show.page";
import CheckoutIndexPage from "@pages/merchant/checkout/index/CheckOut.index.page";
import CompanyRegisterPage from "@pages/merchant/registration/company/Company.register.page";
import MerchantRegisterPage from "@pages/merchant/registration/merchant/Merchant.register.page";
import CheckoutSuccessPage from "@pages/merchant/checkout/pages/success/CheckOut.success.page";
import PlansPage from "@pages/merchant/plans/Plans.page";
import {OtpPage} from "@pages/auth/mfa/Otp.page";
import MfaSetupIndexPage from "@pages/merchant/settings/personal/mfa/MfaSetupIndex.page";
import HomePage from "@pages/Home/Home.page";
import LogoutPage from "@pages/auth/logout/Logout.page";
import MfaScanQrCodePage from "@pages/merchant/settings/personal/mfa/MfaSetupQrCode.page";
import MfaSetupVerifyOtpPage from "@pages/merchant/settings/personal/mfa/MfaSetupVerifyOtp.page";
import MfaSetupVerifyOtpSuccessPage from "@pages/merchant/settings/personal/mfa/MfaSetupVerifyOtpSuccess.page";
import MfaManagePage from "@pages/merchant/settings/personal/mfa/MfaManage.page";
import MfaDeactivatePage from "@pages/merchant/settings/personal/mfa/MfaDeactivate.page";
import MfaDeactivateSuccessPage from "@pages/merchant/settings/personal/mfa/MfaDeactivateSuccess.page";
import {BillingIndexPage} from "@pages/merchant/settings/billing/Billing.index.page";
import PaymentShowPage from "@pages/merchant/payment/show/Payment.show.page";
import {SubscriberListPage} from "@pages/merchant/subscriber/list/Subscriber.list.page";
import {SubscriberInvoiceList} from "@pages/merchant/subscriber/show/components/SubscriberInvoiceList";
import {CompanyManageUsers} from "@pages/merchant/settings/company/CompanyManageUsers";
import {MerchantTOS} from "@pages/merchant/tos/MerchantTOS";
import {MerchantTOSShowPage} from "@pages/merchant/tos/MerchantTOSShowPage";
import {MerchantTOSEditor} from "@pages/merchant/tos/MerchantTOSEditor";
import {TjeckoSubscriptionRedirect} from "@pages/merchant/onboarding/pages/TjeckoSubscriptionRedirect";


/**
 * Base routes should be defined top level.
 * The component of the base route should be defined as a child.
 * The base route component should have 'index=true'.
 * The base route index component should not have children.
 *
 * Children are only supported by the top level route (for now).
 * Pseudo children can be defined by adding the parent in the path.
 */
const Routes: RouterDefinition = [
    {
        path: '/',
        children: [
            {
                title: 'Home',
                index: true,
                Component: HomePage,
            }
        ]
    },
    {
        path: 'authorize',
        children: [
            {
                title: 'Validating login',
                index: true,
                Component: AuthorizePage,
            }
        ]
    },
    {
        path: 'login',
        children: [
            {
                title: 'Login',
                index: true,
                Component: LoginPage,
            }
        ]
    },
    {
        path: 'otp',
        children: [
            {
                title: 'One Time Password',
                index: true,
                Component: OtpPage,
            },
        ]
    },
    {
        path: 'logout',
        children: [
            {
                title: 'Logout',
                index: true,
                Component: LogoutPage,
            }
        ]
    },
    {
        path: 'register',
        children: [

            {
                title: 'Create account',
                index: true,
                Component: MerchantRegisterPage,
            },
            {
                title: 'Register Company',
                path: 'company',
                Component: CompanyRegisterPage,
            },
            {
                title: 'Payment Status',
                path: 'payment',
                Component: TjeckoSubscriptionRedirect,
            },
        ]
    },
    {
        path: 'plans',
        children: [

            {
                title: 'Choose a plan',
                index: true,
                Component: PlansPage,
            },
        ]
    },
    {
        path: 'checkout',
        children: [
            {
                title: 'Check out',
                index: true,
                Component: CheckoutIndexPage,
            },
            {
                path: 'success',
                title: 'Check out succeeded',
                Component: CheckoutSuccessPage,
            }
        ]
    },

    {
        path: 'onboarding',
        isGuarded: true,
        children: [
            {
                title: 'Onboarding',
                index: true,
                Component: OnboardingShowPage,
            }
        ],
    },
    {
        path: 'payments',
        isGuarded: true,
        isDebug: true,
        children: [
            {
                title: __t('payments.title.plural'),
                index: true,
                Component: PaymentIndexPage,
            },
            {
                title: __t('payments.title.singular'),
                path: ':id',
                Component: PaymentShowPage,
            },
            {
                title: __t('payments.title.plural'),
                path: 'list',
                Component: PaymentListPage,
            },
            {
                title: __t('payments.title.singular'),
                path: 'list/:id',
                Component: PaymentShowPage,
            },
        ],
    },
    {
        path: 'subscriptions',
        isGuarded: true,
        children: [
            {
                title: __t('subscriptions.title.plural'),
                index: true,
                Component: SubscriptionIndexPage
            },
            {
                title: __t('subscriptions.title.singular'),
                path: ':id',
                Component: SubscriptionShowPage,
            },
            {
                title: 'Tier',
                path: ':subscriptionId/tiers/:id',
                Component: TierShowPage,
            }
        ],
    },
    {
        path: 'subscribers',
        isGuarded: true,
        children: [
            {
                title: __t('subscribers.title.plural'),
                index: true,
                Component: SubscriberIndexPage,
            },
            {
                title: __t('subscribers.title.plural'),
                path: 'list',
                Component: SubscriberListPage,
            },
            {
                title: __t('subscribers.title.singular'),
                path: 'list/:id',
                Component: SubscriberShowPage,
            },
            {
                title: __t('subscribers.title.singular'),
                path: 'list/:id/invoice',
                Component: SubscriberInvoiceList
            }
        ],
    },
    {
        path: 'settings',
        isGuarded: true,
        children: [
            {
                title: __t('settings.title.plural'),
                index: true,
                Component: SettingsIndexPage,
            },
            {
                title: 'Company Settings',
                path: 'company',
                Component: CompanySettingsIndexPage,
            },
            {
                title: 'Manage Users',
                path: 'manage-users',
                Component: CompanyManageUsers
            },
            {
                title: 'Setup MFA',
                path: 'setup-mfa',
                Component: MfaSetupIndexPage,
            },
            {
                title: 'Setup MFA',
                path: 'setup-mfa/scan-qr',
                Component: MfaScanQrCodePage,
            },
            {
                title: 'Verify MFA',
                path: 'setup-mfa/scan-qr/verify-otp',
                Component: MfaSetupVerifyOtpPage,
            },
            {
                title: 'MFA verified',
                path: 'setup-mfa/scan-qr/verify-otp/success',
                Component: MfaSetupVerifyOtpSuccessPage,
            },
            {
                title: 'Manage MFA',
                path: 'manage-mfa',
                Component: MfaManagePage,
            },
            {
                title: 'Deactivate MFA',
                path: 'manage-mfa/deactivate',
                Component: MfaDeactivatePage,
            },
            {
                title: 'MFA deactivated',
                path: 'manage-mfa/deactivate/success',
                Component: MfaDeactivateSuccessPage,
            },
            {
                title: 'Billing',
                path: 'billing',
                Component: BillingIndexPage
            },
            {
                title: 'ToS',
                path: 'tos',
                Component: MerchantTOS
            },
            {
                title: 'ToS',
                path: 'tos/:id',
                Component: MerchantTOSShowPage
            },
            {
                title: 'ToS Edit',
                path: 'tos/:id/edit',
                Component: MerchantTOSEditor
            }
        ],
    },

] satisfies RouterDefinition;

export default Routes;
