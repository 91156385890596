import React, {FC} from 'react';
import DescriptionCard, {DescriptionCardType} from "@components/card/DescriptionCard.component";
import {GroupsOutlined} from "@mui/icons-material";
import Pill from "@components/indicator/Pill.component";
import {toEuroString} from "@modules/casting/Number";
import {Link} from "react-router-dom";


type Props = Omit<DescriptionCardType, 'badge'> & {
    id: string
    revenue: number,
    activeSubscribers: number,
}
const SubscriptionCard: FC<Props> = ({
    id,
    revenue,
    activeSubscribers,
    ...props
}) => {
    return <Link to={`/subscriptions/${id}`}>
        <DescriptionCard
            {...props}
            context={<span className={'font-medium text-primary'}>{toEuroString(revenue)}</span>}
            badge={<Pill variation={"secondary"} Icon={GroupsOutlined} title={activeSubscribers}/>}
        />
    </Link>;
};

export default SubscriptionCard
