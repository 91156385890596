import * as firebase from 'firebase/app';
import * as fcm from 'firebase/messaging';
import { firebaseConfig, vapidKey } from './config';

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Check for valid VAPID key
if (!vapidKey) {
    throw new Error('VAPID key is not defined.');
}

// Initialize Firebase Cloud Messaging
const messaging = fcm.getMessaging(firebaseApp);

export { messaging };