import React, {FC} from 'react';
import {classNames} from "@modules/casting/String";
import BaseMessage, {MessageProps} from "@components/feedback/Message/BaseMessage";

const SuccessMessage: FC<MessageProps> = ({message, className}) => {
    return <BaseMessage
        message={message}
        className={classNames('bg-positive-300/50 text-positive-700', className)}
    />
};

export default SuccessMessage;
