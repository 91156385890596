
import {useEffect, useState} from 'react';
import { messaging } from './firebase';
import * as fcm from "firebase/messaging";
import {vapidKey} from "@app/fcm/config";

const useFirebaseMessaging = () => {
    const [token, setToken] = useState<string | null>(null);

    const getOrRegisterServiceWorker = async () => {
        if (
            "serviceWorker" in navigator &&
            typeof window.navigator.serviceWorker !== "undefined"
        ) {
            const serviceWorker = await window.navigator.serviceWorker
                .getRegistration("/firebase-push-notification-scope");
            if (serviceWorker) {
                return serviceWorker;
            }
            return window.navigator.serviceWorker.register(
                "/firebase-messaging-sw.js",
                {
                    scope: "/firebase-push-notification-scope",
                }
            );
        }
        throw new Error("The browser doesn`t support notifications.");
    };

    const requestPermission = () => {
        // Request permission to show notifications
        const requestPermission = async () => {
            try {
                const currentToken = await fcm.getToken(messaging, {vapidKey: vapidKey})
                setToken(currentToken);
            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };

        Notification.requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    requestPermission();
                } else {
                    console.warn('Notification permission denied: ', permission);
                }
            })
            .catch((error) => {
                console.error('Failed to request notification permission:', error);
            });
    }

    useEffect(() => {
        getOrRegisterServiceWorker().then(requestPermission).catch(() => console.warn('insecure operation - sw can\'t be added like this.'))
    }, []);

    return {token, requestPermission};
};

export default useFirebaseMessaging;