import React, {Dispatch, SetStateAction, useMemo} from "react";
import {classNames} from "@modules/casting/String";


export type ChartTabItem<T = string> = {
    title: string,
    value: T
}

type Props<T = string> = {
    tabs: ChartTabItem<T>[],
    tabState: [ChartTabItem<T> | undefined, Dispatch<SetStateAction<ChartTabItem<T> | undefined>>]
}

const ChartTab = <T = string>({tabs, tabState}: Props<T>) => {

    const [currentTab, setTab] = useMemo(() => tabState, [tabState])

    const handleTabClick = (tab: ChartTabItem<T>) => {
        setTab(tab)
    }

    const isCurrentTab = (tab: ChartTabItem<T>) => currentTab?.value == tab.value

    return (
        <div className={'w-full h-12'}>
            <div className={'bg-neutral-300/85 w-fit float-right rounded'}>
                {tabs.map((tab, index) => <button
                    key={index}
                    onClick={() => handleTabClick(tab)}
                    className={classNames('font-medium px-2 py-1 text-xs rounded',
                        isCurrentTab(tab)
                            ? 'bg-primary-800 text-white'
                            : 'text-neutral'
                    )}
                >
                    {tab.title}
                </button>)}
            </div>
        </div>
    )
}

export default ChartTab