import React, {FC} from 'react';
import {DragHandle} from "@mui/icons-material";
import {BaseToggle} from "@components/toggle/Toggle.component";
import Card from "@components/card/Card.component";

type Props = {
    id: string,
    title: string,
    description?: string,
    active: boolean
    handleEdit: (benefitId: string) => void,
    handleActive: (benefitId: string) => void,
}

const BenefitCard: FC<Props> = ({
    id,
    title,
    description,
    active,
    handleEdit,
    handleActive
}) => {
    return (
        <Card className={'grid grid-cols-12 gap-x-2 !py-3'}>
            <div className={'col-span-1 flex items-center'}>
                <DragHandle/>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <div
                role={'button'}
                className={'col-span-9 text-left pl-2'}
                onClick={() => handleEdit(id)}
            >
                <dl className={''}>
                    <dt className={'text-lg text-primary font-medium font-roboto leading-6'}>
                        {title}
                    </dt>
                    <dd className={'text-[0.95rem] text-neutral line-clamp-2 leading-5'}>
                        {description}
                    </dd>
                </dl>
            </div>
            <div className={'col-span-2 text-right flex items-center relative'}>
                <BaseToggle
                    className={'absolute right-0'}
                    onChange={() => handleActive(id)}
                    value={active}
                />
            </div>
        </Card>
    );
};

export default BenefitCard;
