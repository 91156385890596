import React, {FC, ReactNode, useEffect, useMemo, useState} from 'react';
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@src/@/components/ui/tabs";
import {classNames} from "@modules/casting/String";

type Tab = {
    trigger: string,
    default?: boolean
    content: ReactNode
}

type Props = {
    tabs: Tab[],
    className?: string
}

const TabsComponent: FC<Props> = ({tabs, className}) => {


    const initialCurrentTab = useMemo(() => {
        const defaultTab = tabs.find(t => t.default === true)?.trigger
        return defaultTab ?? tabs?.[0]?.trigger
    }, [tabs]);

    const [currentTab, setCurrentTab] = useState(initialCurrentTab)
    const [renderedBefore, setRenderedBefore] = useState<string[]>([])

    useEffect(() => {
        if (renderedBefore.find(item => item == currentTab) == undefined) {
            setRenderedBefore([...renderedBefore, currentTab])
        }
    }, [currentTab]);

    const isRenderedBefore = (trigger: string) => {
        return renderedBefore.find(item => item == trigger) != undefined
    }

    const isCurrentTab = (trigger: string) => {
        return currentTab == trigger
    }


    return (
        <Tabs defaultValue={currentTab} onValueChange={setCurrentTab}
            className={classNames('-mx-shell bg-neutral-300/65', className)}>
            <TabsList className={' w-full flex gap-x-2 px-shell py-8 rounded-none border-b border-b-neutral-400'}>
                {tabs.map(tab => {
                    const currentTab = isCurrentTab(tab.trigger);

                    return <TabsTrigger
                        key={tab.trigger}
                        value={tab.trigger}
                        className={classNames('w-full py-1.5', currentTab ? 'text-neutral-100 bg-primary font-semibold' : 'text-neutral')}
                    >
                        {tab.trigger}
                    </TabsTrigger>
                })}
            </TabsList>

            <div className={'mt-shell'}>
                {tabs.map(tab => {
                    const mountState = !isRenderedBefore(tab.trigger) ? undefined : true;
                    const currentTab = isCurrentTab(tab.trigger);
                    return <TabsContent
                        className={classNames('px-shell', mountState && !currentTab && 'hidden')}
                        forceMount={mountState}
                        key={tab.trigger}
                        value={tab.trigger}
                    >
                        {tab.content}
                    </TabsContent>
                })}
            </div>

        </Tabs>
    );
};

export default TabsComponent;
