import React, {FC, useMemo, useRef, useState} from "react";
import {Chart as ChartJS, ChartData} from "chart.js";
import ChartTab, {ChartTabItem} from "@components/chart/ChartTab";
import BalanceDifferenceIcon from "@components/icon/BalanceDifferenceIcon";
import {LineChart} from "@components/chart/Chart";
import {getChartDataSet} from "@components/chart/ChartData";
import {toEuroString, toPercentageString} from "@modules/casting/Number";
import {classNames} from "@modules/casting/String";
import {dummyPaymentChartData, dummyRevenueData} from "@app/mock/MockPaymentData";
import moment from "moment";


const TierRevenueChart: FC = () => {

    const chartRef = useRef<ChartJS>(null);


    const tabs = useMemo((): ChartTabItem<number>[] => [
        {
            title: '3M',
            value: 3
        },
        {
            title: '6M',
            value: 6
        },
        {
            title: '12M',
            value: 12
        }
    ], []);

    const tabState = useState<ChartTabItem<number> | undefined>(tabs[1])
    const currentTab = tabState[0]

    const {revenue, percentageChanged} = useMemo(() => {
        const prev = currentTab?.value ?? 0
        return dummyRevenueData(moment('2024-07-01').subtract(prev > 0 ? prev - 1 : 0, 'months').format('YYYY-MM'))
    }, [currentTab]);

    const paymentChartData = useMemo(() => {
        return dummyPaymentChartData()
    }, []);


    const filteredData = useMemo(() => {
        return paymentChartData.slice(-(currentTab?.value ?? 0))
    }, [currentTab, paymentChartData]);


    const chartData = useMemo((): ChartData<'line'> => {
        return getChartDataSet(filteredData)
    }, [chartRef.current, filteredData]);

    const changeIsPositive = useMemo(() => {
        return percentageChanged >= 0
    }, [percentageChanged]);

    return <div>
        <h2 className={'font-medium font-roboto text-sm text-neutral'}>Revenue</h2>

        <div className={'flex items-center gap-x-3 mt-2'}>
            <p className={'text-2xl font-semibold text-neutral'}>{toEuroString(revenue)}</p>
            <div className={classNames('flex gap-x-1.5 font-semibold',
                changeIsPositive ? 'text-positive-800' : 'text-negative-700'
            )}>
                <BalanceDifferenceIcon isPositive={changeIsPositive}/>
                <span>{changeIsPositive && '+'}{toPercentageString(percentageChanged)}</span>
            </div>
        </div>
        <div className={'-mt-8'}>
            <ChartTab tabState={tabState} tabs={tabs}/>
            <div className={'relative w-full h-[8rem] overflow-hidden -mt-6'}>
                <LineChart data={chartData} ref={chartRef}
                    className={'!h-[8rem] absolute left-[-1.8rem] !w-[calc(100vw+1rem)]'}/>
            </div>
        </div>

    </div>
}

export default TierRevenueChart;