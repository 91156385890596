import React, {FC, useEffect} from 'react';
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import BasePageTemplate, {ExtendPageProps} from "@templates/page/BasePage.template";


type Props = ExtendPageProps
const ListPage: FC<Props> = (props) => {

    const navBottom = useNavbarBottom();
    const setHideNavbarBottom = navBottom.hide[1];

    useEffect(() => {
        setHideNavbarBottom(true)
    }, []);


    return <BasePageTemplate {...props} fullWidth={true} fullHeight={true}/>
};

export default ListPage;
