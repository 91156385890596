import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState} from "react";
import {RouteItem} from "@modules/routing/useRouter";

export type NavRouteItem = Omit<RouteItem, 'parent' | 'title'> & {
    title: ReactNode
}
type NavbarTopContextType = {
    hide: [boolean, Dispatch<SetStateAction<boolean>>]
    title: [string|undefined, Dispatch<SetStateAction<string|undefined>>]
    back: [NavRouteItem | undefined, Dispatch<SetStateAction<NavRouteItem | undefined>>]
    next: [NavRouteItem | undefined, Dispatch<SetStateAction<NavRouteItem | undefined>>]
    height: [number, Dispatch<SetStateAction<number>>]
    children: [ReactNode | undefined, Dispatch<SetStateAction<ReactNode | undefined>>]
}

const NavbarTopDefaultContext: NavbarTopContextType = {
    hide: [false, () => undefined],
    title: ['', () => undefined],
    back: [undefined, () => undefined],
    next: [undefined, () => undefined],
    height: [0, () => null],
    children: [undefined, () => undefined],
}

const NavbarTopContext = createContext<NavbarTopContextType>(NavbarTopDefaultContext);


export const useNavbarTop = () => useContext(NavbarTopContext);
export const NavbarTopProvider: FC<{ children?: ReactNode | undefined }> = props => {

    const hide = useState(true)
    const title = useState<string>()
    const back = useState<NavRouteItem>()
    const next = useState<NavRouteItem>()
    const children = useState<ReactNode>()
    const height = useState<number>(0)

    return (
        <NavbarTopContext.Provider value={{
            hide,
            title,
            back,
            next,
            height,
            children
        }}>
            {props.children}
        </NavbarTopContext.Provider>
    );
};
