import React, {useEffect, useMemo, useState} from 'react';
import useMollie from "@hooks/useMollie";
import useAsyncMemo from "@hooks/useAsyncMemo";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import ProgressCard, {ProgressCardType} from "@components/card/ProgressCard.component";
import useGql from "@modules/graphql/useGql";
import useAuth from "@hooks/useAuth";
import PullOver from "@components/pullover/PullOver.component";
import AddCompanyBrandingPulloverScreen from "@pages/merchant/onboarding/pages/AddCompanyBranding.show.page";
import CreateSubscriptionFormPullOverScreen
    from "@pages/merchant/onboarding/pages/CreateSubscriptionFormPullOverScreen";
import PrimaryButton from "@components/button/PrimaryButton";
import {Link} from "react-router-dom";


const OnboardingShowPage = () => {

    const {userId} = useAuth()
    const mollie = useMollie()
    const gql = useGql()

    const [showCompanyBrandingPullover, setShowCompanyBrandingPullover] = useState(false)
    const [showSubscriptionCreatePullover, setShowSubscriptionCreatePullover] = useState(false)

    const authorizeUrl = useAsyncMemo(async () => {
        return mollie.isAuthorized().then(async isAuthorized => {
            if (!isAuthorized) {
                return await mollie.authorizeUrl()
            }
            return undefined;
        }).catch(async () => await mollie.authorizeUrl())
    }, [])


    const companyId = useAsyncMemo(async () => {
        return await gql.GetCompanies().then(res => {
            return res.getCompanies?.[0].id
        })
    }, [userId])

    const onboardingProgress = useAsyncMemo(async () => {
        if (!companyId) {
            return undefined
        }
        return await gql.GetMerchantProfileProgress().then(res => {
            return res
        })
    }, [companyId])

    const [merchantTheme, setMerchantTheme] = useState(false)

    useEffect(() => {
        if (!companyId) {
            return;
        }
        gql.GetMerchantTheme({id: companyId}).then(res => {
            setMerchantTheme(res.getMerchantTheme !== undefined)
        })
    }, [companyId])

    const [placeHolderSubscriptionComplete, setPlaceHolderSubscriptionComplete] = useState(false)
    const subscriptionComplete = useMemo(() => {
        if (!onboardingProgress) {
            return false
        }
        return onboardingProgress.getMerchantProfileProgress?.onboardingProgress.subscriptionIsComplete ?? false
    }, [onboardingProgress]);


    const [placeHolderMembersComplete, setPlaceHolderMembersComplete] = useState(false)
    const membersComplete = useMemo(() => {
        if (!onboardingProgress) {
            return false
        }
        return onboardingProgress.getMerchantProfileProgress?.onboardingProgress.memberIsComplete ?? false
    }, [onboardingProgress]);


    const placeHolderDescription = useMemo(() => <span>
        <span>Lorem ipsum dolor sit amet,</span>&nbsp;
        <wbr/><span className={'whitespace-nowrap'}>consectetur adipiscing elit.</span>
    </span>, []);

    const onboardingSteps: ProgressCardType[] = useMemo(() => [
        {
            title: 'Mollie',
            description: placeHolderDescription,
            currentMessage: 'Connect',
            doneMessage: 'Connected',
            state: authorizeUrl == undefined ? 'done' : 'current',
            onClick: () => {
                if (authorizeUrl) {
                    window.location.replace(authorizeUrl)
                }
            }
        },
        {
            title: 'Company Branding',
            description: placeHolderDescription,
            currentMessage: 'Add',
            doneMessage: 'Added',
            state: merchantTheme ? 'done' : authorizeUrl == undefined ? 'current' : 'pending',
            onClick: () => {
                setShowCompanyBrandingPullover(true)
            }
        },
        {
            title: 'Create Subscription',
            description: placeHolderDescription,
            state: placeHolderSubscriptionComplete || subscriptionComplete ? 'done' : merchantTheme ? 'current' : 'pending',
            onClick: () => {
                setShowSubscriptionCreatePullover(true)
            }
        },
        {
            title: 'Invite Subscribers',
            description: placeHolderDescription,
            state: membersComplete || placeHolderMembersComplete ? 'done' : subscriptionComplete || placeHolderSubscriptionComplete ? 'current' : 'pending',
            onClick: () => {
                if (navigator.share) {
                    navigator.share({
                        title: document.title,
                        text: "Hello World",
                        url: window.location.href
                    })
                        .then(() => {
                            console.log('Successful share')
                            setPlaceHolderMembersComplete(true)
                        })
                        .catch(error => console.log('Error sharing:', error));
                }
            }
        },
    ], [
        authorizeUrl,
        merchantTheme,
        subscriptionComplete,
        membersComplete,
        placeHolderSubscriptionComplete,
        placeHolderMembersComplete
    ])


    return <>
        <ShowPageTemplate next={{
            title: 'Skip',
            path: '/payments'
        }}>
            <h2 className={'text-xl text-primary font-medium leading-10'}>Get started</h2>
            <hr/>
            {onboardingSteps.map((step, i) => <ProgressCard
                key={i}
                index={i}
                {...step}
            />)}

            {placeHolderMembersComplete && <Link to={'/payments'}>
                <PrimaryButton>Start using Tjecko</PrimaryButton>
            </Link>}
        </ShowPageTemplate>

        <PullOver variations={'max-height'}
            openState={[showCompanyBrandingPullover, setShowCompanyBrandingPullover]}
            title={'Company Branding'}
            submit={{
                title: 'Save',
                onClick: () => {
                    setShowCompanyBrandingPullover(false)
                    setMerchantTheme(true)
                }
            }}
        >
            <AddCompanyBrandingPulloverScreen/>
        </PullOver>

        <PullOver
            variations={'max-height'}
            openState={[showSubscriptionCreatePullover, setShowSubscriptionCreatePullover]}
            title={'Create Subscription'}
            submit={{
                title: 'Save',
                onClick: () => {
                    setShowSubscriptionCreatePullover(false)
                    setPlaceHolderSubscriptionComplete(true)
                }
            }}
        >
            <CreateSubscriptionFormPullOverScreen/>
        </PullOver>
    </>
};

export default OnboardingShowPage;
