import React from 'react';
import IndexPage from "@templates/page/IndexPage.template";
import PaymentChart from "@pages/merchant/payment/index/components/PaymentChart";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import LatestActivity from "@pages/merchant/payment/index/components/LatestActivity";
import CreatePaymentLink from "@pages/merchant/payment/index/components/CreatePaymentLink";
import PushNotificationHandler from "@app/fcm/notification-handler";


const PaymentIndexPage = () => {

    const gql = useGql()

    const onboardingComplete = useAsyncMemo(async () => {
        return await gql.GetCompanies().then(async r => {
            const companyId = r.getCompanies?.[0].id;
            if (!companyId) {
                return false
            }
            return await gql.GetMerchantProfileProgress()
                .then(r => r.getMerchantProfileProgress?.complete ?? false)
        })
    }, [])

    return (
        <IndexPage back={onboardingComplete === false ? {
            title: 'Onboarding',
            path: '/onboarding'
        } : undefined}>
            <PaymentChart/>
            <LatestActivity/>
            <CreatePaymentLink/>

            <PushNotificationHandler/>
        </IndexPage>
    );
};

export default PaymentIndexPage;
