import H2 from "@components/text/H2.component";
import {Link} from "react-router-dom";
import React, {useMemo} from "react";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import {SubscriberListComponent} from "@pages/merchant/subscriber/list/components/SubscriberListComponent";
import {mockSubscriberData} from "@app/mock/MockSubscriberData";

export function SubscriberList() {

    const {available} = useWorkspaceHeight()

    const listHeight = useMemo(() => {
        return `calc(${available - 208 - 41 - 30}px - 4.0625rem)`
    }, [available]);

    return (
        <div>
            <div
                className={'flex items-center justify-between border-b py-2 -mt-shell -mx-shell px-shell relative shadow-lg z-[1]'}>
                <H2>Latest Subscribers</H2>
                <Link to={'/subscribers/list'} className={'text-xs text-secondary-600'}>Show more</Link>
            </div>
            <div className={'h-[calc(1rem/32)]'}/>
            <SubscriberListComponent data={mockSubscriberData} height={listHeight} forIndex={true}/>
        </div>
    )
}