import {Button} from "@src/@/components/ui/button";
import {BiDownload} from "react-icons/bi";
import React, {Dispatch, SetStateAction} from "react";
import {PaymentItem} from "@app/mock/MockPaymentData";
import {RefundPulloverScreen} from "@pages/merchant/payment/show/components/RefundPulloverScreen";
import {RefundBanner} from "@pages/merchant/payment/show/components/RefundBanner";

export interface CustomerDetails extends PaymentItem {
    symbol: string | undefined
}

interface PaymentDetailsProps {
    customer: CustomerDetails | undefined
    setCurrentCustomer: Dispatch<SetStateAction<CustomerDetails | undefined>>
}

export function PaymentDetails({customer, setCurrentCustomer}: PaymentDetailsProps) {

    function getAmountStyling(status: string | undefined) {
        switch (status) {
            case 'paid':
                return 'text-primary-800'
            case 'failed':
                return `text-red-400 line-through `
            case 'reversed':
                return `text-red-600 before:content-['-']`
            default:
                return ''
        }
    }

    return (
        <div className={'flex flex-col gap-y-4'}>
            {
                customer?.status === 'reversed' &&
                <RefundBanner customer={customer}/>
            }

            <div className={'flex items-center justify-between'}>
                <div className={'flex items-center'}>
                    <div
                        className={'w-10 h-10 rounded-full bg-secondary-200 text-primary font-bold mr-4' +
                            ' flex content-center items-center'}>
                        <p className={'w-8 h-4 text-center text-[0.8rem] leading-4 mx-auto'}>
                            {customer?.symbol}
                        </p>
                    </div>
                    <div className={'flex flex-col'}>
                        <p className={'font-semibold text-xl text-primary'}>{customer?.title}</p>
                        <p className={'font-medium text-neutral-600 capitalize'}>{customer?.status}</p>
                    </div>
                </div>
                <div
                    className={`font-roboto font-semibold text-xl ${getAmountStyling(customer?.status)}`}>{customer?.amount}</div>
            </div>
            <div className={'ml-[54px] flex flex-col gap-y-2 text-neutral-900'}>
                <div>
                    <p className={'font-semibold'}>Subscription</p>
                    <p className={'text-sm'}>Great Service - Basic</p>
                </div>
                <div>
                    <p className={'font-semibold'}>Invoice</p>
                    <p className={'text-sm'}>F20240224007210</p>
                </div>
                <div>
                    <p className={'font-semibold'}>Timestamp</p>
                    <p className={'text-sm'}>{customer?.date}</p>
                </div>
                <div>
                    <p className={'font-semibold'}>Method</p>
                    <p className={'text-sm'}>Credit Card</p>
                </div>
            </div>
            <div className={'flex gap-x-2 p-4 border-y -mx-shell'}>
                <RefundPulloverScreen customer={customer} setCurrentCustomer={setCurrentCustomer}/>
                <Button variant={'primary'} className={'flex items-center gap-x-0.5'}><BiDownload
                    size={'20px'}/> Download Invoice</Button>
            </div>
        </div>
    )
}