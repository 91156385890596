import React from 'react';
import ListPage from "@templates/page/ListPage.template";
import {CustomerCardList} from "@pages/merchant/subscriber/show/components/CustomerCardList";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {mockSubscriberData} from "@app/mock/MockSubscriberData";
import useParamId from "@hooks/useParamId";

export function SubscriberInvoiceList() {

    const id = useParamId()

    const customer = useAsyncMemo(async () => {
        return mockSubscriberData.find(customer => customer.id === id)
    }, [id])
    return (
        <>
            {
                customer &&
                <ListPage>
                    <CustomerCardList customer={customer}/>
                </ListPage>
            }
        </>
    )
}