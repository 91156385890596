import React from 'react';
import {ControllerRenderProps} from "react-hook-form";
import {FormControl} from "@src/@/components/ui/form";
import {Textarea as ShadCnTextArea} from "@src/@/components/ui/textarea";
import {ExtendFormFieldProps} from "@components/form/fields/FormField.component";

type Props<T> = ExtendFormFieldProps<T>

const TextArea = <T = string | number>({
    placeholder,
    field
}: Props<T>) => {

    return (
        <FormControl>
            <ShadCnTextArea
                placeholder={placeholder}
                {...(field as unknown as ControllerRenderProps)}
            />
        </FormControl>

    );
};

export default TextArea;
