import React, {FC, useEffect, useMemo} from 'react';
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import BasePageTemplate, {ExtendPageProps} from "@templates/page/BasePage.template";
import {NavRouteItem} from "@components/navigation/NavbarTop/NavbarTop.context";

type Props = ExtendPageProps & {
    hideAvatar?: boolean
}

const ShowPage: FC<Props> = ({hideAvatar, next, ...props}) => {
    const navBottom = useNavbarBottom();
    const setHideNavbarBottom = navBottom.hide[1];

    useEffect(() => {
        setHideNavbarBottom(true)
    }, []);

    const navbarNextItem: NavRouteItem | undefined = useMemo(() => {
        if (!next && hideAvatar) {
            return {
                title: '',
                path: '/'
            } satisfies NavRouteItem
        }
        return next
    }, [hideAvatar, next]);

    return <BasePageTemplate next={navbarNextItem} {...props} />
};

export default ShowPage;
