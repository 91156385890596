import React, {FC} from 'react';
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {ImplementFormProps} from "@components/form/Form.component";
import {CreateBenefitInput, CreateBenefitMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import useGql from "@modules/graphql/useGql";


const BenefitCreateForm: FC<ImplementFormProps<CreateBenefitMutation> & {
    subscriptionId: string,
    tierId: string,
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]

}> = ({tierId, subscriptionId, onSubmitSuccess, openState}) => {

    const gql = useGql();
    const fields: FormFields<Omit<CreateBenefitInput, 'subscriptionId'>> = {
        title: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Benefit Name',
            description: 'Name of your benefit'
        },
        description: {
            zodType: z.string().min(1).max(64),
            fieldType: 'textarea',
            label: 'Benefit description'
        }
    }

    const handleSubmitSuccess = async (values?: CreateBenefitMutation) => {
        if (values) {
            await gql.AddBenefitToTier({benefitId: values.createBenefit.id, tierId: tierId})
        }
        await onSubmitSuccess?.(values)
    }

    return (
        <>
            <PullOverForm
                title={'Benefit test form'}
                openState={openState}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    submit: gql.CreateBenefit,
                    onSubmitSuccess: handleSubmitSuccess,
                    fields: fields,
                    payloadInjection: {
                        subscriptionId
                    }
                }}
            />
        </>
    );
};

export default BenefitCreateForm;
