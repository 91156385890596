import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import {cva, type VariantProps} from "class-variance-authority"

import {cn} from "@shadcn/lib/utils"
import {useMemo} from "react";

const getLabelVariants = cva(
    "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
)

const Label = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof getLabelVariants>
>(({className, ...props}, ref) => {

    const labelVariants = useMemo(() => getLabelVariants(), []);

    return (
        <LabelPrimitive.Root
            ref={ref}
            className={cn(labelVariants, className)}
            {...props}
        />
    )
})
Label.displayName = LabelPrimitive.Root.displayName

export {Label}
