import React from 'react';
import {Link} from "react-router-dom";

const NotFoundPage = () => {
    return (
        <div className={'p-shell'}>
            <h1 className={'text-3xl text-center text-primary'}>404</h1>
            <h2 className={'text-md text-center text-neutral'}>Page Not Found</h2>
            <Link to={'/'} className={'block w-full text-center text-neutral underline mt-4'}>Return back to home</Link>
        </div>
    );
};

export default NotFoundPage;
