import React, {FC, InputHTMLAttributes, ReactNode} from 'react';
import SearchInput from "@components/search/SearchInput.component";
import {classNames} from "@modules/casting/String";

type Props = {
    inputProps?: InputHTMLAttributes<HTMLInputElement>,
    button: ReactNode,
    className?: string
}
const SearchBar: FC<Props> = ({inputProps, button, className}) => {
    return (
        <div className={classNames('grid grid-cols-7 gap-x-4', className)}>
            <div className={'col-span-5'}>
                <SearchInput {...inputProps}/>
            </div>
            <div className={'col-span-2'}>
                {button}
            </div>
        </div>
    );
};

export default SearchBar;
