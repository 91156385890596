import React, {FC, ReactNode} from 'react';
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";

type Props = {
    children: ReactNode
}

const Main: FC<Props> = ({children}) => {

    const {marginTop, marginBottom, available} = useWorkspaceHeight();


    return (
        <main
            className={'overflow-y-hidden'}
            style={{
                marginTop: `${marginTop}px`,
                marginBottom: `${marginBottom}px`,
                height: `${available}px`
            }}
        >
            {children}
        </main>
    );
};

export default Main;
