import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';
import {useMemo} from 'react';

import {cn} from '@shadcn/lib/utils';
import {ColorVariations} from "@app/types/ColorVariations";


const getButtonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                primary:
                    'bg-primary-600 text-neutral-200 rounded-md w-full',
                secondary:
                    'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
                negative:
                    'bg-negative-600 text-neutral-200 rounded-md w-full',
                positive:
                    'bg-positive-600 text-positive-200 rounded-md w-full',
                neutral:
                    'bg-neutral-600 text-neutral-200 rounded-md w-full',
                warning:
                    'bg-orange-600 text-orange-200 rounded-md w-full',
                outline:
                    'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline !p-0 !h-auto',

            } satisfies { [k in ColorVariations]: string },
            size: {
                default: 'h-8 px-4 py-2',
                sm: 'h-8 rounded-md px-3 text-xs',
                lg: 'h-10 rounded-md px-8',
                icon: 'h-9 w-9'
            }
        },
        defaultVariants: {
            variant: 'primary',
            size: 'default'
        }
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof getButtonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({className, variant, size, asChild = false, ...props}, ref) => {
        const Comp = asChild ? Slot : 'button';
        const buttonVariants = useMemo(() => getButtonVariants({variant, size, className}), [variant, size, className]);

        return (
            <Comp
                className={cn(buttonVariants)}
                ref={ref}
                {...props}
            />
        );
    }
);
Button.displayName = 'Button';

export {Button, getButtonVariants};
