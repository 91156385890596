import {MailOutline, Percent, PhoneOutlined} from "@mui/icons-material";
import React from "react";
import {SubscriberItem} from "@app/mock/MockSubscriberData";

interface PaymentCustomerDetailsProps {
    customer: SubscriberItem
}

export function PaymentCustomerDetails({customer}: PaymentCustomerDetailsProps) {

    function formatDate() {
        const newDate = new Date(customer.date);
        return newDate.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
    }

    return (

    // Customer Profile

        <div className={'flex flex-col gap-y-4 text-neutral-900'}>
            <div className={'flex items-center justify-between leading-5'}>
                <div className={'flex gap-x-2.5'}>
                    <div
                        className={'h-10 w-10 rounded-full border text-center flex items-center justify-center' +
                            ' bg-primary-200 font-bold text-primary-800'}>J
                    </div>
                    <div className={'leading-5'}>
                        <p className={'font-semibold'}>
                            {customer.title}
                        </p>
                        <p className={'text-sm font-medium'}>Active Subscriber
                        </p>
                    </div>
                </div>
                <div className={'flex gap-x-2'}>
                    <p><PhoneOutlined/></p>
                    <p><MailOutline/></p>
                    <p><Percent/></p>
                </div>
            </div>
            <div className={'flex justify-between gap-x-2'}>
                <div className={'p-4 border rounded-md flex-grow flex flex-col w-full text-sm items-center' +
                    ' justify-center gap-y-2 bg-white'}>
                    <div className={'flex items-center gap-x-1 font-medium'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="#0B2C60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 6V12H16.5" stroke="#0B2C60" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg>
                        <p className={'font-medium'}>Member Since</p>
                    </div>
                    <p className={'font-semibold'}>{formatDate()}</p>
                </div>
                <div className={'p-4 border rounded-md flex-grow flex flex-col w-full text-sm items-center bg-white' +
                    ' justify-center gap-y-2'}>
                    <div className={'flex items-center gap-x-1'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 21" fill="none">
                            <path
                                d="M16.914 8.414C17.5 7.828 17.5 6.886 17.5 5C17.5 3.114 17.5 2.172 16.914 1.586M16.914 8.414C16.328 9 15.386 9 13.5 9H9.5C7.614 9 6.672 9 6.086 8.414M16.914 1.586C16.328 1 15.386 1 13.5 1H9.5C7.614 1 6.672 1 6.086 1.586M6.086 1.586C5.5 2.172 5.5 3.114 5.5 5C5.5 6.886 5.5 7.828 6.086 8.414M12.5 5C12.5 5.26522 12.3946 5.51957 12.2071 5.70711C12.0196 5.89464 11.7652 6 11.5 6C11.2348 6 10.9804 5.89464 10.7929 5.70711C10.6054 5.51957 10.5 5.26522 10.5 5C10.5 4.73478 10.6054 4.48043 10.7929 4.29289C10.9804 4.10536 11.2348 4 11.5 4C11.7652 4 12.0196 4.10536 12.2071 4.29289C12.3946 4.48043 12.5 4.73478 12.5 5Z"
                                stroke="#0B2C60" strokeWidth="1.5"/>
                            <path
                                d="M17.5 4C16.7044 4 15.9413 3.68393 15.3787 3.12132C14.8161 2.55871 14.5 1.79565 14.5 1M17.5 6C16.7044 6 15.9413 6.31607 15.3787 6.87868C14.8161 7.44129 14.5 8.20435 14.5 9M5.5 4C6.29565 4 7.05871 3.68393 7.62132 3.12132C8.18393 2.55871 8.5 1.79565 8.5 1M5.5 6C6.29565 6 7.05871 6.31607 7.62132 6.87868C8.18393 7.44129 8.5 8.20435 8.5 9M4.5 18.388H6.76C7.77 18.388 8.793 18.494 9.776 18.696C11.531 19.0565 13.3368 19.0965 15.106 18.814C15.974 18.674 16.826 18.459 17.598 18.087C18.294 17.75 19.147 17.277 19.72 16.746C20.292 16.216 20.888 15.349 21.31 14.671C21.674 14.089 21.498 13.376 20.924 12.943C20.6015 12.7084 20.2129 12.582 19.814 12.582C19.4151 12.582 19.0265 12.7084 18.704 12.943L16.897 14.308C16.197 14.838 15.432 15.325 14.521 15.47C14.411 15.487 14.296 15.503 14.176 15.517M14.176 15.517C14.1394 15.5212 14.1027 15.5252 14.066 15.529M14.176 15.517C14.3361 15.4737 14.4828 15.3912 14.603 15.277C14.7537 15.1466 14.8767 14.9874 14.9649 14.8087C15.053 14.63 15.1045 14.4355 15.1163 14.2366C15.128 14.0377 15.0998 13.8384 15.0333 13.6506C14.9668 13.4628 14.8633 13.2902 14.729 13.143C14.5987 12.9983 14.4469 12.8746 14.279 12.776C11.482 11.107 7.129 12.378 4.5 14.243M14.176 15.517C14.1399 15.5249 14.103 15.5289 14.066 15.529M14.066 15.529C13.4629 15.5896 12.8553 15.5909 12.252 15.533"
                                stroke="#0B2C60" strokeWidth="1.5" strokeLinecap="round"/>
                            <path
                                d="M4.5 13.5C4.5 12.6716 3.82843 12 3 12C2.17157 12 1.5 12.6716 1.5 13.5V18.5C1.5 19.3284 2.17157 20 3 20C3.82843 20 4.5 19.3284 4.5 18.5V13.5Z"
                                stroke="#0B2C60" strokeWidth="1.5"/>
                        </svg>
                        <p className={'font-medium'}>Total Spent</p>
                    </div>
                    <p className={'font-semibold'}>€{customer.amount}</p>
                </div>
            </div>
        </div>
    )
}