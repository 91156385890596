import React from 'react';
import SearchBar from "@components/search/SearchBar.component";
import FilterButton from "@components/button/FilterButton";

const PaymentListFilter = () => {
    return (
        <SearchBar
            className={'pt-shell'}
            inputProps={{placeholder: 'Find payment'}}
            button={<FilterButton/>}
        />
    );
};

export default PaymentListFilter;
