import React from 'react';
import {useLocation, useOutlet} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {IS_PRODUCTION} from "@config/variables";
import {useAuth0} from "@auth0/auth0-react";
import {HistoryProvider} from "@modules/routing/useHistory";
import NavigationProvider from "@components/navigation/Navigation.provider";
import Header from "@app/templates/app/Header.template";
import Main from "@app/templates/app/Main.template";
import Footer from "@app/templates/app/Footer.template";
import DebugSheet from "@components/debug/DebugSheet.component";


function App() {

    const location = useLocation()
    const outlet = useOutlet();

    const {isLoading} = useAuth0();

    return (
        <>
            <HistoryProvider>
                <NavigationProvider>
                    <Header/>
                    <Main>
                        <AnimatePresence initial={false} mode={'popLayout'}>
                            {!isLoading && outlet && React.cloneElement(outlet, {key: location.pathname})}
                        </AnimatePresence>
                    </Main>
                    <Footer/>
                    {!IS_PRODUCTION && <DebugSheet/>}

                </NavigationProvider>
            </HistoryProvider>
        </>
    );
}

export default App;
