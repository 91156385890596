import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import React, {useState} from "react";
import {$createTextNode, $getSelection, $isRangeSelection} from "lexical";
import {$createLinkNode, TOGGLE_LINK_COMMAND} from "@lexical/link";
import {HiOutlineLink} from "react-icons/hi";
import {Popover, PopoverContent, PopoverTrigger} from "@src/@/components/ui/popover";
import {Input} from "@src/@/components/ui/input";
import {Button} from "@src/@/components/ui/button";


export function LinkGroup() {
    const [editor] = useLexicalComposerContext()
    const [linkUrl, setLinkUrl] = useState<string>('');
    const [displayText, setDisplayText] = useState<string>('');
    const [isLinkDialogOpen, setIsLinkDialogOpen] = useState<boolean>(false);


    function insertLink() {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) && linkUrl) {
                if (displayText) {
                    const textNode = $createTextNode(displayText);
                    const linkNode = $createLinkNode(linkUrl);
                    linkNode.append(textNode);
                    selection.insertNodes([linkNode]);
                } else {
                    const selectedText = selection.getTextContent();
                    if (selectedText.length > 0) {
                        // Wrap the selected text with a LinkNode
                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, {url: linkUrl});
                    } else {
                        const textNode = $createTextNode(linkUrl);
                        const linkNode = $createLinkNode(linkUrl);
                        linkNode.append(textNode);
                        selection.insertNodes([linkNode]);
                    }
                }
            }
        });
        setIsLinkDialogOpen(false);
        setLinkUrl('');
        setDisplayText('');
    }

    function handleLinkUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setLinkUrl(event.target.value);
    }

    function handleDisplayTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDisplayText(event.target.value);
    }

    return (
        <div className={'pl-4'}>
            <Popover open={isLinkDialogOpen} onOpenChange={setIsLinkDialogOpen}>
                <PopoverTrigger className={'flex p-1 items-center border rounded'}><HiOutlineLink/></PopoverTrigger>
                <PopoverContent className={'bg-white '}>
                    <Input
                        type={'text'}
                        value={linkUrl}
                        onChange={handleLinkUrlChange}
                        placeholder={'Enter URL'}
                        className={'border p-2 rounded mb-2 w-full'}
                    />
                    <Input
                        type={'text'}
                        value={displayText}
                        onChange={handleDisplayTextChange}
                        placeholder={'Enter Display Text (optional)'}
                        className={'border p-2 rounded mb-2 w-full'}
                    />
                    <Button
                        onClick={insertLink}
                        className={'rounded text-white'}
                    >
                        Insert Link
                    </Button>
                </PopoverContent>
            </Popover>
        </div>

    )
}