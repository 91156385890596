import {useCallback, useMemo} from "react";

import {useIdTokenCtx} from "@modules/auth/useIdToken";
import {GraphQLClient} from "graphql-request";
import {API_URL} from "@config/variables";
import {getSdk} from "@src/gql-schema";
import {RequestConfig} from "graphql-request/build/cjs/types";

const useGql = () => {

    const idToken = useIdTokenCtx();

    const gqlEndpoint = useMemo(
        () => `${API_URL}/query`,
        [API_URL]
    );

    const requestConfig: RequestConfig = useMemo(() => ({
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    }), [idToken]);

    const graphQLClient: GraphQLClient = useMemo(
        () => new GraphQLClient(gqlEndpoint, requestConfig),
        [requestConfig, gqlEndpoint]
    );

    const getGql = useCallback(
        () => getSdk(graphQLClient),
        [graphQLClient]
    );

    return useMemo((): ReturnType<typeof getSdk> => getGql(), [getGql]);
};

export default useGql;
