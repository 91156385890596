import {Switch} from "@src/@/components/ui/switch";
import React, {FC} from "react";

type Props = {
    onChange: (checked: boolean) => void,
    value: boolean | undefined,
    className?: string
}

export const BaseToggle: FC<Props> = ({onChange, value, className}) => {
    return <Switch
        className={className}
        checked={value === true}
        onCheckedChange={onChange}
    />
}