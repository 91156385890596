import React, {FC} from 'react';
import {AddCircleOutline} from "@mui/icons-material";
import {Button, ButtonProps} from "@src/@/components/ui/button";


export const CreatButtonInner = () => <><AddCircleOutline className={'!text-[1rem] mr-1'}/> New</>

type Props = Omit<ButtonProps, 'children' | 'variant'>
const CreateButton: FC<Props> = (props) => {
    return (
        <Button variant={'primary'} {...props}>
            <CreatButtonInner/>
        </Button>
    );
};

export default CreateButton;
