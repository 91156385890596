import React, {FC} from 'react';
import BillingItemComponent, {BillingItem} from "@pages/merchant/settings/billing/components/BillingItemComponent";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";

type Props = {
    items: BillingItem[]
}

const BillingList: FC<Props> = ({items}) => {

    const {available} = useWorkspaceHeight();
    return (
        <div
            className={'grid divide-y gap-y-3 overflow-y-auto pb-3 bg-white'}
            style={{
                height: `${available}px`
            }}
        >
            {items.map((item, i) => <BillingItemComponent
                key={i}
                {...item}
            />)}
        </div>
    );
};

export default BillingList;
