import React, {Dispatch, SetStateAction} from 'react'
import {EditCompanyUserPullover} from "@pages/merchant/settings/company/components/EditCompanyUserPullover";
import {RemoveUserDialog} from "@pages/merchant/settings/company/components/RemoveUserDialog";
import {CloseRounded} from "@mui/icons-material";
import {CompanyUser} from "@app/mock/MockCompanyData";

interface CompanyUserCardProps {
    user: CompanyUser | undefined
    setUsers: Dispatch<SetStateAction<CompanyUser[] | undefined>>
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
    removeUser: (userId: string | undefined) => void
}

export function CompanyUserCard({user, changeUserRole, removeUser}: CompanyUserCardProps) {
    return (
        <div key={user?.id} className={'flex justify-between items-center p-4 border-b gap-x-2 bg-white'}>
            <div className={'flex flex-col gap-y-1 flex-grow'}>
                <div className={'text-xl font-roboto font-medium text-primary'}>
                    {user?.name}
                </div>
                <div className={'text-sm font-medium'}>
                    {user?.role.join(', ')}
                </div>
            </div>
            <div className={'flex items-center gap-x-3 text-primary'}>
                <EditCompanyUserPullover
                    user={user}
                    changeUserRole={changeUserRole}
                />
                <RemoveUserDialog user={user} removeUser={removeUser}
                    trigger={<CloseRounded className={'hover:cursor-pointer'}/>}/>
            </div>
        </div>
    )
}