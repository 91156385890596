import {RadioGroup, RadioGroupItem} from "@src/@/components/ui/radio-group";
import {Label} from "@src/@/components/ui/label";
import React, {Dispatch, FC, SetStateAction, useMemo} from "react";
import {classNames} from "@modules/casting/String";
import H2 from "@components/text/H2.component";


export type FilterItem<T = string> = {
    title: string,
    value: T
}

type Props = {
    filters: FilterItem[]
    filterState: [FilterItem | undefined, Dispatch<SetStateAction<FilterItem | undefined>>]
}

const BillingFilter: FC<Props> = ({filters, filterState}) => {

    const [currentFilter, setFilter] = useMemo(() => filterState, [filterState])

    const isCurrentFilter = (filter: FilterItem) => {
        return currentFilter?.value == filter.value
    }

    const handleFilterItemClick = (filter: FilterItem) => {
        setFilter(currentFilter?.value == filter.value ? undefined : filter)
    }

    return (
        <div className={classNames('pb-1.5', filters.length == 0 && 'hidden')}>
            <H2>Filter By</H2>
            <RadioGroup
                value={currentFilter?.value}
                className={'flex items-center py-2'}
            >
                {filters.map(filter => <div key={filter.value}>
                    <RadioGroupItem
                        id={filter.value}
                        value={filter.value}
                        onClick={() => handleFilterItemClick(filter)}
                        className={'hidden'}
                    />
                    <Label htmlFor={filter.value} className={classNames(
                        'py-2 px-4 rounded-md border',
                        isCurrentFilter(filter)
                            ? 'bg-primary-600 border-primary-600 text-neutral-100'
                            : 'bg-white border-neutral-600 text-neutral'
                    )}>{filter.title}</Label>
                </div>)}
            </RadioGroup>
        </div>
    )
}
export default BillingFilter;