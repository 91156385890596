import React, {FC, ReactNode, useMemo} from "react";
import Card from "@components/card/Card.component";
import {classNames} from "@modules/casting/String";


export type DescriptionCardType = {
    title: string,
    description?: string,
    context?: ReactNode
    badge?: ReactNode
    disabled?: boolean,
    className?: string
    onClick?: () => void
}

type Props = DescriptionCardType


const DescriptionCard: FC<Props> = ({
    title,
    description,
    badge,
    context,
    disabled = false,
    className,
    onClick
}) => {

    const withInfo = useMemo(() => {
        return context !== undefined || badge != undefined
    }, [context, badge]);

    return (
        <Card onClick={onClick} className={classNames(disabled && 'opacity-60', className)}>
            <div className="grid grid-cols-12 gap-x-2">
                <dl className={classNames('grid grid-cols-1 gap-y-1', withInfo ? 'col-span-10' : 'col-span-12')}>
                    <dt className={'text-lg text-primary font-medium font-roboto leading-6'}>{title}</dt>
                    <dd className={'text-[0.95rem] text-neutral line-clamp-2 leading-6'}>{description}</dd>
                </dl>
                {withInfo && <div className={'col-span-2'}>
                    <div className={'w-full relative min-h-[1.75rem]'}>
                        <div className={'absolute w-fit right-0'}>
                            {context}
                        </div>
                    </div>
                    {badge && <div className={'w-full relative'}>
                        <div className={'absolute w-fit right-0'}>
                            {badge}
                        </div>
                    </div>}
                </div>}
            </div>
        </Card>
    )
}
export default DescriptionCard