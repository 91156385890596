import React, {Dispatch, SetStateAction} from 'react'
import {CompanyUser} from "@app/mock/MockCompanyData";
import {CompanyUserCard} from "@pages/merchant/settings/company/components/CompanyUserCard";

interface CompanyUserCardListProps {
    users: CompanyUser[] | undefined
    setUsers: Dispatch<SetStateAction<CompanyUser[] | undefined>>
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
    removeUser: (userId: string | undefined) => void
}

export function CompanyUserCardList({users, setUsers, changeUserRole, removeUser}: CompanyUserCardListProps) {
    return (
        <>
            {
                users?.map(user => {
                    return (
                        <CompanyUserCard
                            key={user.id}
                            user={user}
                            setUsers={setUsers}
                            changeUserRole={changeUserRole}
                            removeUser={removeUser}
                        />
                    )
                })
            }
        </>
    )
}