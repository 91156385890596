import React, {ReactNode} from 'react'
import {CompanyUser} from "@app/mock/MockCompanyData";
import {Dialog, DialogClose, DialogContent, DialogFooter, DialogTrigger} from "@src/@/components/ui/dialog";
import {Button} from "@src/@/components/ui/button";

interface RemoveUserPulloverProps {
    user: CompanyUser | undefined
    removeUser: (userId: string | undefined) => void
    trigger: ReactNode
    isIcon?: boolean
}

export function RemoveUserDialog({user, removeUser, trigger, isIcon = true}: RemoveUserPulloverProps) {

    function handleSubmit() {
        removeUser(user?.id)
    }

    return (
        <Dialog>
            <DialogTrigger asChild={!isIcon}>
                {trigger}
            </DialogTrigger>
            <DialogContent className={'bg-white w-full'}>
                <p className={'text-base font-medium text-neutral-900'}>Are you sure you want to remove <span
                    className={'font-bold text-primary'}>{user?.name}</span> from your Company User list?
                </p>
                <DialogFooter className={'flex flex-col gap-y-2'}>
                    <DialogClose asChild>
                        <Button onClick={handleSubmit}>Proceed</Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button variant={'outline'}>Cancel</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

