import {InputOTP, InputOTPGroup, InputOTPSlot} from "@shadcn/components/ui/input-otp";
import React, {FC, useEffect, useRef} from "react";
import {classNames} from "@modules/casting/String";


type Props = {
    value?: string,
    onChange: (value: string) => void,
    disabled?: boolean,
    className?: string
}
const OtpInputComponent: FC<Props> = ({onChange, value, disabled, className}) => {

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!disabled) {
            inputRef.current?.focus()
        }
    }, [disabled]);

    return (
        <div className={'w-full'}>
            <InputOTP
                ref={inputRef}
                maxLength={6}
                minLength={6}
                containerClassName={classNames('mx-auto w-fit', className)}
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus={true}
                onBlur={() => inputRef.current?.focus()}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                <InputOTPGroup>
                    <InputOTPSlot index={0}/>
                </InputOTPGroup>
                <InputOTPGroup>
                    <InputOTPSlot index={1}/>
                </InputOTPGroup>
                <InputOTPGroup>
                    <InputOTPSlot index={2}/>
                </InputOTPGroup>
                <InputOTPGroup>
                    <InputOTPSlot index={3}/>
                </InputOTPGroup>
                <InputOTPGroup>
                    <InputOTPSlot index={4}/>
                </InputOTPGroup>
                <InputOTPGroup>
                    <InputOTPSlot index={5}/>
                </InputOTPGroup>
            </InputOTP>
        </div>

    )
}
export default OtpInputComponent