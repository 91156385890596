import React, {useState} from "react";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@src/@/components/ui/tabs";
import {PaymentSubscriptionTab} from "@pages/merchant/subscriber/show/components/PaymentSubscriptionTab";
import {PaymentCustomerContactTab} from "@pages/merchant/subscriber/show/components/PaymentCustomerContactTab";
import {SubscriberItem} from "@app/mock/MockSubscriberData";


interface PaymentTabProps {
    customer: SubscriberItem
}

export function PaymentTab({customer}: PaymentTabProps) {
    const [activeTab, setActiveTab] = useState('Subscription')

    return (
        <Tabs defaultValue="Subscription" onValueChange={(value) => setActiveTab(value)} className={'h-full' +
            ' flex overflow-y-auto flex-col -mx-shell -my-shell mt-4'}>
            <div className={'py-4 border-y'}>
                <TabsList className={'flex rounded-none px-4 gap-x-2'}>
                    <TabsTrigger value="Subscription"
                        className={`flex-grow w-full font-medium ${activeTab === 'Subscription' ? 'bg-primary-800 text-neutral-100' : ''}`}>Subscription</TabsTrigger>
                    <TabsTrigger value="Contact"
                        className={`flex-grow w-full font-medium ${activeTab === 'Contact' ? 'bg-primary-800 text-neutral-100' : ''}`}>Contact</TabsTrigger>
                </TabsList>
            </div>
            <TabsContent value="Subscription" className={'h-full m-0 space-y-4 overflow-hidden'}>
                <PaymentSubscriptionTab customer={customer}/>
            </TabsContent>
            <TabsContent value="Contact" className={'m-0'}>
                <PaymentCustomerContactTab/>
            </TabsContent>
        </Tabs>
    )
}