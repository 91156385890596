import React, {useRef} from 'react';
import useGql from "@modules/graphql/useGql";
import {FormFields} from "@components/form/fields/FormField.component";
import {MerchantRegistrationForm, MerchantRegistrationOutput,} from "@src/gql-schema";
import {z} from "zod";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import Form from "@components/form/Form.component";
import PrimaryButton from "@components/button/PrimaryButton";
import CompanyIcon from "@components/icon/CompanyIcon";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {useNavigate} from "react-router-dom";

const CompanyRegisterPage = () => {

    const gql = useGql();
    const formRef = useRef<HTMLFormElement>(null)
    const navigate = useNavigate()

    const currentCompany = useAsyncMemo(async (): Promise<MerchantRegistrationOutput | undefined> => {
        return await gql.GetRegisterMerchantForm()
            .then(r => {
                if (r.getRegisterMerchantForm.name != undefined) {
                    navigate('/plans')
                    return undefined
                }
                return r.getRegisterMerchantForm
            })
    }, [])

    const fields: FormFields<MerchantRegistrationForm> = {
        name: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Company name',
            defaultValue: currentCompany?.name
        },
        coc: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'COC Number',
            defaultValue: currentCompany?.coc
        },
        vat: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'VAT ID',
            defaultValue: currentCompany?.vat
        },
        address: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Billing address',
            defaultValue: currentCompany?.address
        },
        extra: {
            zodType: z.string().min(0).max(64),
            fieldType: 'input',
            label: 'Extra',
            defaultValue: currentCompany?.extra
        },
        postalCode: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Zipcode',
            colSpan: 'col-span-6',
            defaultValue: currentCompany?.postalCode
        },
        city: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'City',
            colSpan: 'col-span-6',
            defaultValue: currentCompany?.city
        }

    }

    const onSubmitSuccess = () => {
        navigate('/plans')
    }

    return (
        <ShowPageTemplate>
            <div className={'rounded-full p-2 border border-neutral-500 mx-auto w-fit mt-8'}>
                <CompanyIcon/>
            </div>
            <Form
                ref={formRef}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                submit={gql.RegisterMerchant}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                onSubmitSuccess={onSubmitSuccess}
                fields={fields}
                payloadInjection={{}}
            />

            <PrimaryButton className={'mt-4'} onClick={() => formRef.current?.requestSubmit()}>Submit</PrimaryButton>
        </ShowPageTemplate>
    )
};
export default CompanyRegisterPage;
