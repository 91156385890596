import React, {FC, ReactNode} from "react";
import {Badge} from "@src/@/components/ui/badge";
import Card from "@components/card/Card.component";
import {classNames} from "@modules/casting/String";
import {Check} from "@mui/icons-material";


export type ProgressState = 'pending' | 'current' | 'done'

export type ProgressCardType = {
    title: ReactNode,
    description: ReactNode,
    onClick: () => void,
    state: ProgressState,
    index?: number,
    currentMessage?: string,
    doneMessage?: string
}

type Props = ProgressCardType


const ProgressCard: FC<Props> = ({
    title,
    description,
    onClick,
    state,
    index = 0,
    currentMessage = 'Start',
    doneMessage = 'Done'
}) => {

    return (<div className={'relative ml-0.5'}>
        <Card
            onClick={state == 'current' ? onClick : undefined}
            className={classNames(
                state == 'pending' && 'bg-neutral-400/30 border-[3px] border-neutral-400/5 text-neutral/40 hover:cursor-not-allowed',
                state == 'current' && 'border-primary-600 border-[3px] bg-primary-200/10 text-primary-700',
                state == 'done' && 'border-positive-500 border-[3px] bg-positive-200/20 text-positive-800'
            )}>
            <div className="grid grid-cols-12 gap-x-2">
                <dl className={'col-span-8 grid grid-cols-1 gap-y-1'}>
                    <dt className={'text-lg font-medium font-roboto'}>{title}</dt>
                    <dd className={'text-[0.95rem]'}>{description}</dd>
                </dl>
                <div className={'col-span-4'}>
                    <Badge
                        label={state === 'done' ? doneMessage : state == 'current' ? currentMessage : ''}
                        className={classNames('px-2 text-sm py-1 border-none float-right',
                            state == 'pending' && 'text-neutral-900',
                            state == 'current' && 'text-primary-700 font-semibold',
                            state == 'done' && 'font-medium'
                        )}
                        variant={'outline'}
                    />
                </div>
            </div>
        </Card>

        <div className={classNames(
            'rounded-full h-7 w-7 border-[3px] absolute left-0 top-1/2 -translate-x-[40%] -translate-y-1/2 flex items-center justify-center text-[0.8rem] font-[700]',
            state == 'pending' && 'bg-white border-neutral-500/40 text-neutral/50',
            state == 'current' && 'border-primary-700 bg-white text-primary-700',
            state == 'done' && 'border-positive-500 bg-positive-500'
        )}>
            {state !== 'done' ? index + 1 : <Check className={'text-white'} fontSize={'small'}/>}
        </div>
    </div>)
}
export default ProgressCard