import React from 'react';
import {ControllerRenderProps} from "react-hook-form";
import {FormControl} from "@src/@/components/ui/form";
import {Input as ShadCnInput} from "@src/@/components/ui/input";
import {ExtendFormFieldProps} from "@components/form/fields/FormField.component";

type Props<T> = ExtendFormFieldProps<T>

const Input = <T = string | number>({
    placeholder,
    type,
    field
}: Props<T>) => {

    return (
        <FormControl>
            <ShadCnInput
                placeholder={placeholder}
                type={type}
                {...(field as unknown as ControllerRenderProps)}
            />
        </FormControl>
    );
};

export default Input;
