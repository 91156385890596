import React, {FC} from 'react';
import {classNames} from "@modules/casting/String";


type Props = {
    isPositive?: boolean
}
const BalanceDifferenceIcon: FC<Props> = ({isPositive = true}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className={classNames(!isPositive && 'rotate-180 scale-x-[-1]')}
        >
            <path
                d="M18.3333 5.83331L11.25 12.9166L7.08329 8.74998L1.66663 14.1666"
                stroke="#196619"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3334 5.83331H18.3334V10.8333"
                stroke="#196619"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default BalanceDifferenceIcon;
