import React, {FC, useEffect, useMemo} from 'react';
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import BasePageTemplate, {ExtendPageProps} from "@templates/page/BasePage.template";
import {useCurrentRoute} from "@modules/routing/useRouter";


type Props = ExtendPageProps
const GuestPageTemplate: FC<Props> = ({children, pageTitle, ...props}) => {

    const currentRoute = useCurrentRoute();
    const navBottom = useNavbarBottom();
    const setHideNavbarBottom = navBottom.hide[1];

    useEffect(() => {
        setHideNavbarBottom(true)
    }, []);

    const currentPageTitle = useMemo(() => {
        if (pageTitle != undefined && pageTitle.replaceAll(' ', '') != '') {
            return pageTitle
        }
        return currentRoute.item.title
    }, [currentRoute.item.title, pageTitle]);

    return (
        <>
            <BasePageTemplate {...props} pageTitle={currentPageTitle} hideNavbarTop={true}>
                {children}
            </BasePageTemplate>
        </>
    );
};

export default GuestPageTemplate;
