import React, {FC, useEffect, useMemo, useState} from 'react';
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from "@shadcn/components/ui/sheet";
import {Link} from "react-router-dom";
import {BugReport} from "@mui/icons-material";
import {API_URL, STORY_BOOK_URL} from "@config/variables";
import Router from "@src/Router";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";


type DebugGroupProps = {
    title: string,
    links: ({
        to: string,
        title: string,
        openInNewTab?: boolean
    })[]
}
const DebugGroup: FC<DebugGroupProps> = ({title, links}) => {
    return <>
        <div className="font-semibold leading-6 text-neutral mt-4">{title}</div>
        <ul className="flex flex-1 flex-col gap-y-7">
            <li>
                <ul className="-mx-2 space-y-1">
                    {links.map(link => <li key={link.to}>
                        <Link
                            {...link.openInNewTab ? {target: '_blank', rel: 'noopener noreferrer'} : null}
                            to={link.to}
                            className="text-neutral group flex gap-x-3 rounded-md px-2 leading-6 underline font-medium"
                        >
                            {link.title}
                        </Link>
                    </li>)}
                </ul>
            </li>
        </ul>
    </>
}


const DebugSheet = () => {

    const navBottom = useNavbarBottom()
    const navbarHidden = navBottom.hide[0]

    const [flipOrientation, setFlipOrientation] = useState(false)
    useEffect(() => {
        document.documentElement.style.transform = flipOrientation ? `rotate(180deg)` : ''
    }, [flipOrientation]);

    interface RouteListItem {
        title: string,
        path: string,
        children: Omit<RouteListItem, 'children'>[]
    }

    const routeList = useMemo(() => Router.map(route => {
        const item: RouteListItem = {
            title: route.title ?? '',
            path: route.path,
            children: []
        }

        route.children.forEach(child => {
            if (child.index === true) {
                item.title = child.title
                item.path = `${item.path}${child.path ? `/${child.path}` : ''}`
                return;
            }

            item.children.push({
                title: child.title,
                path: `${route.path}/${child.path}`
            })
        })
        return item
    }), [Router])


    return (
        <Sheet>
            <SheetTrigger
                style={{
                    bottom: navbarHidden ? '1rem' : '4.5rem'
                }}
                className={'fixed right-4 rounded-full bg-primary/40 hover:bg-primary-hover text-primary-100 hover:text-primary-accent h-8 w-8 z-[100]'}>
                <BugReport className={'-mt-1 !h-4'}/>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader className={'text-left'}>
                    <SheetTitle>Debug menu</SheetTitle>
                </SheetHeader>
                <nav
                    className="text-left overflow-y-auto overflow-x-hidden relative h-[calc(100vh-3.25rem)] -mr-6 pb-6">
                    <DebugGroup title={'Quick routes'} links={[
                        {
                            title: 'App home',
                            to: '/',
                        },
                        {
                            title: 'Debug home',
                            to: '/debug'
                        },
                    ]}/>
                    <DebugGroup title={'Links'} links={[
                        {
                            title: 'Storybooks',
                            to: STORY_BOOK_URL,
                            openInNewTab: true
                        },
                        {
                            title: 'GraphQL playground',
                            to: `${API_URL}/graphql`,
                            openInNewTab: true
                        },
                    ]}/>
                    <div className="font-semibold leading-6 text-neutral mt-4">Tools</div>
                    <button
                        className="text-neutral group flex gap-x-3 rounded-md leading-6 underline font-medium"
                        onClick={() => setFlipOrientation(!flipOrientation)}
                    >Flip orientation
                    </button>

                    <>
                        <div className="font-semibold leading-6 text-neutral mt-4">Sitemap</div>
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1 list-disc list-inside">
                                    {routeList.map(route => <li key={route.path} className={''}>
                                        <Link
                                            to={route.path}
                                            className="text-neutral leading-6 underline font-medium"
                                        >
                                            {route.title}
                                        </Link>
                                        {route.children.length > 0 && <ul className={'list-disc list-inside pl-5'}>
                                            {route.children.map(child => <li key={child.path}>
                                                <Link
                                                    to={child.path}
                                                    className="text-neutral leading-6 underline"
                                                >
                                                    {child.title}
                                                </Link>
                                            </li>)}
                                        </ul>}
                                    </li>)}
                                </ul>
                            </li>
                        </ul>
                    </>
                </nav>
            </SheetContent>
        </Sheet>
    )
};

export default DebugSheet;
