import React, {ReactNode, useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {EditSubscriptionPullover} from "@pages/merchant/subscriber/show/components/EditSubscriptionPullover";
import {CancelSubscriptionPullover} from "@pages/merchant/subscriber/show/components/CancelSubscriptionPullover";

interface ManageSubscriptionPulloverProps {
    children: ReactNode
}

export default function ManageSubscriptionPullover({children}: ManageSubscriptionPulloverProps) {
    const [openMangeSubscription, setManageSubscription] = useState<boolean>(false)


    return (
        <PullOver
            title={'Manage Subscription'}
            submit={
                {
                    title: 'N/A',
                    hidden: true
                }
            }
            trigger={
                {
                    title: children,
                    onClick: () => setManageSubscription(true),
                    variation: 'link'
                }
            }
            openState={[openMangeSubscription, setManageSubscription]}
        >
            <div className={'flex flex-col gap-y-4'}>
                <EditSubscriptionPullover/>
                <CancelSubscriptionPullover/>
            </div>
        </PullOver>
    )
}