import * as React from 'react';
import {InputHTMLAttributes} from 'react';

import {cn} from '@shadcn/lib/utils';
import {InputStyle} from "@templates/components/InputStyle";

const Input = React.forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
    ({className, type, ...props}, ref) => {
        return (
            <input
                type={type}
                className={cn(InputStyle, className)}
                ref={ref}
                {...props}
            />
        );
    }
);
Input.displayName = 'Input';

export {Input};
