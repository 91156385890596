import ListComponent, {ListItem, ListItems} from "@components/list/List.component";
import React, {useMemo} from "react";
import {groupObjectsByDate} from "@modules/casting/Object";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import {Badge} from "@src/@/components/ui/badge";
import {SubscriberItem} from "@app/mock/MockSubscriberData";

interface SubscriberListComponentProps {
    data: SubscriberItem[]
    height?: string
    forIndex?: boolean
}

export function SubscriberListComponent({data, height, forIndex = false}: SubscriberListComponentProps) {

    const {available} = useWorkspaceHeight()

    const subscriberList = useMemo(() => {
        const groupedItems = groupObjectsByDate(data, 'date', {
            groupType: 'day',
            sort: 'DESC'
        });

        return Object.keys(groupedItems).reduce((acc, groupKey) => ({
            ...acc,
            [groupKey]: groupedItems[groupKey].map(item => ({
                id: item.id,
                dateTime: item.date,
                title: item.title,
                description: item.subscriptionType,
                target: `/subscribers/list/${item.id}`,
                symbol: item.title[0].concat(item.title.split(' ')[1][0]),
                feedback: <div className={'flex justify-end items-center w-full h-7'}>
                    <Badge label={item.isActive ? 'Active' : 'Inactive'}
                        className={`w-fit ${item.isActive ? 'bg-positive-600' : 'bg-neutral-400 text-neutral-800'}`}/>
                </div>
            }) satisfies ListItem)
        }), {} satisfies ListItems)
    }, [])

    return (
        <ListComponent height={height ?? `${available}px`} items={subscriberList} adjustWidth={forIndex}/>
    )
}