import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "@src/@/components/ui/form";
import React from "react";
import {FieldErrors} from "react-hook-form";
import {FormSchema} from "@pages/merchant/onboarding/pages/CreateSubscriptionFormPullOverScreen";

export function VATRateInput({errors}: { errors: FieldErrors<FormSchema> }) {
    return (
        <FormField name={'VATRate'} render={({field}) => (
            <FormItem className={'space-y-1'}>
                <FormLabel>VAT Rate</FormLabel>
                <FormControl>
                    <select {...field} className={'w-full px-2 py-1 rounded-md border'}>
                        <option value={''}>Select VAT Rate</option>
                        <option value={'18'}>18%</option>
                        <option value={'19'}>19%</option>
                        <option value={'20'}>20%</option>
                        <option value={'21'}>21%</option>
                        <option value={'22'}>22%</option>
                    </select>
                </FormControl>
                <FormMessage>{errors.VATRate?.message}</FormMessage>
            </FormItem>
        )}/>
    )
}