import React, {useState} from 'react'
import {SortBySection} from "@pages/merchant/subscriber/list/components/SortBySection";
import {FilterSubscriptionSection} from "@pages/merchant/subscriber/list/components/FilterSubscriptionSection";
import {FilterCustomerStatusSection} from "@pages/merchant/subscriber/list/components/FilterCustomerStatusSection";
import {FilterByTierSection} from "@pages/merchant/subscriber/list/components/FilterByTierSection";

interface SortBy {
    category: string;
    order: string;
}

export interface CheckboxSection {
    title: string;
    isSelected: boolean;
    name?: string
}

export interface State {
    sortBy: SortBy;
    subscription: CheckboxSection[];
    customerStatus: CheckboxSection[];
    tiers: CheckboxSection[]
}

export const initialSortBy = {
    category: 'Name',
    order: 'Descending'
}

export const initialSubscriptionState = [
    {
        title: 'All',
        isSelected: false,
        name: 'AllSub'
    },
    {
        title: 'Great Service',
        isSelected: true,
    },
    {
        title: 'Subscription Y',
        isSelected: false,
    },
    {
        title: 'Subscription Z',
        isSelected: false,
    },

]

export const initialCustomerStatusState = [
    {
        title: 'All',
        isSelected: true,
        name: 'AllCStatus'
    },
    {
        title: 'Active',
        isSelected: false
    },
    {
        title: 'Inactive',
        isSelected: false
    },
]

export const initialTierState = [
    {
        title: 'All',
        isSelected: true,
        name: 'AllTier'
    },
    {
        title: 'Basic',
        isSelected: false
    },
    {
        title: 'Premium',
        isSelected: false
    },
    {
        title: 'Pro',
        isSelected: false
    },
]

const initialState: State = {
    sortBy: initialSortBy,
    subscription: initialSubscriptionState,
    customerStatus: initialCustomerStatusState,
    tiers: initialTierState
}

export function SubscriberFilterPullover() {
    const [filter, setFilter] = useState(initialState)

    function handleChange(value: string | boolean, category: string, name = '') {
        setFilter((prev) => {
            if (category === 'subscription' || category === 'customerStatus' || category === 'tiers') {
                // If 'All' is selected, set 'All' to true and others to false.
                if (name === 'All') {
                    return {
                        ...prev,
                        [category]: prev[category].map((item) => ({
                            ...item,
                            isSelected: item.title === 'All'
                        }))
                    };
                } else {
                    // If another item is selected, set 'All' to false and that item to true.
                    return {
                        ...prev,
                        [category]: prev[category].map((item) => ({
                            ...item,
                            isSelected: item.title === 'All' ? false : (item.title === name ? value : item.isSelected)
                        }))
                    };
                }
            } else if (category in prev.sortBy) {
                return {
                    ...prev,
                    sortBy: {
                        ...prev.sortBy,
                        [category]: value
                    }
                };
            } else {
                return {
                    ...prev,
                    [category]: value
                };
            }
        });
    }


    function getSelectedFilterCount(data: CheckboxSection[]): string {
        const allSelected = data.some(item => item.title === 'All' && item.isSelected);
        if (allSelected) {
            return 'All'
        }

        const selectedCount = data.reduce((count, item) => {
            return item.title !== 'All' && item.isSelected ? count + 1 : count
        }, 0);

        return selectedCount.toString();
    }

    function handleReset(category: string) {
        switch (category) {
            case 'sortBy' :
                return setFilter((prev) => {
                    return {
                        ...prev,
                        sortBy: initialSortBy
                    }
                })
            case 'subscription' :
                return setFilter((prev) => {
                    return {
                        ...prev,
                        subscription: initialSubscriptionState
                    }
                })
            case 'customerStatus' :
                return setFilter((prev) => {
                    return {
                        ...prev,
                        customerStatus: initialCustomerStatusState
                    }
                })
            case 'tiers' :
                return setFilter((prev) => {
                    return {
                        ...prev,
                        tiers: initialTierState
                    }
                })
            default :
                return null
        }
    }

    return (
        <div className={'divide-y flex flex-col gap-y-6'}>
            <SortBySection handleChange={handleChange} handleReset={handleReset} filter={filter}/>
            <FilterSubscriptionSection handleChange={handleChange} handleReset={handleReset} filter={filter}
                getSelectedFilterCount={getSelectedFilterCount}/>
            <FilterCustomerStatusSection handleChange={handleChange} handleReset={handleReset} filter={filter}
                getSelectedFilterCount={getSelectedFilterCount}/>
            <FilterByTierSection handleChange={handleChange} handleReset={handleReset} filter={filter}
                getSelectedFilterCount={getSelectedFilterCount}/>
        </div>
    )
}