import React, {createContext, FC, ReactNode, useContext, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";


const HistoryContext = createContext(['']);


export const HistoryProvider: FC<{ children: ReactNode }> = ({children}) => {

    const location = useLocation()
    const [historyState, setHistoryState] = useState<string[]>([])
    const history: string[] = useMemo(() => {
        const newHistory = [...historyState, location.pathname]
        setHistoryState(newHistory)
        return newHistory;
    }, [location]);
    return (
        <HistoryContext.Provider value={history}>
            {children}
        </HistoryContext.Provider>
    );
}

export const useHistory = () => useContext(HistoryContext);