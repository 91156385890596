import React, {useRef, useState} from 'react'
import {z} from "zod";
import CheckOutFormComponent from "@pages/merchant/checkout/components/CheckOutFormComponent";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import {useNavigate} from "react-router-dom";
import CheckoutAgreement from "@pages/merchant/checkout/index/components/CheckoutAgreement.component";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {MinimumTosFragment, PaymentMethod, ShoppingCartOutput, TierOutput} from "@src/gql-schema";
import useGql from "@modules/graphql/useGql";
import useAuth from "@hooks/useAuth";
import {CheckoutFailedAlert} from "@pages/merchant/checkout/index/components/CheckoutFailedAlert";
import {Button} from "@src/@/components/ui/button";
import {toEuroString} from "@modules/casting/Number";


// @fixme paypal support
export const paymentMethods = ['SEPA', 'Credit Card'];

const paymentMethodMap = {
    'SEPA': PaymentMethod.Sepa,
    'Credit Card': PaymentMethod.CreditCard,
    // @fixme paypal support
    // 'PayPal': PaymentMethod.Paypal // Temp Fix
} as const;

export type SupportedPaymentMethod = `${keyof typeof paymentMethodMap}`;
export const zPaymentMethods = Object.keys(paymentMethodMap) as readonly SupportedPaymentMethod[]


export const formSchema = z.object({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    paymentMethod: z.enum(zPaymentMethods),
    cardNumber: z.string().min(1, {
        message: 'Card number must be at least 1 character.'
    }),
    cardHolder: z.string().min(1, {
        message: 'Card holder must be at least 1 character.'
    }),
    expirationDate: z.string().min(1, {}),
    cvv: z.string().min(3),
    agreeToTerms: z.boolean()
})


const CheckoutIndexPage: React.FC = () => {

    const navigate = useNavigate();
    const gql = useGql();
    const auth = useAuth();
    const formRef = useRef<HTMLFormElement>(null);


    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SupportedPaymentMethod>("Credit Card")
    const [canProceed, setCanProceed] = useState(false)
    const [values, setValues] = useState<z.infer<typeof formSchema>>()
    const [isPaymentFailed, setIsPaymentFailed] = useState(false)


    const tos = useAsyncMemo(async (): Promise<MinimumTosFragment> => {
        return (await gql.LatestTerms()).latestTerms;
    }, [])


    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            paymentMethod: 'Credit Card',
            cardNumber: '',
            cardHolder: '',
            expirationDate: '',
            cvv: '',
            agreeToTerms: false,
        }
    })

    const cart = useAsyncMemo(async (): Promise<ShoppingCartOutput[]> => {
        return (await gql.GetShoppingCart()).getShoppingCart
    }, [], [])

    const tier = useAsyncMemo(async (): Promise<TierOutput | undefined> => {
        if (cart.length == 0) {
            return undefined
        }
        return (await gql.GetTier({id: cart?.[0].tierId ?? ''})).getTier
    }, [cart])


    function handleChange(values: z.infer<typeof formSchema>) {
        if (values.cvv.trim() === ''
            || values.cardNumber.trim() === ''
            || values.cardHolder.trim() === ''
            || values.expirationDate.trim() === '') {
            setCanProceed(false)
        } else {
            setCanProceed(true)
            setValues(values)
        }
    }

    // Part of my workaround for form submit
    const handleSubmit = async () => {
        // formRef.current?.submit()
        console.log(values) // Undefined
        const testValues = form.getValues() // Live values

        if (auth.userId && testValues) {
            const findPaymentMethod = paymentMethodMap[selectedPaymentMethod as keyof typeof paymentMethodMap]
            gql.UpdateUser({
                id: auth.userId,
                input: {
                    paymentMethod: findPaymentMethod
                }
            })
                .then(() => {
                    gql.AcceptTermsForUser({
                        record: auth?.userId ?? '',
                        relation: tos?.id ?? ''
                    })
                        .then((r) => {
                            console.log(r)
                            setTimeout(() => {
                                gql.BuyTier({
                                    tierId: cart?.[0].tierId ?? '',
                                    intervalId: cart?.[0].intervalId ?? '',
                                    discountId: cart?.[0].discountId ?? ''
                                })
                                    .then((r) => {
                                        console.log(r)
                                        navigate('success')
                                    }).catch(err => {
                                        console.error(`BuyTier: ${err}`)
                                    })
                            }, 2000)
                        })
                        .catch(err => {
                            console.error(`AcceptTermsForUser: ${err}`)
                        })
                })
                .catch(err => console.error(err))
        }
    }

    return <ShowPageTemplate>
        {!tier && <div>Loading ...</div>}

        {isPaymentFailed && <CheckoutFailedAlert/>}

        <div className={'flex-col flex w-full items-center my-8 gap-y-2'}>
            <p className={'text-3xl font-semibold'}>{'Checkout'}</p>
            <p className={'text-xl font-medium'}>Total</p>
            <p className={'text-lg font-semibold'}>{toEuroString(tier?.price ?? 0)}</p>
        </div>

        <CheckOutFormComponent
            ref={formRef}
            form={form}
            paymentMethods={zPaymentMethods}
            buttonText={'Checkout'}
            onChange={handleChange}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
        />

        <CheckoutAgreement onSubmit={handleSubmit} agreement={tos} disabled={!canProceed}/>
        <Button onClick={() => setIsPaymentFailed(!isPaymentFailed)}>Test Button for Payment Failed</Button>

    </ShowPageTemplate>
}

export default CheckoutIndexPage