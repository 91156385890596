/**
 * Component to handle incoming push notifications.
 * Uses Firebase messaging to listen for notifications.
 */

import { useEffect } from 'react';
import * as fcm from 'firebase/messaging';
import {messaging} from "@app/fcm/firebase";
import useFirebaseMessaging from "@app/fcm/useFirebaseMessaging";
import {Button} from "@src/@/components/ui/button";

const PushNotificationHandler = () => {
    const fcmToken = useFirebaseMessaging();

    useEffect(() => {
        fcm.onMessage(messaging, (payload) => {
            new Notification(payload.notification?.title ?? 'Tjecko', {
                body: payload.notification?.body ?? ''
            })
        })
    }, [fcmToken.token]);

    // eslint-disable-next-line react/react-in-jsx-scope
    return <Button variant={'secondary'} onClick={() => {
        fcmToken.requestPermission();
    }}>Enable Notifications</Button>;
};

export default PushNotificationHandler;