import React, {useEffect} from 'react';
import Redirect from "@modules/routing/Redirect";

const HomePage = () => {


    /**
     * @fixme Monkey patch for hanging after OTP success redirect
     */
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.reload()
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, []);


    return <Redirect pathname={'/authorize'}/>;
};

export default HomePage;
