import React from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import NegativeButton from "@components/button/NegativeButton";
import {Link} from "react-router-dom";

const MfaSetupSuccessPage = () => {
    return (
        <ShowPageTemplate>
            <Link to={'deactivate'}>
                <NegativeButton className={'mt-16'}>Deactivate MFA</NegativeButton>
            </Link>
        </ShowPageTemplate>
    );
};

export default MfaSetupSuccessPage;
