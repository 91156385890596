import {RadioGroup, RadioGroupItem} from "@src/@/components/ui/radio-group";
import {Label} from "@src/@/components/ui/label";
import {ShopIcon} from "@pages/merchant/subscriber/show/components/CustomIcons/ShopIcon";
import React from "react";

const subscriptionDetails = [
    {
        title: 'Great Service - Basic',
        amount: '€30,00'
    },
    {
        title: 'Great Service - Premium',
        amount: '€35,00'
    },
    {
        title: 'Great Service - Pro',
        amount: '€40,00'
    },
]

export function TierSelectSection() {
    return (
        <div className={'flex flex-col gap-y-4 text-neutral-900'}>
            <p className={'font-medium text-sm text-primary-800'}>Select Subscription</p>
            <RadioGroup className={'flex-wrap flex gap-2'}>
                {
                    subscriptionDetails.map(subscription => {
                        return (
                            <div key={subscription.title} className={'w-full'}>
                                <RadioGroupItem value={subscription.title} id={subscription.title}
                                    className={'peer hidden'}/>
                                <Label htmlFor={subscription.title}
                                    className={'p-4 outline-1 outline outline-neutral-300 rounded-md flex' +
                                           ' items-center gap-x-4' +
                                           ' peer-data-[state=checked]:outline-2' +
                                           ' peer-data-[state=checked]:outline-primary-800'}>
                                    <ShopIcon/>
                                    <p className={'font-semibold text-sm flex-grow'}>{subscription.title}</p>
                                    <p className={'font-semibold text-sm'}>{subscription.amount}</p>
                                </Label>
                            </div>
                        )
                    })
                }
            </RadioGroup>
        </div>
    )
}