import {Link} from "react-router-dom";
import React, {FC, useMemo} from "react";
import PaymentListComponent from "@pages/merchant/payment/components/PaymentListComponent";
import {mockPaymentData} from "@app/mock/MockPaymentData";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import H2 from "@components/text/H2.component";

const LatestActivity: FC = () => {

    const {available} = useWorkspaceHeight();

    const paymentItems = useMemo(() => {
        return mockPaymentData.slice(0, 16)
    }, []);

    const listHeight = useMemo(() => {
        return `calc(${available - 209.5 - 41 - 30}px - 4.0625rem)`
    }, [available]);

    return (
        <div>
            <div
                className={'flex items-center justify-between border-b py-2 -mt-shell -mx-shell px-shell relative shadow-lg z-[1]'}>
                <H2>Latest Activity</H2>
                <Link to={'/payments/list'} className={'text-xs text-secondary-600'}>Show more</Link>
            </div>
            <div className={'h-[calc(1rem/32)]'}/>
            <PaymentListComponent forIndexPage={true} height={listHeight} items={paymentItems}/>
        </div>
    )
}
export default LatestActivity