// Application initialization imports
import React, {cloneElement, ComponentType, FC, ReactNode} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, createHashRouter, RouteObject, RouterProvider} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import {CookiesProvider} from "react-cookie";

// Registration imports
import {CategoryScale, Chart, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {register as registerSwiper} from 'swiper/element/bundle';

// Config imports
import reportWebVitals from "@config/reportWebVitals";
import {IS_PRODUCTION} from "@config/variables";
import {ChildRouterElement, IndexRouterElement} from "@config/router.config";
import "@config/i18n";

// Helper imports
import {IdTokenProvider} from "@modules/auth/useIdToken";
import {AuthProvider} from "@hooks/useAuth";
import AuthGuard from "@app/guards/Auth.guard";
import DebugGuard from "@app/guards/Debug.guard";

// App root imports
import Router from "@src/Router";
import App from "@src/App";
import '@src/index.css';
import NotFoundPage from "@pages/error/NotFound.page";


/** Register ChartJS */
Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ChartDataLabels
)

/** Register Swiper */
registerSwiper();

const platformRouter = process.env.REACT_APP_PLATFORM_PROVIDER === 'CORDOVA' ? createHashRouter : createBrowserRouter;

/** Register Router */
const router = platformRouter([{
    path: '/',
    element: <App/>,
    children: Router.map(r => {
        const indexRouterElement: IndexRouterElement = r;
        indexRouterElement.children = r.children.map((childRoute): ChildRouterElement => {
            const childRouterElement: ChildRouterElement = childRoute;
            let guardSet = false;

            const isGuarded = r.isGuarded ?? childRoute.isGuarded;
            if (isGuarded) {
                childRouterElement.element = cloneElement(<AuthGuard Page={childRoute.Component as ComponentType}/>)
                guardSet = true
            }

            if (r.isDebug || childRoute.isDebug) {
                if (isGuarded) {
                    childRouterElement.element = <DebugGuard>
                        {childRouterElement.element}
                    </DebugGuard>
                } else {
                    childRouterElement.element = cloneElement(<DebugGuard
                        Page={childRoute.Component as ComponentType}/>)
                }
                guardSet = true
            }

            if (guardSet) {
                delete childRouterElement.Component;
            }
            return childRouterElement;
        });
        return ({...indexRouterElement, index: false}) as RouteObject
    }),
}, {
    path: '*',
    element: <NotFoundPage/>
}]);

// noinspection JSUnusedLocalSymbols
/**
 * Setup main component
 * @param children
 * @constructor
 */
const Main: FC<{ children: ReactNode }> = ({children}) => <>
    {IS_PRODUCTION
        ? <React.StrictMode>{children}</React.StrictMode>
        : children
    }
</>

// noinspection JSUnusedLocalSymbols
/**
 * Setup application providers
 * @constructor
 */
const RootElement = () => <Main>
    <Auth0Provider
        domain="samfe.eu.auth0.com"
        clientId="SwwaGgL8huCiQjXMvc3OW5cA6GelYex7"
        authorizationParams={{redirect_uri: window.location.origin}}
        cacheLocation={'localstorage'}
    >
        <CookiesProvider defaultSetOptions={{path: '/'}}>
            <IdTokenProvider>
                <AuthProvider>
                    <RouterProvider router={router}/>
                </AuthProvider>
            </IdTokenProvider>
        </CookiesProvider>
    </Auth0Provider>
</Main>


/**
 * Setup DOM Root
 */
const renderDom = () => ReactDOM
    .createRoot(document.getElementById('root') as HTMLElement)
    .render(<RootElement/>)


// Set up Cordova
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.cordova) {
    document.addEventListener('deviceready', () => {
        renderDom()
    }, false)
} else {
    renderDom()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
