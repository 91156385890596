import React, {FC, useMemo, useState} from "react";
import i18n from "i18next";
import MfaCode from "@pages/merchant/settings/personal/mfa/components/MfaCode";
import PrimaryButton from "@components/button/PrimaryButton";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {Link} from "react-router-dom";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import QRCode from "react-qr-code";
import ErrorMessage from "@components/feedback/Message/ErrorMessage";
import useGql from "@modules/graphql/useGql";

type Props = object

const MfaSetupQrCodePage: FC<Props> = () => {

    const gql = useGql()

    const [errorMessage, setErrorMessage] = useState<string>()

    const qrCode = useAsyncMemo(async () => {
        return await gql.ConnectMfaQrCode()
            .then(r => r.connectMfaQrCode)
            .catch(() => {
                setErrorMessage(i18n.t('otp.text.qr.failure'))
                return undefined
            })
    }, [])

    const mfaCode = useMemo(() => {
        if (!qrCode) {
            return undefined
        }

        return qrCode.split('?secret=')[1].split('&')[0]
    }, [qrCode]);


    return (
        <ShowPageTemplate pageTitle={'Setup MFA'}>

            <p>{i18n.t('otp.text.qr.scan')}</p>
            {qrCode && <QRCode
                className={'mx-auto my-8 w-[14rem]'}
                value={qrCode}
                fgColor="#030A17"
                level="H" // high error correction level
            />}

            <ErrorMessage message={errorMessage}/>

            {mfaCode && <MfaCode value={mfaCode}/>}

            <Link to={'verify-otp'} className={'fixed bottom-20 w-[calc(100vw-2rem)]'}>
                <PrimaryButton>
                    {i18n.t('otp.text.enter')}
                </PrimaryButton>
            </Link>
        </ShowPageTemplate>
    );
}
export default MfaSetupQrCodePage;