import React from "react";
import {FormControl, FormField, FormItem, FormLabel} from "@src/@/components/ui/form";
import {Input} from "@src/@/components/ui/input";
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {formSchema} from "@pages/merchant/checkout/index/CheckOut.index.page";
import {FormSchemaKeys} from "./CheckOutFormComponent";

interface InputCheckOutComponentProp {
    form: UseFormReturn<z.infer<typeof formSchema>>;
    label: string;
    formName: FormSchemaKeys;
}

const InputCheckOutComponent: React.FC<InputCheckOutComponentProp> = ({form, formName, label}) => {
    return (
        <FormField control={form.control} name={formName} render={({field}) => {
            return (
                <FormItem className={'w-full'}>
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                        <Input {...field}/>
                    </FormControl>
                </FormItem>
            )

        }}/>
    )
}

export default InputCheckOutComponent