import React, {FC, useRef} from 'react';
import useGql from "@modules/graphql/useGql";
import {FormFields} from "@components/form/fields/FormField.component";
import {UpdateUserInput, UserIdentity} from "@src/gql-schema";
import {z} from "zod";
import Form from "@components/form/Form.component";
import useAsyncMemo from "@hooks/useAsyncMemo";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import UserIcon from "@components/icon/UserIcon";
import PrimaryButton from "@components/button/PrimaryButton";
import {useNavigate} from "react-router-dom";


const MerchantRegisterPage: FC = () => {


    const gql = useGql();
    const formRef = useRef<HTMLFormElement>(null)
    const navigate = useNavigate()

    const user = useAsyncMemo(async (): Promise<UserIdentity> => {
        return await gql.GetIdentity().then(r => {
            return r.getIdentity.user
        })
    }, [])

    const fields: FormFields<UpdateUserInput> = {
        firstName: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'First name',
            defaultValue: user?.name.split(' ')[0]
        },
        lastName: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Last name',
            defaultValue: user?.name.split(' ')[1]
        },
        email: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Email',
            defaultValue: user?.email,
            disabled: true
        },
    }

    const onSubmitSuccess = async () => {
        navigate('/register/company')
    }

    return (
        <ShowPageTemplate>
            <div className={'rounded-full p-2 border border-neutral-500 mx-auto w-fit mt-12'}>
                <UserIcon/>
            </div>
            <Form
                ref={formRef}
                id={user?.id}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                submit={gql.UpdateUser}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                onSubmitSuccess={onSubmitSuccess}
                fields={fields}
                payloadInjection={{}}
            />

            <PrimaryButton className={'mt-4'} onClick={() => formRef.current?.requestSubmit()}>Submit</PrimaryButton>
        </ShowPageTemplate>
    )
};
export default MerchantRegisterPage;
