import React, {useEffect, useState} from 'react';
import useGql from "@modules/graphql/useGql";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import Redirect from "@modules/routing/Redirect";

export const TjeckoSubscriptionRedirect = () => {
    const gql = useGql();

    const [route, setRoute] = useState('');
    const [state, setState] = useState<null | string>(null)

    useEffect(() => {
        gql.GetMerchantTjeckoSubStatus().then(result => {
            if (result.getMerchantTjeckoSubStatus.initialPaymentComplete) {
                setState('success')
                setRoute('/checkout/success');
                return;
            }
            setRoute('/checkout');
        }).catch(() => {
            /**
             *
             * Temp ... I thought there shouldn't be a redirect to happen on failed but since currently it always error
             * I just added something here to show the redirect
             *
             * */
            setState(null)  // Change to 'success' for success page or 'failed' to get the payment failed message on
            // Checkout
            setRoute('/checkout') // Change to '/checkout/success' for success page

        });


    }, []);

    return (
        route === ''
            ? <LoadingSpinner/>
            : <Redirect pathname={route} state={{status: state}}/>
    );
}