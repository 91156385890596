import {FormControl, FormField, FormItem, FormLabel} from "@src/@/components/ui/form";
import {Input} from "@src/@/components/ui/input";
import React from "react";

export function AddCompanyUserDetails() {
    return (
        <div className={'space-y-4 pb-6'}>
            <p className={'font-roboto text-lg font-semibold text-primary'}>User Information</p>
            <FormField name={'name'} render={({field}) => (
                <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                        <Input placeholder={'John Doe'} {...field} className={'capitalize'}/>
                    </FormControl>
                </FormItem>
            )}/>
            <FormField name={'email'} render={({field}) => (
                <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        <Input placeholder={'johndoe@gmail.com'} {...field} />
                    </FormControl>
                </FormItem>
            )}/>
            <FormField name={'mobileNo'} render={({field}) => (
                <FormItem>
                    <FormLabel>Mobile No.</FormLabel>
                    <FormControl>
                        <Input placeholder={'1 234 567 890'} {...field} />
                    </FormControl>
                </FormItem>
            )}/>
        </div>
    )
}