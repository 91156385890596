import React, {Dispatch, SetStateAction, useState} from 'react';
import {ColorResult} from "react-color";
import {useNavigate} from "react-router-dom";
import ColorPickerComponent from "@pages/merchant/onboarding/components/ColorPicker.component";
import InputLogoComponent from "@pages/merchant/onboarding/components/InputLogo.component";
import {Accordion, AccordionItem} from "@src/@/components/ui/accordion";

interface ColorScheme {
    bgColor: ColorResult;
    textColor: string;
}

export interface Color {
    [key: string]: ColorScheme;
}


const colorInitialState: Color = {
    primaryColor: {
        bgColor: {
            "hsl": {
                "h": 212.23529411764707,
                "s": 1,
                "l": 0.5,
                "a": 1
            },
            "hex": "#0076ff",
            "rgb": {
                "r": 0,
                "g": 118,
                "b": 255,
                "a": 1
            },
        }, textColor: '#FFF'
    },
    secondaryColor: {
        bgColor: {
            "hsl": {
                "h": 211.875,
                "s": 1,
                "l": 0.25098039215686274,
                "a": 1
            },
            "hex": "#003c80",
            "rgb": {
                "r": 0,
                "g": 60,
                "b": 128,
                "a": 1
            },
        }, textColor: '#FFF'
    },
}

interface CompanyBranding {
    primaryColor: ColorResult | null
    secondaryColor: ColorResult | null
    logo?: string;
}

const companyBrandingInitialState: CompanyBranding = {
    primaryColor: null,
    secondaryColor: null,
    logo: ''
}

function getTextColor(bgColor: string) {
    /* Not the perfect solution but works for now */
    const hex = bgColor.replace('#', '')
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    const brightness = ((r * 299) + (g * 587) + (b * 144)) / 1000

    return brightness > 125 ? '#000' : '#FFF'
}


export interface AddCompanyBrandingPageProps {
    colors: Color,
    handleOnComplete: (color: ColorResult, colorType: string) => void;
    handleOnClick?: (number: number) => void;
    handleOnSubmit: () => void;
    file?: string;
    setFile: Dispatch<SetStateAction<string>>
}

const AddCompanyBrandingPulloverScreen = () => {

    const navigate = useNavigate();
    const [colors, setColors] = useState<Color>(colorInitialState)
    const [companyBranding, setCompanyBranding] = useState<CompanyBranding>(companyBrandingInitialState)

    function handleOnComplete(color: ColorResult, colorType: string) {
        setColors((prev) => {
            return {
                ...prev,
                [colorType]: {
                    bgColor: {
                        hsl: color.hsl,
                        rgb: color.rgb,
                        hex: color.hex
                    },
                    textColor: getTextColor(color.hex)
                }
            }
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {handleOnSubmit, setFile, file}: AddCompanyBrandingPageProps = {
        colors: colorInitialState,
        handleOnComplete: handleOnComplete,
        handleOnSubmit: () => {
            setCompanyBranding({
                ...companyBranding,
                primaryColor: colors.primaryColor.bgColor,
                secondaryColor: colors.secondaryColor.bgColor,
                logo: file
            })

            navigate('..')
            setFile('')
        },
        file: undefined,
        setFile: (file: string | ((file: string) => string)) => {
            console.log('not implemented', file)
        }, handleOnClick: () => {
            console.log('not implemented')
        },


    }
    const keys = Object.keys(colors)

    return (
        <>
            <div className=''>
                <p className={'text-base font-semibold font-roboto'}>
                    Set your Brand colors
                </p>
                <Accordion type="single" collapsible className={'flex flex-col gap-y-2'}>
                    {
                        keys.map((key, index) => {
                            return (
                                <AccordionItem key={index} value={key}
                                    className={'border-none flex flex-col gap-y-2'}>
                                    <ColorPickerComponent
                                        propertyName={key}
                                        key={index}
                                        index={index}
                                        selectedColor={key === 'primaryColor' ? colors.primaryColor.bgColor.hex : colors.secondaryColor.bgColor.hex}
                                        textColor={key === 'primaryColor' ? colors.primaryColor.textColor : colors.secondaryColor.textColor}
                                        handleOnComplete={handleOnComplete}
                                    />
                                </AccordionItem>
                            )
                        })
                    }

                </Accordion>

            </div>
            <InputLogoComponent file={file} setFile={setFile}/>
        </>
    );
};

export default AddCompanyBrandingPulloverScreen;
