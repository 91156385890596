import React, {FC, ReactNode} from 'react';
import {NavbarBottomProvider} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {NavbarTopProvider} from "@components/navigation/NavbarTop/NavbarTop.context";

type Props = {
    children: ReactNode
}
const NavigationProvider: FC<Props> = ({children}) => {
    return (
        <NavbarTopProvider>
            <NavbarBottomProvider>
                {children}
            </NavbarBottomProvider>
        </NavbarTopProvider>
    );
};

export default NavigationProvider;
