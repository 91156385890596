import React, {CSSProperties, useMemo, useState} from "react";
import {EditorState} from "lexical";
import {$convertToMarkdownString, TRANSFORMERS} from "@lexical/markdown";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {LexicalErrorBoundary} from "@lexical/react/LexicalErrorBoundary";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import {TablePlugin} from "@lexical/react/LexicalTablePlugin";
import {CheckListPlugin} from "@lexical/react/LexicalCheckListPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import ToolbarPlugins from "@components/markdown/ToolbarPlugins";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {classNames} from "@modules/casting/String";

export function EditorContent() {


    const [toolbarOpen, setToolbarOpen] = useState(false)

    function onChange(editorState: EditorState) {
        editorState.read(() => {
            ($convertToMarkdownString(TRANSFORMERS, undefined, true)); // Update markdown string on change
        });
    }


    const height: CSSProperties['height'] = useMemo(() => {
        return `calc(100vh - ${6 + (toolbarOpen ? 17.75 : 0)}rem)`
    }, [toolbarOpen]);


    return (
        <>
            <MarkdownShortcutPlugin transformers={TRANSFORMERS}/>
            <TablePlugin hasTabHandler={true} hasCellBackgroundColor={true} hasCellMerge={true}/>
            <CheckListPlugin/>
            <LinkPlugin/>
            <ListPlugin/>
            <HistoryPlugin/>
            <AutoFocusPlugin/>
            <OnChangePlugin onChange={onChange}/>
            <div style={{height}}
                className={classNames(' relative transition-all ease-in-out', toolbarOpen ? 'duration-500' : 'duration-300')}>
                <RichTextPlugin
                    contentEditable={<ContentEditable
                        className={'overflow-y-auto p-8 h-full prose bg-white !outline-none'}/>}
                    placeholder={<div className={'absolute top-[19px] left-4 pointer-events-none'}>Enter
                        some
                        text...</div>}
                    ErrorBoundary={LexicalErrorBoundary}
                />
            </div>
            <ToolbarPlugins toolbarState={[toolbarOpen, setToolbarOpen]}/>
        </>
    );
}