import {InfoIcon} from "@pages/merchant/subscriber/show/components/CustomIcons/InfoIcon";
import React from "react";

export function InfoBanner() {
    return (
        <div className={'bg-primary-100 border rounded-md border-primary-300 flex items-center gap-x-2 p-4'}>
            <InfoIcon/>
            <p className={'text-sm font-semibold text-primary-700'}>Benefits and Loyalty will remain unchanged</p>
        </div>
    )
}