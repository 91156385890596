import React from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import SuccessIcon from "@components/icon/success.icon";
import PrimaryButton from "@components/button/PrimaryButton";
import {Link} from "react-router-dom";

const MfaSetupSuccessPage = () => {
    return (
        <ShowPageTemplate>
            <div className={'flex flex-col items-center gap-y-12 translate-y-[calc(50vh-14rem)]'}>
                <SuccessIcon/>
                <p className={'text-xl font-semibold'}>MFA Successfully Deactivated</p>
                <Link to={'/settings/setup-mfa'} className={'w-full'}>
                    <PrimaryButton>Return back</PrimaryButton>
                </Link>
            </div>
        </ShowPageTemplate>
    );
};

export default MfaSetupSuccessPage;
