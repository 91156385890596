import React from 'react'

export function CheckoutFailedAlert() {
    return (
        <div className={'flex flex-col items-center justify-center w-full gap-y-2'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="101" height="100" viewBox="0 0 101 100"
                fill="none">
                <path
                    d="M50.5 91.6667C73.5119 91.6667 92.1667 73.0119 92.1667 50C92.1667 26.9881 73.5119 8.33334 50.5 8.33334C27.4881 8.33334 8.83334 26.9881 8.83334 50C8.83334 73.0119 27.4881 91.6667 50.5 91.6667Z"
                    stroke="#E60000" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M63 37.5L38 62.5" stroke="#E60000" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"/>
                <path d="M38 37.5L63 62.5" stroke="#E60000" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
            <p className={'text-red-600 text-xl font-semibold text-center w-full'}>Payment Failed</p>
        </div>
    )
}