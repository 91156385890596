import React, {FC} from 'react';
import Badge, {BadgeType} from "@components/indicator/Badge.component";
import {classNames} from "@modules/casting/String";


type Props = BadgeType & {
    className?: string
}
const Pill: FC<Props> = (props) => {
    return <Badge {...props} className={classNames('!rounded-full px-3 py-1 !text-[0.7rem]', props.className)}/>
};

export default Pill;
