import React, {Dispatch, FC, SetStateAction, useMemo} from 'react';
import {TextStyleGroup} from "@components/markdown/components/TextStyleGroup";
import {TextFormattingGroup} from "@components/markdown/components/TextFormattingGroup";
import {ListGroup} from "@components/markdown/components/ListGroup";
import {LinkGroup} from "@components/markdown/components/LinkGroup";
import {Sheet, SheetContent, SheetTrigger} from "@shadcn/components/ui/sheet"
import {FormatSize} from "@mui/icons-material";
import H2 from "@components/text/H2.component";

type Props = {
    toolbarState: [boolean, Dispatch<SetStateAction<boolean>>]
}
const ToolbarPlugins: FC<Props> = ({toolbarState}) => {

    const [open, setOpen] = useMemo(() => {
        return toolbarState
    }, [toolbarState])

    return (
        <div className={'fixed bottom-0 w-screen flex content-center h-10 justify-between px-shell bg-neutral-50'}>
            <Sheet open={open} modal={false}>
                <SheetTrigger className={'!z-[100] static'} onClick={() => setOpen(!open)}><FormatSize/></SheetTrigger>
                <SheetContent onCloseClicked={() => setOpen(false)} side={'bottom'}
                    className={'w-screen bottom-10 shadow-none !bg-neutral-50'}>
                    <div>
                        <H2>Format</H2>
                        <TextStyleGroup/>
                        <TextFormattingGroup/>
                        <ListGroup/>
                        <LinkGroup/>
                    </div>
                </SheetContent>
            </Sheet>
        </div>

    );
}
export default ToolbarPlugins