import PaymentListComponent from "@pages/merchant/payment/components/PaymentListComponent";
import React, {useMemo} from "react";
import {PaymentItem} from "@app/mock/MockPaymentData";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";
import {CustomerDetails} from "@pages/merchant/payment/show/components/PaymentDetails";

interface CustomerPaymentHistoryProps {
    customer: CustomerDetails | undefined
    customerHistory: PaymentItem[]
}

export function CustomerPaymentHistory({customer, customerHistory}: CustomerPaymentHistoryProps) {

    const {available} = useWorkspaceHeight()

    const listHeight = useMemo(() => {
        return customer?.status === 'reversed' ? `calc(${available - 320.5 - 41 - 30}px - 4.0625rem)` : `calc(${available - 230.5 - 41 - 30}px - 4.0625rem)`

    }, [available, customer?.status]);

    return (
        <div className={'-m-shell'}>
            <PaymentListComponent items={customerHistory} height={listHeight}/>
        </div>
    )
}