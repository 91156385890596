import {FormControl, FormItem, FormMessage} from "@src/@/components/ui/form";
import React from "react";
import {availablePaymentMethods, FormSchema} from "../pages/CreateSubscriptionFormPullOverScreen";
import {FieldErrors, UseFormReturn} from "react-hook-form";

const feeTypes = ['Percentage', 'Fixed'];

interface PaymentMethodsInputProps {
    fields: { methodName: string; feeType: string; feeAmount: string }[]
    form: UseFormReturn<FormSchema>
    handleCheckboxChange: (method: string) => void
    errors: FieldErrors<FormSchema>
}

export function PaymentMethodsInput({fields, form, handleCheckboxChange, errors}: PaymentMethodsInputProps) {
    return (
        <div className={'space-y-2.5 border rounded-md py-8 px-4 bg-white'}>
            <div className={'space-y-2'}>
                <p className={'font-semibold'}>Payment Methods</p>
                <div className={'flex items-center'}>
                    <p className={'w-5/12 font-medium'}>Methods</p>
                    <p className={'w-7/12 font-medium'}>Transaction Fee</p>
                </div>
                <FormMessage>{errors.paymentMethods?.message ?? errors.paymentMethods?.root?.message}</FormMessage>
            </div>

            {availablePaymentMethods.map((method) => {
                const isChecked = fields.some(field => field.methodName === method.name);
                const fieldIndex = fields.findIndex(field => field.methodName === method.name);

                const feeTypeFieldName = `paymentMethods.${fieldIndex}.feeType` as const;
                const feeAmountFieldName = `paymentMethods.${fieldIndex}.feeAmount` as const;

                return (
                    <FormItem key={method.name}
                        className={'flex w-full items-center justify-between gap-x-2 space-y-0'}>
                        <FormControl className={'flex-grow w-5/12'}>
                            <div className={'flex gap-x-2'}>
                                <input
                                    type='checkbox'
                                    id={`paymentMethods.${method.name}`}
                                    name={`paymentMethods.${method.name}`}
                                    value={method.name}
                                    checked={isChecked}
                                    onChange={() => handleCheckboxChange(method.name)}
                                />
                                <label htmlFor={`paymentMethods.${method.name}`}>
                                    {method.icon === '' ? method.name : method.icon}
                                </label>
                            </div>
                        </FormControl>
                        <div className={'flex items-center gap-x-2 mt-0 justify-end flex-grow w-7/12'}>
                            <FormControl className={'w-3/12'}>
                                <div>
                                    <input
                                        className={'w-full border rounded-md py-0.5 px-1 text-sm'}
                                        {...form.register(feeAmountFieldName)}
                                        placeholder='Fee'
                                        disabled={!isChecked}
                                    />
                                </div>
                            </FormControl>
                            <FormControl className={'flex-grow'}>
                                <div className={''}>
                                    <select
                                        className={'w-full border rounded-md py-0.5 px-1 text-sm disabled:opacity-50'}
                                        {...form.register(feeTypeFieldName)}
                                        disabled={!isChecked}>
                                        <option value=''>Select</option>
                                        {feeTypes.map(feeType => (
                                            <option key={feeType} value={feeType}>{feeType}</option>
                                        ))}
                                    </select>
                                </div>
                            </FormControl>
                        </div>
                    </FormItem>
                );
            })}
        </div>
    )
}