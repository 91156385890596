import React, {useEffect, useMemo, useRef, useState} from 'react';
import PullOver, {PullOverProps} from "@components/pullover/PullOver.component";
import Form, {ExtendFormProps} from "@components/form/Form.component";


interface Props<
    Fields,
    Payload extends Fields,
    PayloadInjection,
    Mutation
> extends PullOverProps {
    form: ExtendFormProps<Fields, Payload, PayloadInjection, Mutation>,
    openState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}


const PullOverForm = <
    Fields,
    Payload extends Fields,
    PayloadInjection extends Omit<Payload, (keyof Fields extends never ? keyof Payload : keyof Fields)>,
    Mutation
>({openState, ...props}: Props<Fields, Payload, PayloadInjection, Mutation>) => {

    const formRef = useRef<HTMLFormElement>(null)

    const internalOpenState = useState(openState?.[0] ?? false)
    const [open, setOpen] = useMemo(() => {
        if (!openState) {
            return internalOpenState
        }
        internalOpenState[1](openState[0])
        return openState
    }, [openState]);

    useEffect(() => {
        console.log({open})
    }, [open]);

    const {
        title,
        description,
        trigger,
        submit,
        cancel,
        onClose,
        form
    } = useMemo(() => {
        return props
    }, [props]);

    const getData = useMemo(() => {
        if (open) {
            return form.getData
        }
        return undefined
    }, [open]);


    const handleSubmitClick = () => {
        submit?.onClick?.()
        formRef.current?.requestSubmit()
    }


    const handleTrigger = async () => {
        await trigger?.onClick?.()
    }

    const handleSubmitSuccess = async (values: Mutation) => {
        await form.onSubmitSuccess?.(values)
        setOpen(false)
    }


    return (<>
        <PullOver
            trigger={trigger && {
                ...trigger,
                onClick: handleTrigger
            }}
            title={title}
            description={description}
            openState={openState}
            submit={{
                title: submit?.title,
                onClick: handleSubmitClick
            }}
            cancel={cancel && {
                ...cancel,
                dialog: {
                    title: cancel.dialog?.title ?? 'Cancel',
                    description: cancel.dialog?.description
                }
            }}
            onClose={onClose}
        >
            <Form
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id={form?.['id']}
                ref={formRef}
                getData={getData}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                submit={form.submit}
                fields={form.fields}
                payloadInjection={form.payloadInjection}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onSubmitSuccess={handleSubmitSuccess}
            />
        </PullOver>
    </>);
};

export default PullOverForm;
