import React, {forwardRef, useImperativeHandle} from 'react';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Form} from '@src/@/components/ui/form';
import {CompanyUser} from "@app/mock/MockCompanyData";
import {AddCompanyUserDetails} from "@pages/merchant/settings/company/components/AddCompanyUserDetails";
import {AddCompanyUserRole} from "@pages/merchant/settings/company/components/AddCompanyUserRole";
import {useForm} from "react-hook-form";

interface AddCompanyUserPulloverScreenProps {
    addUser: (user: CompanyUser) => void
}

const formSchema = z.object({
    name: z.string().min(1, `Name can't be empty`),
    role: z.array(z.string()),
    email: z.string().email(`Invalid email address`).min(1, `Email can't be empty`),
    mobileNo: z.string().min(1, `Mobile number can't be empty`)
});

export type FormSchema = z.infer<typeof formSchema>

export const AddCompanyUserPulloverScreen = forwardRef(({addUser}: AddCompanyUserPulloverScreenProps, ref) => {
    const form = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: '',
            email: '',
            role: [],
            mobileNo: ''
        }
    });

    const handleRoleChange = (role: string, isChecked: boolean | 'indeterminate') => {
        const currentRoles = form.getValues('role');
        let updatedRoles: string[];

        if (role === 'Admin') {
            updatedRoles = isChecked ? ['Admin'] : [];
        } else {
            updatedRoles = isChecked
                ? [...currentRoles.filter(r => r !== 'Admin'), role]
                : currentRoles.filter(r => r !== role);
        }

        form.setValue('role', updatedRoles);
    };

    const onSubmit = (values: FormSchema) => {
        const newUser: CompanyUser = {
            id: String(new Date().getTime()), // For random id number only
            ...values
        };
        addUser(newUser);
        form.reset();
    };

    useImperativeHandle(ref, () => ({
        submit: () => form.handleSubmit(onSubmit)()
    }));


    return (
        <Form {...form}>
            <form className={'text-neutral-900 divide-y'}>
                <AddCompanyUserDetails/>
                <AddCompanyUserRole form={form} handleRoleChange={handleRoleChange}/>
            </form>
        </Form>
    )
});

AddCompanyUserPulloverScreen.displayName = 'AddCompanyUserPulloverScreen';
