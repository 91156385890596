import React from "react";
import {Button} from "@src/@/components/ui/button";
import {Label} from "@src/@/components/ui/label";
import {Checkbox} from "@src/@/components/ui/checkbox";
import {CheckboxSection, State} from "@pages/merchant/subscriber/list/components/SubscriberFilterPullover";

interface FilterSubscriptionSectionProps {
    handleChange: (value: string | boolean, category: string, name: string) => void
    handleReset: (category: string) => void | null
    filter: State
    getSelectedFilterCount: (data: CheckboxSection[]) => string
}

export function FilterSubscriptionSection({
    handleChange,
    handleReset,
    filter,
    getSelectedFilterCount
}: FilterSubscriptionSectionProps) {

    return (
        <div className={'pt-6'}>
            <div className={'space-y-4'}>
                <div className={'flex items-center justify-between'}>
                    <p className={'text-xl w-full font-roboto font-medium text-primary-800'}>Subscription</p>
                    <div className={'flex items-center gap-x-2'}>
                        <div
                            className={'text-xs bg-primary-600 text-neutral-100 px-2 py-1 rounded-md'}>{getSelectedFilterCount(filter.subscription)}</div>
                        <Button size={'default'}
                            className={'flex-grow-0 w-auto h-auto bg-neutral-200 text-neutral-700'}
                            onClick={() => handleReset('subscription')}>Reset</Button>
                    </div>
                </div>


                <div
                    className={'flex-wrap flex gap-2'}>
                    {
                        filter.subscription.map(item => {
                            return (
                                <div key={item.title}>
                                    <Checkbox checked={item.isSelected} id={item.name ?? item.title} className={'peer' +
                                        ' hidden'}
                                    onCheckedChange={(value) => handleChange(value, 'subscription', item.title)}/>
                                    <Label htmlFor={item.name ?? item.title} id={item.name ?? item.title}
                                        className={'peer-data-[state=checked]:bg-primary-800' +
                                               ' peer-data-[state=checked]:text-neutral-100' +
                                               ' peer-data-[state=checked]:border-primary-800' +
                                               ' w-auto h-auto rounded-md px-2 py-1 aspect-auto border' +
                                               ' border-neutral-400 font-medium text-neutral-600 shadow-none flex' +
                                               ' gap-x-1 items-center'}>
                                        {/* Dummy Icon */}
                                        <div className={'h-4 w-4 border border-neutral-600 rounded'}></div>

                                        {item.title}
                                    </Label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}