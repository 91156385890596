import React from 'react'
import {Checkbox} from "@src/@/components/ui/checkbox";
import {Label} from "@src/@/components/ui/label";
import {CompanyUser} from "@app/mock/MockCompanyData";

interface CompanyUserRoleProps {
    currentUser: CompanyUser | undefined
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
}

const roles = ['Admin', 'Financial Manager', 'Subscription Manager', 'Subscriber Manager', 'User Manager']

export function CompanyUserRole({currentUser, changeUserRole}: CompanyUserRoleProps) {
    return (
        <div className={'pt-6 space-y-4'}>
            <p className={'font-roboto text-lg font-semibold text-primary'}>
                Roles
            </p>
            <div className={'flex flex-col gap-y-3'}>
                {
                    roles.map(role => {
                        const isChecked = currentUser?.role.includes(role) || false;
                        return (
                            <div key={role}>
                                <Checkbox id={role}
                                    value={role}
                                    name={role}
                                    checked={isChecked}
                                    onCheckedChange={(check) => {
                                        if (currentUser) {
                                            changeUserRole(currentUser.id, role, check)
                                        }
                                    }}
                                /><Label
                                    htmlFor={role}
                                    className={'text-base ml-2 font-semibold'}>{role}</Label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}