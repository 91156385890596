import {motion} from "framer-motion";
import React from "react";
import {TjeckoLogo} from "@components/icon/tjecko.icon";

export function LoginHeader() {
    return (
        <header className={'grid grid-cols-1 gap-y-8'}>
            <motion.div
                initial={{opacity: 0, scale: 0.5, translateY: '-50%'}}
                animate={{opacity: 1, scale: 1, translateY: 0}}
                transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
            >
                <TjeckoLogo/>
            </motion.div>
            <motion.div
                initial={{opacity: 0, scale: 0.5, translateY: '-50%'}}
                animate={{opacity: 1, scale: 1, translateY: 0}}
                transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
            >
                <h1 className="text-3xl text-neutral-900 font-medium font-roboto">Welcome Back</h1>
            </motion.div>
        </header>
    )
}