import {SelectOption} from "@components/form/fields/Select.component";

/**
 * Type of extra
 */
export enum ExtraTypeEnum {
    euro = 'Euro',
    percentage = 'Percentage'
}

export type ExtraTypeValue = keyof typeof ExtraTypeEnum
export type ExtraTypeName = (typeof ExtraTypeEnum)[keyof typeof ExtraTypeEnum]

export const ExtraTypeOptions = Object.keys(ExtraTypeEnum).map((k) => ({
    title: ExtraTypeEnum[k as ExtraTypeValue] as ExtraTypeName,
    value: k as ExtraTypeValue
}) satisfies SelectOption<ExtraTypeValue>)


/**
 * When extra applies
 */
export enum ExtraWhenEnum {
    on_invoice = 'Every invoice',
    start_subscription = 'First invoice',
    end_subscription = 'Last invoice',
    // @todo evaluate use case of types below
    monthly = 'Monthly',
    quarterly = 'Quarterly',
    yearly = 'Yearly',
}

export type ExtraWhenValue = keyof typeof ExtraWhenEnum
export type ExtraWhenName = (typeof ExtraWhenEnum)[keyof typeof ExtraWhenEnum]

export const ExtraWhenOptions = Object.keys(ExtraWhenEnum).map((k) => ({
    title: ExtraWhenEnum[k as ExtraWhenValue] as ExtraWhenName,
    value: k as ExtraWhenValue
}) satisfies SelectOption<ExtraWhenValue>)
