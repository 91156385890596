export enum WeekDays {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday'
}

export enum MonthParts {
    first_week = 'First week',
    last_week = 'Last week'
}