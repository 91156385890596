import React, {useState} from 'react'
import {ShopIcon} from "@pages/merchant/subscriber/show/components/CustomIcons/ShopIcon";
import {SubscriptionPeriodSection} from "@pages/merchant/subscriber/show/components/SubscriptionPeriodSection";

export function CancelSubscriptionPulloverContent() {
    const [condition, setCondition] = useState('Immediately')

    return (
        <div className={'space-y-4'}>
            <div className={'flex flex-col gap-y-2'}>
                <p className={'font-bold'}>Are you sure you want to cancel?</p>
                <p className={'text-sm font-medium'}>Customer will be notified about the cancellation.</p>
            </div>
            <div className={'space-y-4'}>
                <p className={'text-sm text-primary-800 font-medium'}>The following subscription will be cancelled</p>
                <div className={'p-4 border rounded-md flex flex-col gap-y-6'}>
                    <div className={'flex gap-x-2'}>
                        <ShopIcon/>
                        <div>
                            <p className={'font-semibold'}>Great Service</p>
                            <p className={'text-xs'}>Next invoice on Jun 01, 2023 for €30</p>
                        </div>
                        <p className={'text-sm font-semibold'}>€30,00</p>
                    </div>
                    <div className={'flex justify-center flex-col px-7'}>
                        <p className={'text-sm font-semibold text-left'}>Subscription commitment until</p>
                        <p className={'text-left text-xs'}>Next invoice on Dec 31, 2023</p>
                    </div>
                    <div className={'flex justify-center flex-col px-7'}>
                        <p className={'text-sm font-semibold text-left'}>Churn Rate</p>
                        <p className={'text-left text-xs'}>€180,00</p>
                    </div>
                </div>
            </div>
            <SubscriptionPeriodSection condition={condition} setCondition={setCondition}/>
        </div>
    )
}