import PullOver from "@components/pullover/PullOver.component";
import React, {useState} from "react";
import {
    CancelSubscriptionPulloverContent
} from "@pages/merchant/subscriber/show/components/CancelSubscriptionPulloverContent";

export function CancelSubscriptionPullover() {
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false)

    return (
        <PullOver
            variations={'max-height'}
            title={'Cancel Subscription'}
            trigger={
                {
                    title: <p className={'w-full'}>Cancel Subscription</p>,
                    onClick: () => setOpenCancelSubscription(true),
                    variation: 'negative'
                }
            }
            openState={[openCancelSubscription, setOpenCancelSubscription]}
            submit={
                {
                    title: 'Cancel Subscription',
                    onClick: () => {
                        setOpenCancelSubscription(false)
                    }
                }
            }
            cancel={
                {
                    title: 'Cancel',
                }
            }
        >

            <CancelSubscriptionPulloverContent/>

        </PullOver>
    )
}