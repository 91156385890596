import React, {useEffect, useMemo, useState} from 'react';
import {ChevronLeft} from "@mui/icons-material";
import {Link, useLocation} from "react-router-dom";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@shadcn/components/ui/dropdown-menu";
import {__t} from "@config/i18n";
import {useCurrentRoute} from "@modules/routing/useRouter";
import {motion, useAnimationControls} from "framer-motion";
import {useHistory} from "@modules/routing/useHistory";
import {useNavbarTop} from "@components/navigation/NavbarTop/NavbarTop.context";
import {useMeasure} from "@uidotdev/usehooks";
import {classNames} from "@modules/casting/String";

const NavbarTop = () => {

    const currentRoute = useCurrentRoute();
    const location = useLocation();
    const history = useHistory();
    const navState = useNavbarTop()
    const givenNavTitle = navState.title[0]
    const [givenBackRoute, setGivenBackRoute] = navState.back
    const givenNextRoute = navState.next[0]
    const children = navState.children[0];
    const setHeight = navState.height[1]

    const [childItemRef, childItemMeasure] = useMeasure()


    const [ref, {height}] = useMeasure()
    const backButtonAnimation = useAnimationControls()

    const [initialBack, setInitialBack] = useState(false)
    useEffect(() => {
        if (currentRoute.item.parent) {
            setInitialBack(true)
            backButtonAnimation.start({
                x: '0',
                opacity: 0
            }).then()
        }
    }, []);


    useEffect(() => {
        const isGoingBack = currentRoute.item.parent != null && location.pathname == currentRoute.item.parent.path
        backButtonAnimation.start({
            x: isGoingBack ? '12.5vw' : '-6.25vw',
            opacity: 40
        }).then(() => {
            backButtonAnimation.start({
                x: isGoingBack ? '25vw' : '-25vw',
                opacity: 0
            }).finally(() => {
                currentRoute.reset()
            })
        })
        setGivenBackRoute(undefined)
    }, [location]);


    useEffect(() => {
        if (currentRoute.item.parent == null) {
            return;
        }

        let moveDeeper = false
        if (history.length > 1) {
            if (currentRoute.item.path.includes(history[history.length - 2])) {
                moveDeeper = true
            }
        }

        if (moveDeeper) {
            backButtonAnimation.start({
                x: '25vw',
                opacity: 0
            }).then(() => {
                backButtonAnimation.start({
                    x: '12.5vw',
                    opacity: 25
                }).then(() => {
                    backButtonAnimation.start({
                        x: 0,
                        opacity: 100
                    }).then()
                })
            })
        } else {
            backButtonAnimation.start({
                x: '-25vw',
                opacity: 0
            }).then(() => {
                backButtonAnimation.start({
                    x: '-12.5vw',
                    opacity: 25
                }).then(() => {
                    backButtonAnimation.start({
                        x: 0,
                        opacity: 100
                    }).then()
                })
            })
        }


    }, [currentRoute.item.parent])

    useEffect(() => {
        setHeight((height ?? 0) + 32)
    }, [height]);

    const navTitle = useMemo(() => {
        if (givenNavTitle != undefined && givenNavTitle.replaceAll(' ', '') != '') {
            return givenNavTitle
        }
        return currentRoute.item.title
    }, [currentRoute.item.title, givenNavTitle]);


    const backButton = () => {

        const route = givenBackRoute ?? currentRoute.item.parent;
        if (!route) {
            return <></>
        }

        return <>
            <Link to={route.path} className={'h-6'}>

                <ChevronLeft
                    className={'text-neutral scale-[0.8] bg-white z-10 relative -left-5 pl-1 -mr-7 !w-10 pr-0 inline'}
                />
                <motion.button
                    className={'inline relative z-[11]'}
                    initial={{
                        x: !route ? '-25vw' : initialBack ? '25vw' : 0
                    }}
                    animate={backButtonAnimation}
                    transition={{duration: 0.05, type: 'tween', damping: 100}}
                >
                    <span className={'text-[0.7rem] text-neutral-700'}>{route.title}</span>
                </motion.button>
            </Link>
        </>
    }

    return (
        <nav ref={ref} className={'grid grid-cols-1'}>
            <div className={'grid grid-cols-7 h-[1.625rem]'}>
                <div className={'col-span-2'}>
                    {backButton()}
                </div>
                <div className={'col-span-3'}>
                    <h1 className={'mx-auto text-center text-[1.1rem] tracking-wide text-neutral-900 font-medium font-roboto'}>{navTitle}</h1>
                </div>
                <div className={'col-span-2 relative text-right'}>
                    {givenNextRoute && <Link to={givenNextRoute.path} className={'text-xs text-neutral-700'}>
                        {givenNextRoute.title}
                    </Link>}
                    {!givenNextRoute && <DropdownMenu>
                        <DropdownMenuTrigger
                            className={'rounded-full bg-primary text-center text-primary-accent w-8 h-8 absolute right-0 -top-1'}>
                            <span className={'absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'}>T</span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent sideOffset={12} className={'bg-white rounded-t-none rounded-br-none'}>
                            <Link to={'/logout'}>
                                <DropdownMenuItem>
                                    {__t('auth.logout')}
                                </DropdownMenuItem>
                            </Link>
                        </DropdownMenuContent>
                    </DropdownMenu>}
                </div>
            </div>
            <div
                className={'transition-all ease-in-out duration-200'}
                style={{
                    height: `${childItemMeasure.height}px`
                }}
            >
                <div
                    ref={childItemRef}
                    className={classNames('transition-all ease-in-out duration-700', children == undefined ? 'opacity-0' : 'opacity-100')}
                >
                    {children}
                </div>
            </div>
        </nav>
    );
};

export default NavbarTop;
