import React, {FC, useState} from 'react';
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {ImplementFormProps} from "@components/form/Form.component";
import {CreateSubscriptionInput, CreateSubscriptionMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import {optionsFromEnum} from "@components/form/fields/Select.component";
import {MonthParts, WeekDays} from "@app/entities/Subscription.types";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {CreatButtonInner} from "@components/button/CreateButton";


const SubscriptionCreateForm: FC<ImplementFormProps<CreateSubscriptionMutation>> = ({
    onSubmitSuccess,
}) => {

    const gql = useGql();

    // @fixme current company ID
    const companyId = useAsyncMemo(async () => {
        return await gql.GetCompanies().then(r => r.getCompanies?.[0].id ?? '')
    }, [], '')


    const fields: FormFields<Omit<CreateSubscriptionInput, 'companyId'>> = {
        title: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Subscription Name',
            description: 'Name of your subscription'
        },
        description: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'input',
            label: 'Invoice description'
        },
        vatPercentage: {
            zodType: z.enum(['0', '9', '21']).default('0'),
            fieldType: 'input',
            label: 'VAT percentage',
            description: 'Will be used for all tiers'
        },

        billingDateType: {
            zodType: z.coerce.number().min(1).max(31).step(1).default(1),
            fieldType: 'input',
            label: 'Billing date type',
            description: 'Mail where invoice is sent to'
        },
        billingDayOfWeek: {
            zodType: z.enum(Object.keys(WeekDays) as [string]),
            fieldType: 'select',
            label: 'Billing day of week',
            defaultValue: 'monday',
            colSpan: 'col-span-6',
            options: optionsFromEnum(WeekDays)
        },
        billingPartOfMonth: {
            zodType: z.enum(['first_week', 'last_week']).default('first_week'),
            fieldType: 'select',
            label: 'Billing part of month',
            colSpan: 'col-span-6',
            options: optionsFromEnum(MonthParts)
        },
        billingDayOfMonth: {
            zodType: z.coerce.number().min(1).max(31).step(1).default(1),
            fieldType: 'input',
            label: 'Billing day of month',
        }
    }

    const [open, setOpen] = useState(false)
    return (
        <>
            <PullOverForm
                title={'Subscription create test form'}
                openState={[open, setOpen]}
                trigger={{
                    title: <CreatButtonInner/>,
                    onClick: () => {
                        setOpen(true)
                    }
                }}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    submit: gql.CreateSubscription,
                    onSubmitSuccess: onSubmitSuccess,
                    fields: fields,
                    payloadInjection: {
                        companyId
                    }
                }}
            />
        </>
    );
};

export default SubscriptionCreateForm;
