import {useEffect} from 'react';
import {useAuthProvider} from "@hooks/useAuth";
import {useNavigate} from "react-router-dom";


const useAuthGuard = () => {

    const {loggedIn} = useAuthProvider()
    const navigate = useNavigate()

    useEffect(() => {
        if (loggedIn === null) {
            return;
        }

        if (!loggedIn) {
            navigate('/logout')
        }


    }, [loggedIn]);

};
export default useAuthGuard;