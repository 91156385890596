import React, {
    ComponentPropsWithRef,
    DetailedHTMLProps,
    Dispatch,
    ElementType,
    forwardRef,
    HTMLAttributes,
    JSX,
    ReactNode,
    SetStateAction,
    useEffect,
    useId,
    useRef,
    useState
} from 'react';
import {classNames} from "@modules/casting/String";
import {ForwardRefComponent} from "framer-motion";
import Description from "@components/text/Description.component";


export type ExtendAgreementProps = {
    title: string
    description?: string,
    children: ReactNode
}

type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
    & ExtendAgreementProps
    & ComponentPropsWithRef<ElementType<JSX.IntrinsicElements['div']>>
    & {
    acceptClickedState: [boolean, Dispatch<SetStateAction<boolean>>]
    setCanSubmit: Dispatch<SetStateAction<boolean>>
    setCanProceed: Dispatch<SetStateAction<boolean>>
}

const Agreement: ForwardRefComponent<HTMLDivElement, Props> = forwardRef(({
    title,
    description,
    children,
    className,
    id,
    acceptClickedState,
    setCanSubmit,
    setCanProceed,
    ...props
}, ref) => {


    const checkboxId = useId();
    const articleId = id ?? useId();
    const articleRef = useRef<HTMLElement | null>(null);

    const [acceptIsClicked, setAcceptIsClicked] = acceptClickedState
    const [agreeIsChecked, setAgreeIsChecked] = useState(false)
    const [highlightCheckbox, setHighlightCheckbox] = useState(false);

    useEffect(() => {
        articleRef.current = document.getElementById(articleId);
    }, []);


    const handleCheckboxClick = () => {
        const isAgreed = !agreeIsChecked;
        setAgreeIsChecked(!agreeIsChecked);
        setHighlightCheckbox(!isAgreed);
        setCanSubmit(isAgreed)
    };

    useEffect(() => {
        const canProceed = acceptIsClicked && agreeIsChecked;
        if (acceptIsClicked && !agreeIsChecked) {
            if (articleRef.current) {
                articleRef.current.scrollTop = articleRef.current.scrollHeight;
            }
            setHighlightCheckbox(true);
        }
        setCanProceed(canProceed)
        setAcceptIsClicked(false)
    }, [acceptIsClicked]);


    return <div ref={ref} className={'h-full'}>
        <div className="mb-4 pb-4 border-b">
            <h1 className={'font-bold text-neutral text-lg'}>{title}</h1>
            {description && <Description>{description}</Description>}
        </div>

        <article
            {...props}
            style={{...props.style}}
            id={articleId}
            className={classNames('scroll-smooth w-full overflow-y-auto', className)}
        >

            {children}
            <div className={'pt-4'}>
                <input
                    id={checkboxId}
                    className={classNames(highlightCheckbox && 'border border-red-600', 'mr-2')}
                    type={'checkbox'}
                    onClick={handleCheckboxClick}
                />
                <label
                    htmlFor={checkboxId}
                    className={classNames('text-sm', highlightCheckbox && 'text-negative-700 font-medium')}
                >
                    Ik heb alles gelezen en ik ga akkoord
                </label>
            </div>

        </article>

    </div>;
});

Agreement.displayName = 'Agreement';
export default Agreement;