import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$getSelection, $isRangeSelection, TextFormatType} from "lexical";
import React, {ComponentType, useEffect, useState} from "react";
import {FormatBold, FormatItalic, FormatStrikethrough, FormatUnderlined} from "@mui/icons-material";
import {classNames} from "@modules/casting/String";


type TextFormatItem = {
    Icon: ComponentType<{ className?: string }>,
    format: TextFormatType
}

const textFormatItems: TextFormatItem[] = [
    {
        Icon: FormatBold,
        format: 'bold'
    },
    {
        Icon: FormatItalic,
        format: 'italic'
    },
    {
        Icon: FormatUnderlined,
        format: 'underline'
    },
    {
        Icon: FormatStrikethrough,
        format: 'strikethrough'
    },

]


export function TextFormattingGroup() {


    const [editor] = useLexicalComposerContext()
    const [activeFormats, setActiveFormats] = useState<TextFormatType[]>([])


    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.getEditorState().read(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    const formats = textFormatItems
                        .map(item => item.format)
                        .filter(format => selection.hasFormat(format));
                    setActiveFormats(formats);
                }
            });
        });
    }, [editor]);


    const handleClick = (format: TextFormatType) => {

        if (activeFormats.includes(format)) {
            setActiveFormats(activeFormats.filter(aF => aF != format))
        } else {
            setActiveFormats([...activeFormats, format])
        }

        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                selection.formatText(format);
            }
        });
    }


    return (

        <div className={'flex gap-x-0.5 my-4 w-full justify-between'}>
            {textFormatItems.map((item, i) => <button
                onClick={() => handleClick(item.format)}
                key={item.format}
                className={classNames('w-full h-10 !text-xl',
                    i == 0 && 'rounded-l-lg',
                    i == textFormatItems.length - 1 && 'rounded-r-lg',
                    activeFormats.includes(item.format) ? 'bg-primary text-neutral-100' : 'bg-neutral-300 text-primary'
                )}
            >
                <item.Icon className={'!text-3xl'}/>
            </button>)}
        </div>
    )
}