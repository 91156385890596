import React from 'react';
import './LoadingDots.css';

const LoadingDots = () => {
    return (
        <span className={'loading-dots'}>...</span>
    );
};

export default LoadingDots;
