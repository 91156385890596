import React, {useMemo} from 'react';
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import LoadingDots from "@components/feedback/LoadingDots/LoadingDots.component";
import {Navigate, useLocation} from "react-router-dom";
import useAuth from "@hooks/useAuth";

const AuthorizePage = () => {

    const {hash, search, state, key} = useLocation()

    const {loggedIn, hasMfa, otpVerified} = useAuth();

    const target = useMemo(() => {
        if (loggedIn === null) {
            return null
        }

        if (!loggedIn) {
            if (hasMfa && !otpVerified) {
                return '/otp'
            }
            return '/logout'
        }

        return '/payments'
    }, [loggedIn]);


    return (
        <>
            <LoadingSpinner/>
            <h1 className={'absolute h-12 text-lg text-primary font-medium text-center left-0 right-0 pl-8 translate-y-[calc(33.333vh+4rem)]'}>
                Authorizing<LoadingDots/>
            </h1>

            {target !== null && <Navigate
                to={{pathname: target, hash, search}}
                state={state}
                replace={true}
                key={key}
            />}
        </>
    );
};

export default AuthorizePage;
