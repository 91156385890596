import {useEffect, useRef, useState} from "react";

type Props = {
    offsetTop?: number,
    offsetBottom?: number,
    trueIfNoScroll?: boolean
}

const useScrollSpy = <T extends HTMLElement = HTMLElement>({
    offsetTop = 0,
    offsetBottom = 0,
    trueIfNoScroll = true
}: Props = {}) => {

    const scrollRef = useRef<T>(null)
    const [isBottom, setIsBottom] = useState(false);
    const [isTop, setIsTop] = useState(false)

    useEffect(() => {

        const scrollHeight = scrollRef.current?.scrollHeight ?? 0
        const clientHeight = scrollRef.current?.clientHeight ?? 0
        const scrollTopRaw = scrollRef.current?.scrollTop ?? 0

        if (scrollHeight === clientHeight) {
            setIsTop(trueIfNoScroll)
            setIsBottom(trueIfNoScroll)
            return;
        }

        const elementHeightRaw = scrollHeight - clientHeight;
        const scrollTopRemainder = scrollTopRaw % 1;

        const elementHeight = Math.floor(elementHeightRaw) + scrollTopRemainder
        const scrollTop = Math.floor(scrollTopRaw) + scrollTopRemainder;

        setIsTop(scrollTop <= 0 + offsetTop)
        setIsBottom(scrollTop >= elementHeight - offsetBottom)
    }, [scrollRef.current?.scrollTop, scrollRef.current?.clientHeight]);

    return {
        scrollRef,
        isTop,
        isBottom
    }

}

export default useScrollSpy