import React, {useEffect} from 'react';
import useAuth from "@hooks/useAuth";
import GuestPageTemplate from "@templates/page/GuestPage.template";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";

const LogoutPage = () => {

    const auth = useAuth()
    useEffect(() => {
        auth.logout()
    }, [])

    return (
        <GuestPageTemplate pageTitle={'logout'}>
            <LoadingSpinner/>
        </GuestPageTemplate>
    );
};

export default LogoutPage;
