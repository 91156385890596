import {RadioGroup, RadioGroupItem} from "@src/@/components/ui/radio-group";
import {Label} from "@src/@/components/ui/label";
import {Checkbox} from "@src/@/components/ui/checkbox";
import React, {Dispatch, SetStateAction} from "react";

const conditions = [
    {
        name: 'Immediately',
        description: 'Change Immediately'
    },
    {
        name: 'Next Invoice',
        description: 'Change after next invoice'
    }
]

interface SubscriptionPeriodProps {
    condition: string
    setCondition: Dispatch<SetStateAction<string>>
}

export function SubscriptionPeriodSection({condition, setCondition}: SubscriptionPeriodProps) {
    return (
        <RadioGroup value={condition} onValueChange={setCondition} className={'flex flex-col gap-y-4'}>
            {conditions.map(item => (
                <div key={item.name}>
                    <RadioGroupItem value={item.name} id={item.name} className="peer hidden"/>
                    <Label htmlFor={item.name}
                        className='px-4 py-2 outline-1 outline outline-neutral-300 font-medium rounded-md flex items-center
                             justify-between peer-data-[state=checked]:outline-2 peer-data-[state=checked]:outline-primary-800'
                    >
                        {item.description} <Checkbox
                            checked={item.name === condition}
                            onCheckedChange={(checked) => {
                                if (checked) {
                                    setCondition(item.name);
                                }
                            }}
                            id={item.name}
                        />
                    </Label>
                </div>
            ))}
        </RadioGroup>
    )
}