import React, {Dispatch, FC, SetStateAction} from "react";
import {Input} from "@src/@/components/ui/input";
import {Label} from "@src/@/components/ui/label";

type Props = {
    file?: string;
    setFile: Dispatch<SetStateAction<string>>;
}

const InputLogoComponent: FC<Props> = ({file, setFile}) => {


    function handleInputFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const uploadedFile = e.target.files?.[0];

        if (uploadedFile) {
            const fileUrl = URL.createObjectURL(uploadedFile);

            setFile(fileUrl);
        }
    }

    return (
        <div className={''}>
            <Label htmlFor={'upload'} className={'text-base font-semibold font-roboto'}>Upload your Logo!</Label>
            <Input
                type={'file'}
                id={'upload'}
                accept={'image/*'}
                className={'cursor-pointer flex items-center h-auto py-2 px-4'}
                onChange={handleInputFileChange}
            />
            {
                file && <img src={file} alt='Logo' className={'h-60 object-scale-down object-top'}/>}
        </div>
    )
}

export default InputLogoComponent