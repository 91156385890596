import React, {FC, ReactNode} from 'react';
import {classNames} from "@modules/casting/String";
import {ColorVariations} from "@app/types/ColorVariations";
import {IconType} from "@app/types/Icons";


export type BadgeVariation = ColorVariations

export type BadgeType = {
    title: ReactNode,
    Icon?: IconType
    variation?: BadgeVariation,
}
type Props = BadgeType & {
    className?: string
}

const Badge: FC<Props> = ({
    title,
    Icon,
    variation = 'primary',
    className
}) => {
    return (
        <div className={classNames(
            'px-2 py-1 rounded-lg w-fit text-[0.6rem] font-medium flex justify-center items-center',
            variation == 'primary' && 'bg-primary text-primary-100',
            variation == 'secondary' && 'bg-secondary-500 text-secondary-100',
            variation == 'negative' && 'bg-negative-600 text-negative-100',
            variation == 'positive' && 'bg-positive-800 text-positive-100',
            variation == 'neutral' && 'bg-neutral-900 text-neutral-100',
            variation == 'warning' && 'bg-orange-600 text-orange-100',
            Icon && 'gap-x-1',
            className
        )}>
            {Icon && <Icon style={{
                width: 12,
                height: 12
            }}/>}
            <span>
                {title}
            </span>
        </div>
    );
};

export default Badge;
