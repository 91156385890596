import React, {ReactNode, useMemo} from 'react';
import {
    Select as ShadCnSelect,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@src/@/components/ui/select";
import {FormControl} from "@src/@/components/ui/form";
import {ExtendFormFieldProps} from "@components/form/fields/FormField.component";

/**
 * Option item for the select field.
 */
export type SelectOption<T> = {
    title: ReactNode,
    value: T extends number ? number
        : T extends string ? string
            : null
}

export const optionsFromEnum = <T extends object>(enumerator: T): SelectOption<string>[] => Object
    .keys(enumerator)
    .map(k => ({
        title: enumerator[k as keyof T] as string,
        value: k
    }));

type Props<T> = ExtendFormFieldProps<T> & {
    options: SelectOption<T>[]
}

const Select = <T = string | number | undefined>({
    name,
    options,
    placeholder,
    field
}: Props<T>) => {


    const placeholderDisplayValue = useMemo(() => {
        return options.find(opt => opt.value == field.value)?.title ?? placeholder ?? ''
    }, [field.value, placeholder, options])

    return (
        <ShadCnSelect name={name} onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
                <SelectTrigger>
                    <SelectValue placeholder={placeholderDisplayValue}/>
                </SelectTrigger>
            </FormControl>
            <SelectContent className={'bg-white'}>
                {options.map(option =>
                    <SelectItem key={`${option.value}`} value={`${option.value}`}>{option.title}</SelectItem>
                )}
            </SelectContent>
        </ShadCnSelect>
    );
}
export default Select