import React from 'react';

export function ShopIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
                d="M2.5 10V13C2.5 16.771 2.5 18.657 3.672 19.828C4.843 21 6.729 21 10.5 21H11.5C15.271 21 17.157 21 18.328 19.828C19.5 18.657 19.5 16.771 19.5 13V10"
                stroke="#0B2C60" strokeWidth="1.5"/>
            <path
                d="M8.50001 1H13.5L14.152 7.517C14.195 7.95677 14.1454 8.40065 14.0066 8.82013C13.8677 9.2396 13.6426 9.62537 13.3457 9.95265C13.0489 10.2799 12.6868 10.5415 12.2828 10.7204C11.8788 10.8994 11.4419 10.9919 11 10.9919C10.5582 10.9919 10.1212 10.8994 9.71719 10.7204C9.31321 10.5415 8.95114 10.2799 8.65428 9.95265C8.35741 9.62537 8.13231 9.2396 7.99346 8.82013C7.8546 8.40065 7.80505 7.95677 7.84801 7.517L8.50001 1Z"
                stroke="#0B2C60" strokeWidth="1.5"/>
            <path
                d="M2.33002 4.351C2.50802 3.461 2.59702 3.016 2.77802 2.655C2.96662 2.27904 3.23217 1.94694 3.55743 1.68025C3.88269 1.41356 4.26039 1.21826 4.66602 1.107C5.05602 1 5.51002 1 6.41802 1H8.50002L7.77502 8.245C7.73676 8.65963 7.61428 9.06209 7.41508 9.42774C7.21588 9.79339 6.94414 10.1145 6.61651 10.3715C6.28888 10.6285 5.91225 10.8159 5.50968 10.9223C5.1071 11.0287 4.68706 11.0518 4.27525 10.9901C3.86344 10.9285 3.46853 10.7835 3.11471 10.564C2.76089 10.3445 2.45559 10.0551 2.21751 9.71345C1.97943 9.37184 1.81357 8.98523 1.73009 8.5773C1.64662 8.16936 1.64727 7.74867 1.73202 7.341L2.33002 4.351ZM19.67 4.351C19.492 3.461 19.403 3.016 19.222 2.655C19.0334 2.27904 18.7679 1.94694 18.4426 1.68025C18.1174 1.41356 17.7397 1.21826 17.334 1.107C16.944 1 16.49 1 15.582 1H13.5L14.225 8.245C14.2633 8.65963 14.3858 9.06209 14.585 9.42774C14.7842 9.79339 15.0559 10.1145 15.3835 10.3715C15.7112 10.6285 16.0878 10.8159 16.4904 10.9223C16.8929 11.0287 17.313 11.0518 17.7248 10.9901C18.1366 10.9285 18.5315 10.7835 18.8853 10.564C19.2392 10.3445 19.5445 10.0551 19.7825 9.71345C20.0206 9.37184 20.1865 8.98523 20.27 8.5773C20.3534 8.16936 20.3528 7.74867 20.268 7.341L19.67 4.351Z"
                stroke="#0B2C60" strokeWidth="1.5"/>
            <path
                d="M8.5 20.5V17.5C8.5 16.565 8.5 16.098 8.701 15.75C8.83265 15.522 9.02199 15.3326 9.25 15.201C9.598 15 10.065 15 11 15C11.935 15 12.402 15 12.75 15.201C12.978 15.3326 13.1674 15.522 13.299 15.75C13.5 16.098 13.5 16.565 13.5 17.5V20.5"
                stroke="#0B2C60" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}