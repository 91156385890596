import React from 'react';

const CompanyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
            <path
                d="M8 27.5V5C8 4.6625 8 4.3125 8.0875 3.975C8.16401 3.63828 8.33341 3.32977 8.57647 3.08451C8.81952 2.83924 9.12649 2.66705 9.4625 2.5875C9.825 2.5 11.4125 2.5 11.75 2.5H20.5C20.8375 2.5 21.1875 2.5 21.525 2.5875C21.8617 2.66401 22.1702 2.83341 22.4155 3.07647C22.6608 3.31952 22.8329 3.62649 22.9125 3.9625C23 4.3125 23 4.6625 23 5V27.5H8Z"
                stroke="#1E293B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M3 17.5V25C3 26.375 4.125 27.5 5.5 27.5H8V15H5.5C5.1625 15 4.8125 15 4.475 15.0875C4.1375 15.175 3.825 15.3375 3.575 15.5875C3.3375 15.825 3.175 16.1375 3.0875 16.475C3.01694 16.8119 2.98756 17.1561 3 17.5Z"
                stroke="#1E293B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M26.525 11.3375C26.1881 11.2669 25.8439 11.2376 25.5 11.25H23V27.5H25.5C26.163 27.5 26.7989 27.2366 27.2678 26.7678C27.7366 26.2989 28 25.663 28 25V13.75C28 13.4 28 13.0625 27.9125 12.725C27.825 12.3875 27.6625 12.075 27.4125 11.825C27.175 11.5875 26.8625 11.425 26.525 11.3375Z"
                stroke="#1E293B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M13 7.5H18"
                stroke="#1E293B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 12.5H18"
                stroke="#1E293B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 17.5H18"
                stroke="#1E293B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 22.5H18"
                stroke="#1E293B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CompanyIcon;
