import ActionButton from "@components/button/ActionButton";
import {ChevronRight} from "@mui/icons-material";
import React from "react";

export function CreateInviteLink() {
    return (
        <div
            className={'bg-white -mx-shell px-shell py-3 border-t border-t-neutral-300/80 fixed bottom-0 w-full transition-all ease-in-out duration-300'}>
            <ActionButton className={'flex !justify-between items-center'}>
                <p>Create Invite Link</p>
                <ChevronRight/>
            </ActionButton>
        </div>
    )
}