import BalanceDifferenceIcon from "@components/icon/BalanceDifferenceIcon";
import ChartTab, {ChartTabItem} from "@components/chart/ChartTab";
import {LineChart} from "@components/chart/Chart";
import React, {useMemo, useRef, useState} from "react";
import {Chart as ChartJS, ChartData} from "chart.js";
import {getChartDataSet} from "@components/chart/ChartData";
import {dummySubscriberChartData, dummySubscriberData} from "@app/mock/MockSubscriberData";
import moment from "moment/moment";
import {toPercentageString} from "@modules/casting/Number";
import {classNames} from "@modules/casting/String";

const SubscriberChart = () => {

    const chartRef = useRef<ChartJS>(null);

    const tabs = useMemo((): ChartTabItem<number>[] => [
        {
            title: '3M',
            value: 3
        },
        {
            title: '6M',
            value: 6
        },
        {
            title: '12M',
            value: 12
        }
    ], []);

    const tabState = useState<ChartTabItem<number> | undefined>(tabs[1])
    const currentTab = tabState[0]

    const {currentAmount, prevAmount, percentageChanged} = useMemo(() => {
        const prev = currentTab?.value ?? 0
        return dummySubscriberData(moment('2024-07-01').subtract(prev > 0 ? prev - 1 : 0, 'months').format('YYYY-MM'))
    }, [currentTab]);

    const subscriberChartData = useMemo(() => {
        return dummySubscriberChartData();
    }, []);

    const filteredData = useMemo(() => {
        return subscriberChartData.slice(-(currentTab?.value ?? 0));
    }, [currentTab, subscriberChartData]);

    const chartData = useMemo((): ChartData<'line'> => {
        return getChartDataSet(filteredData);
    }, [chartRef.current, filteredData]);

    const changeIsPositive = useMemo(() => {
        return percentageChanged >= 0
    }, [percentageChanged]);

    return (
        <div>
            <p className={'font-medium font-roboto text-sm text-neutral'}>Current Subscribers</p>
            <div className={'flex items-center gap-x-3 mt-2'}>
                <p className={'text-2xl font-semibold text-neutral'}>{currentAmount - prevAmount}</p>
                <div className={classNames('flex gap-x-1.5 font-semibold',
                    changeIsPositive ? 'text-positive-800' : 'text-negative-700'
                )}>
                    <BalanceDifferenceIcon isPositive={changeIsPositive}/>
                    <span>{changeIsPositive && '+'}{toPercentageString(percentageChanged)}</span>
                </div>
            </div>
            <div className={'-mt-8'}>
                <ChartTab tabState={tabState} tabs={tabs}/>
                <div className={'relative w-full h-[10rem] overflow-hidden -mt-6'}>
                    <LineChart
                        data={chartData}
                        ref={chartRef}
                        className={'!h-[10rem] absolute left-[-1.8rem] !w-[calc(100vw+1rem)]'}
                    />
                </div>
            </div>
        </div>
    )
}

export default SubscriberChart