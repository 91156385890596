import {Transition, Variants} from "framer-motion";

export const FramerVariants: Variants = {

    slideAnimation: {
        x: 0,
        position: 'absolute'
    },

    slideOverAnimation: {
        x: 0,
        zIndex: 1,
        position: 'absolute'
    },

    none: {
        x: '0.0000001mm', // width of an atom, ZERO will omit the animation
        position: 'absolute'
    },

    slideLeft: {
        x: '-100vw',
        position: 'absolute'
    },

    slideRight: {
        x: '100vw',
        position: 'absolute'
    },

}

export const FramerTransitions: { [k: string]: Transition } = {
    slide: {
        type: "tween",
        damping: 10,
        stiffness: 100
    }
};