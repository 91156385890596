import React, {FC} from 'react';
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {ImplementFormPropsWithId} from "@components/form/Form.component";
import {UpdateSubscriptionInput, UpdateSubscriptionMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import {MonthParts, WeekDays} from "@app/entities/Subscription.types";
import {optionsFromEnum} from "@components/form/fields/Select.component";
import useGql from "@modules/graphql/useGql";


type Props = ImplementFormPropsWithId<UpdateSubscriptionMutation> & {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const SubscriptionUpdateForm: FC<Props> = ({
    id,
    onSubmitSuccess,
    openState
}) => {


    const gql = useGql();

    const fields: FormFields<UpdateSubscriptionInput> = {
        title: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Subscription Name',
            description: 'Name of your subscription'
        },
        description: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'textarea',
            label: 'Invoice description'
        },
        vatPercentage: {
            zodType: z.enum(['0', '9', '21']).default('0'),
            fieldType: 'input',
            label: 'VAT percentage',
            description: 'Will be used for all tiers'
        },
        billingDateType: {
            zodType: z.number().min(1).max(31).step(1).default(1),
            fieldType: 'input',
            label: 'Billing date type',
            description: 'Mail where invoice is sent to'
        },
        billingDayOfWeek: {
            zodType: z.enum(Object.keys(WeekDays) as [string]),
            fieldType: 'select',
            label: 'Billing day of week',
            // defaultValue: 'monday',
            colSpan: 'col-span-6',
            options: optionsFromEnum(WeekDays)
        },
        billingPartOfMonth: {
            zodType: z.enum(['first_week', 'last_week']).default('first_week'),
            fieldType: 'select',
            label: 'Billing part of month',
            colSpan: 'col-span-6',
            options: optionsFromEnum(MonthParts)
        },
        billingDayOfMonth: {
            zodType: z.number().min(1).max(31).step(1).default(1),
            fieldType: 'input',
            label: 'Billing day of month',
        }
    }

    return (
        <>
            <PullOverForm
                title={'Subscription edit test form'}
                openState={openState}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    id: id,
                    getData: async () => await gql.GetSubscriptionForm({id}).then(r => r.getSubscription),
                    submit: gql.UpdateSubscription,
                    onSubmitSuccess: onSubmitSuccess,
                    fields: fields,
                    payloadInjection: {}
                }}
            />
        </>
    );
};

export default SubscriptionUpdateForm;
