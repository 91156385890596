import React from 'react';
import {CreatePaymentLinkPullover} from "@pages/merchant/payment/index/components/CreatePaymentLinkPullover";

const CreatePaymentLink = () => {
    return (<>
        <div
            className={'bg-white -mx-shell px-shell py-3 border-t border-t-neutral-300/80 fixed bottom-0 w-full transition-all ease-in-out duration-300'}>
            <CreatePaymentLinkPullover/>
        </div>
    </>);
};

export default CreatePaymentLink;
