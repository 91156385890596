import React, {FC, ReactNode} from 'react';
import {classNames} from "@modules/casting/String";

type Props = {
    children: ReactNode,
    className?: string,
    onClick?: () => void
}
const Card: FC<Props> = ({children, className, onClick}) => {
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div role={onClick ? 'button' : 'none'}
            onClick={onClick}
            className={classNames('bg-white border border-neutral-400/75 shadow-sm p-5 rounded-lg', className)}
        >
            {children}
        </div>
    );
};

export default Card;
