import React from "react";
import {FieldErrors} from "react-hook-form";
import {FormSchema} from "@pages/merchant/onboarding/pages/CreateSubscriptionFormPullOverScreen";
import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "@src/@/components/ui/form";


interface TextInputProps {
    name: string;
    errors: FieldErrors<FormSchema>
}

export function FormTextInput({name, errors}: TextInputProps) {


    return (
        <FormField name={name} render={({field}) => {
            return (
                <FormItem className={'flex flex-col space-y-1'}>
                    <FormLabel className={'capitalize'}>{name}</FormLabel>
                    <FormControl>
                        {name === 'title' ?
                            < input placeholder={name} {...field} className={'rounded-md py-1 px-2' +
                                ' border placeholder:capitalize'}/> : <textarea rows={5} placeholder={name} {...field}
                                className={'border rounded-md py-1 px-2 placeholder:capitalize'}/>
                        }
                    </FormControl>
                    <FormMessage>{errors.title?.message}</FormMessage>
                </FormItem>
            )
        }}/>
    )
}