import React from 'react';

const UserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 37 36" fill="none">
            <path
                d="M29 31.5V28.5C29 26.9087 28.3679 25.3826 27.2426 24.2574C26.1174 23.1321 24.5913 22.5 23 22.5H14C12.4087 22.5 10.8826 23.1321 9.75736 24.2574C8.63214 25.3826 8 26.9087 8 28.5V31.5"
                stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M18.5 16.5C21.8137 16.5 24.5 13.8137 24.5 10.5C24.5 7.18629 21.8137 4.5 18.5 4.5C15.1863 4.5 12.5 7.18629 12.5 10.5C12.5 13.8137 15.1863 16.5 18.5 16.5Z"
                stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default UserIcon;
