import React, {useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {Edit} from "lucide-react";
import {EditCompanyUserPulloverScreen} from "@pages/merchant/settings/company/components/EditCompanyUserPulloverScreen";
import {CompanyUser} from "@app/mock/MockCompanyData";

interface EditCompanyUserPulloverProps {
    user: CompanyUser | undefined
    changeUserRole: (userId: string, role: string, isChecked: boolean | 'indeterminate') => void
}

export function EditCompanyUserPullover({user, changeUserRole}: EditCompanyUserPulloverProps) {
    const [showEditCompanyUser, setShowEditCompanyUser] = useState(false);

    return (
        <PullOver
            variations={'max-height'}
            openState={[showEditCompanyUser, setShowEditCompanyUser]}
            title={'Edit User'}
            submit={{
                title: 'Save',
                onClick: () => setShowEditCompanyUser(false)
            }}
            trigger={{
                title: <Edit size={16} className={'hover:cursor-pointer'}/>,
                variation: 'link',
                onClick: () => setShowEditCompanyUser(true)
            }}
        >
            <EditCompanyUserPulloverScreen user={user} changeUserRole={changeUserRole}/>
        </PullOver>
    )
}