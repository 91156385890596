import React, {useEffect, useMemo, useState} from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import {mockPaymentData,} from "@app/mock/MockPaymentData";
import useParamId from "@hooks/useParamId";
import {CustomerDetails, PaymentDetails} from "@pages/merchant/payment/show/components/PaymentDetails";
import {CustomerPaymentHistory} from "@pages/merchant/payment/show/components/CustomerPaymentHistory";

const PaymentShowPage = () => {
    /**
     *
     * Temp for visual purposes
     *
     * */
    const [currentCustomer, setCurrentCustomer] = useState<CustomerDetails | undefined>();


    const params = useParamId()

    const customer = useMemo(() => {
        const customerFinder = mockPaymentData.find(data => data.id === params)
        if (customerFinder) {
            return {
                ...customerFinder,
                symbol: customerFinder.title ? customerFinder?.title[0].concat(customerFinder?.title.split(' ')[1][0]) : undefined
            }
        }

        return undefined
    }, [params])

    useEffect(() => {
        setCurrentCustomer(customer)
    }, [customer]);

    const customerHistory = useMemo(() => {
        return mockPaymentData.filter(data => data.title === customer?.title)
    }, [])

    return (
        <ShowPageTemplate back={{
            title: 'Payment List',
            path: '/payments/list'

        }}>
            <PaymentDetails customer={currentCustomer} setCurrentCustomer={setCurrentCustomer}/>
            <CustomerPaymentHistory customer={currentCustomer} customerHistory={customerHistory}/>
        </ShowPageTemplate>

    );
};

export default PaymentShowPage;
