import {Header} from "@pages/merchant/subscriber/show/components/Header";
import {InfoBanner} from "@pages/merchant/subscriber/show/components/InfoBanner";
import {TierSelectSection} from "@pages/merchant/subscriber/components/TierSelectSection";
import {SubscriptionPeriodSection} from "@pages/merchant/subscriber/show/components/SubscriptionPeriodSection";
import React, {useState} from "react";

export function EditSubscriptionPulloverContent() {
    const [condition, setCondition] = useState('Immediately')

    return (
        <div className={'space-y-8 mb-2'}>
            <Header/>
            <InfoBanner/>
            <TierSelectSection/>
            <SubscriptionPeriodSection condition={condition} setCondition={setCondition}/>
        </div>
    )
}