import {SortByDate} from './Date';
import moment from "moment/moment";

export type HashKey = string | number
export type HashMap<K extends HashKey, T> = {
    // eslint-disable-next-line no-unused-vars
    [k in K]: T;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type ReflectKeysOf<T, Prefix extends string = never> =
    T extends string | number | bigint | boolean
        | null | undefined | ((...args: (string | number | bigint | boolean)[]) => string | number | bigint | boolean) ? never : {
        [K in keyof T & string]: [Prefix] extends [never]
            ? K | `['${K}']` | ReflectKeysOf<T[K], K>
            : `${Prefix}.${K}` | `${Prefix}['${K}']` | ReflectKeysOf<T[K], `${Prefix}.${K}` | `${Prefix}['${K}']`>
    }[keyof T & string];


type TParsed = { [k: string]: string | number | bigint | boolean };
/**
 * Check if two objects are the same.
 *
 * @param prevState
 * @param newState
 */
export const ObjectIsChanged = (prevState: object, newState: object): boolean => {
    const orderedObject = (obj: {
        [k: string]: string | number | bigint | boolean
    }) => Object.keys(obj).sort().reduce((prev, key) => ({
        ...prev, [key]: obj[key]
    }), {});
    return JSON.stringify(orderedObject(prevState as TParsed)) != JSON.stringify(orderedObject(newState as TParsed));
};

/**
 * Object key type for sorting
 */
type KeyType = 'string' | 'number' | 'date';

/**
 * Sort object by its keys.
 *
 * Supported date formats: "Y-m-d" | "Y-m-d H:i:s"
 *
 * @example keyType = 'string', direction = 'ASC' -> 'Albert', 'Bernard', ...
 * @example keyType = 'number', direction = 'DESC' -> 3, 2, 1, ...
 * @example keyType = 'date', direction = 'ASC' -> '2023-01-01', '2023-01-02', ...
 * @example keyType = 'date', direction = 'DESC' -> '2023-01-01 15:00:00', '2023-01-01 03:00:00', ...
 *
 * @param {T extends object} obj
 * @param {KeyType} keyType
 * @param {'ASC'|'DESC'} direction
 * @constructor
 */
export const SortObjectKeys = <T extends object>(
    obj: T,
    keyType: KeyType,
    direction: 'ASC' | 'DESC' = 'ASC'
): T => {
    const castedObject = obj as HashMap<HashKey, string | number | boolean | null>;
    return Object.keys(castedObject)
        .sort((a, b) => {
            const first = direction == 'ASC' ? a : b;
            const second = direction == 'ASC' ? b : a;

            if (keyType == 'number') {
                return a < b ? 1 : -1;
            }
            return keyType == 'date'
                // @todo determine if SortByDate ASC/DESC is set properly
                ? SortByDate(direction == 'ASC' ? second : first, direction == 'ASC' ? first : second, direction)
                : first.localeCompare(second);
        })
        .reduce((prev, key) => ({...prev, [key]: castedObject[key as HashKey]}), {}) as T;
};


type GroupObjectsByDateConfig = {
    groupType?: 'month' | 'day',
    sort?: 'ASC' | 'DESC'
}
export const groupObjectsByDate = <T extends object>(items: T[], datePropKey: keyof T, config?: GroupObjectsByDateConfig): HashMap<string, T[]> => {

    const {
        groupType,
        sort
    } = {
        groupType: 'day',
        sort: 'DESC',
        ...config
    } satisfies GroupObjectsByDateConfig;

    type GroupMap = HashMap<string, T[]>;
    const dateKeyFormat = groupType == 'month' ? 'YYYY-MM' : 'YYYY-MM-DD';

    /**
     * Group items by date, add to list if exist else create list.
     */
    const map = items.reduce((acc, item) => {
        const dateStr = item[datePropKey] as string;
        const momentDate = moment(dateStr, [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD", "YYYY-MM HH:mm:ss", "YYYY-MM"], true);

        if (!momentDate.isValid()) {
            console.warn('Invalid date supplied to function: ' + dateStr);

            return acc
        }

        const updatedDateStr = momentDate.format('YYYY-MM-DD HH:mm:ss');

        const groupKey = moment(updatedDateStr).format(dateKeyFormat);

        if (acc[groupKey] !== undefined) {
            acc[groupKey].push(item);
        } else {
            acc[groupKey] = [item];
        }

        return acc;
    }, {} as GroupMap);


    /**
     * Sort keys in given order.
     * @param a
     * @param b
     */
    const sortItems = (a: T, b: T) => {
        let first = a;
        let second = b;
        if (sort == 'DESC') {
            first = b;
            second = a
        }
        return moment(first[datePropKey] as string).diff(moment(second[datePropKey] as string))
    }

    /**
     * Sort set items in set in given order
     */
    const sortedSet = Object.keys(map).reduce((acc, groupKey): GroupMap => ({
        ...acc,
        [groupKey]: map[groupKey].sort(sortItems) satisfies T[]
    }), {} as GroupMap);

    return SortObjectKeys(sortedSet, 'date', sort)
}