import React, {FC} from 'react';
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {ImplementFormPropsWithId} from "@components/form/Form.component";
import {FormFields} from "@components/form/fields/FormField.component";
import useGql from "@modules/graphql/useGql";
import {UpdateCompanyInput, UpdateCompanyMutation} from "@src/gql-schema";

const CompanyUpdateTestForm: FC<ImplementFormPropsWithId<UpdateCompanyMutation>> = ({
    id,
    onSubmitSuccess
}) => {

    const gql = useGql();

    const formFields: FormFields<UpdateCompanyInput> = {
        name: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Company Name',
            description: 'Name of your company'
        },
        invoiceEmail: {
            zodType: z.string().min(1).max(64).email(),
            fieldType: 'input',
            label: 'Invoice mail',
            description: 'Mail where invoice is sent to'
        },
        invoiceRecipient: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'input',
            label: 'Invoice Recipient',
            description: 'Name of invoice recipient'
        }
    }

    return (
        <>
            <PullOverForm
                title={'Company test form'}
                trigger={{
                    title: 'Update'
                }}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    id,
                    getData: async () => await gql.GetCompanyForm({id})
                        .then(r => r.getCompany),
                    submit: gql.UpdateCompany,
                    onSubmitSuccess: onSubmitSuccess,
                    fields: formFields,
                    payloadInjection: {}
                }}
            />
        </>
    );
};

export default CompanyUpdateTestForm;
