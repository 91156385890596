import React from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import SuccessIcon from "@components/icon/success.icon";
import PrimaryButton from "@components/button/PrimaryButton";
import Confetti from 'react-confetti'
import {Link} from "react-router-dom";

const MfaSetupVerifyOtpSuccessPage = () => {
    return (
        <ShowPageTemplate>
            <div className={'flex flex-col items-center gap-y-12 translate-y-[calc(50vh-14rem)]'}>
                <SuccessIcon/>
                <p className={'text-xl font-semibold'}>MFA Successfully Enabled</p>
                <Link to={'/settings'}>
                    <PrimaryButton>Return back to settings</PrimaryButton>
                </Link>
            </div>
            <Confetti
                recycle={false}
                width={window.screen.width}
                height={window.screen.height}
            />
        </ShowPageTemplate>
    );
};

export default MfaSetupVerifyOtpSuccessPage;
