import {useEffect, useState} from 'react';
import {useBlocker} from "react-router-dom";
import {FramerTransitions, FramerVariants} from "@modules/framer/framer.variables";
import {getRouteItem, useCurrentRoute} from "@modules/routing/useRouter";
import {useHistory} from "@modules/routing/useHistory";


const usePageTransition = () => {
    const currentRoute = useCurrentRoute();
    const history = useHistory();

    type AnimationFlow = {
        initial: string,
        animate: string,
        exit: string
    }
    const [transitionReady, setTransitionReady] = useState(false)
    const [willTransition, setWillTransition] = useState(false)

    const [animationFlow, setAnimationFlow] = useState<AnimationFlow>({
        initial: 'none',
        animate: 'none',
        exit: 'none'
    })

    useEffect(() => {
        const currentRouteItem = currentRoute.item;
        const currentRouteIndex = currentRoute.index;
        const currentRouteIsIndex = currentRouteItem.parent == null;

        let initial = animationFlow.initial;
        let animate = animationFlow.animate;
        const exit = animationFlow.exit

        if (history.length > 1) {
            const prevPath = history[history.length - 2];
            const prevRoute = getRouteItem(prevPath);
            const prevRouteItem = prevRoute.item
            const prevRouteIndex = prevRoute.index

            const prevRouteIsIndex = prevRouteItem.parent == null;
            const prevRouteIsParent = currentRouteItem.path.includes(prevRouteItem.path)
            const prevRouteIsChild = prevRouteItem.path.includes(currentRouteItem.path)
            if (currentRouteIsIndex) {
                if (prevRouteIsIndex) {
                    initial = currentRouteIndex < prevRouteIndex ? 'slideLeft' : 'slideRight'
                } else if (prevRouteIsChild) {
                    initial = 'none'
                }
            }

            if (prevRouteIsParent) {
                animate = 'slideOverAnimation';
                initial = 'slideRight'
            }
        }

        setAnimationFlow({
            initial,
            animate,
            exit,
        })

        setTransitionReady(true)
    }, []);

    /**
     * Exit animation interceptor
     */
    const blocker = useBlocker(
        ({currentLocation, nextLocation}) => {
            if (currentLocation.pathname == nextLocation.pathname) {
                return true
            }

            const currentRouteItem = currentRoute.item;
            const currentRouteIndex = currentRoute.index;
            const currentRouteIsIndex = currentRouteItem.parent == null;

            const nextRoute = getRouteItem(nextLocation.pathname);
            const nextRouteItem = nextRoute.item
            const nextRouteIndex = nextRoute.index

            const nextRouteIsIndex = nextRouteItem.parent == null;
            const nextRouteIsParent = currentRouteItem.path.includes(nextRouteItem.path)
            const nextRouteIsChild = nextRouteItem.path.includes(currentRouteItem.path)

            let exitAnimation = 'none';
            let exitSlide = 'slideAnimation';

            if (currentRouteIsIndex) {
                if (nextRouteIsIndex) {
                    if (currentRouteIndex < nextRouteIndex) {
                        exitSlide = 'slideLeft'
                    } else {
                        exitSlide = 'slideRight'
                    }
                } else if (nextRouteIsChild) {
                    exitAnimation = 'none'
                }
            }

            if (nextRouteIsParent) {
                exitAnimation = 'slideOverAnimation';
                exitSlide = 'slideRight'
            }

            const animation = {
                ...animationFlow,
                animate: exitAnimation,
                exit: exitSlide
            }
            setAnimationFlow(animation)

            setWillTransition(true)
            return true
        }
    );


    const [isTransitioned, setIsTransitioned] = useState(true)
    useEffect(() => {
        if (!willTransition && !transitionReady && !isTransitioned) {
            setTransitionReady(false)
            setIsTransitioned(true)
            return
        }
        if (!transitionReady && !isTransitioned && willTransition) {
            setIsTransitioned(true)
        }
    }, [transitionReady]);

    useEffect(() => {
        if (willTransition && isTransitioned) {
            setIsTransitioned(false)
            blocker.proceed?.();
        }
    }, [willTransition, isTransitioned]);


    return {
        transitionReady,
        setIsTransitioned: () => setTransitionReady(false),
        initial: animationFlow.initial,
        animate: animationFlow.animate,
        exit: animationFlow.exit,
        variants: FramerVariants,
        transition: FramerTransitions.slide
    }
};

export default usePageTransition;
