import React, {useEffect} from 'react';
import {motion, useAnimationControls} from "framer-motion";
import NavbarBottom from "@components/navigation/NavbarBottom/NavbarBottom.component";
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import {useIsFirstRender} from "@uidotdev/usehooks";
import {classNames} from "@modules/casting/String";

const Footer = () => {
    const navbarBottomCtx = useNavbarBottom();
    const [hideNavbarBottom] = navbarBottomCtx.hide
    const footerAnimation = useAnimationControls()
    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        footerAnimation.start({
            translateY: !hideNavbarBottom ? '0%' : '100%'
        })
    }, [hideNavbarBottom]);

    return (
        <motion.footer
            animate={footerAnimation}
            transition={{duration: hideNavbarBottom ? 0.25 : 0.3}}
            className={classNames(
                'bg-white px-shell pt-1 pb-4 fixed bottom-0 w-full border-t border-t-neutral-400 z-20',
                hideNavbarBottom && 'translate-y-[100%]'
            )}
        >
            <NavbarBottom/>
        </motion.footer>
    );
};

export default Footer;
