import React, {useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {
    EditSubscriptionPulloverContent
} from "@pages/merchant/subscriber/show/components/EditSubscriptionPulloverContent";


export function EditSubscriptionPullover() {
    const [openEditSubscription, SetOpenEditDescription] = useState(false)

    return (
        <PullOver
            variations={'max-height'}
            title={'Manage Subscription'}
            trigger={
                {
                    title: <p className={'w-full'}>Manage Subscription</p>,
                    onClick: () => SetOpenEditDescription(true),
                }
            }
            openState={[openEditSubscription, SetOpenEditDescription]}
            submit={
                {
                    title: 'Save',
                    onClick: () => {
                        SetOpenEditDescription(false)
                    }
                }
            }
            cancel={
                {
                    title: 'Cancel',
                    dialog: {
                        title: 'Cancel Editing Subscription?',
                        description: 'Are you sure you want to Cancel? Changes will not be saved.'
                    }
                }
            }
        >
            <EditSubscriptionPulloverContent/>

        </PullOver>

    )
}