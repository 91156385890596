import React from "react";
import {ChromePicker, ColorResult} from "react-color";
import {AccordionContent, AccordionTrigger} from "@src/@/components/ui/accordion";

interface ColorPickerComponentProps {
    propertyName: string
    index: number;
    selectedColor: string;
    textColor: string;
    handleOnComplete: (color: ColorResult, colorType: string) => void
}

const ColorPickerComponent: React.FC<ColorPickerComponentProps> = ({
    propertyName,
    selectedColor,
    textColor,
    handleOnComplete
}) => {
    return (
        <>
            <AccordionTrigger icon={false} className={'hover:no-underline p-0 w-full flex-grow flex'}>
                <div className={'w-full font-medium flex-grow py-2 rounded-md'}
                    style={{
                        backgroundColor: selectedColor,
                        color: textColor
                    }}>Set {propertyName === 'primaryColor' ? 'Primary' : 'Secondary'} Color
                    (current
                    color: {selectedColor})
                </div>
            </AccordionTrigger>
            <AccordionContent data-vaul-no-drag className={'flex justify-center'}>

                <ChromePicker
                    color={selectedColor}
                    disableAlpha={true}
                    onChange={(color) => handleOnComplete(color, propertyName)}
                    data-vaul-no-drag
                />
            </AccordionContent>
        </>
    )
}

export default ColorPickerComponent