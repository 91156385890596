export type MockMerchantTOSDataType = {
    id: string;
    title: string;
    tos: string
}

export const mockMerchantTOSData: MockMerchantTOSDataType[] = [
    {
        id: '1',
        title: 'Great Service',
        tos: `
# Terms of Service
_Last updated: August 6, 2024_

Welcome to Our Service! These terms of service outline the rules and regulations for the use of Our Service.

## 1. Acceptance of Terms

By accessing and using Our Service, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using Our Service, you shall be subject to any posted guidelines or rules applicable to such services.

## 2. Description of Service

Our Service provides users with access to a rich collection of resources, including various communications tools, forums, shopping services, personalized content, and branded programming through its network of properties.

## 3. User Conduct

You agree to use Our Service only for lawful purposes. You are prohibited from posting on or transmitting through Our Service any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit, profane, hateful, or otherwise objectionable material of any kind.

### 3.1 Prohibited Activities

- Using Our Service for any illegal purpose.
- Impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity.
- Interfering with or disrupting the service or servers or networks connected to the service.

## 4. Privacy Policy

We respect the privacy of our users. For details, please see our [Privacy Policy](#). By using Our Service, you consent to our collection and use of personal data as outlined therein.

## 5. Modifications to Service

We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.

## 6. Termination

You agree that We, in our sole discretion, may terminate your password, account (or any part thereof) or use of the Service, and remove and discard any Content within the Service, for any reason, including, without limitation, for lack of use or if We believe that you have violated or acted inconsistently with the letter or spirit of the TOS.

## 7. Disclaimer of Warranties

You expressly understand and agree that:

- Your use of the service is at your sole risk. The service is provided on an "as is" and "as available" basis.
- We make no warranty that (i) the service will meet your requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, and (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations.

## 8. Limitation of Liability

You expressly understand and agree that We shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if We have been advised of the possibility of such damages).

## 9. Governing Law

These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of the jurisdiction in which Our Service operates.

## 10. Changes to the Terms of Service

We reserve the right to update or change our Terms of Service at any time and you should check this Terms of Service periodically. Your continued use of the Service after we post any modifications to the Terms of Service on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms of Service.

---

If you have any questions about these Terms, please contact us at [support@example.com](mailto:support@example.com).
`
    },
    {
        id: '2',
        title: 'Subscription Y',
        tos: `
# Terms of Service

**Last Updated: August 7, 2024**

Welcome to **Acme Corp**! These Terms of Service (“Terms”) govern your use of our website and services. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree, please do not use our services.

## 1. Use of Our Services

### 1.1. Eligibility
You must be at least 18 years old to use our services.

### 1.2. Account Registration
You may need to register for an account to access certain features of our services. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

## 2. User Obligations

### 2.1. Compliance
You agree to comply with all applicable laws and regulations when using our services.

### 2.2. Prohibited Activities
You must not engage in any activity that disrupts or interferes with our services, or that harms or exploits other users.

## 3. Intellectual Property

### 3.1. Ownership
All intellectual property rights in our services and content are owned by us or our licensors. You may not use our intellectual property without our prior written consent.

### 3.2. User Content
You retain ownership of any content you submit to our services, but you grant us a license to use, display, and distribute your content in connection with our services.

## 4. Termination

We may suspend or terminate your access to our services if you violate these Terms or for any other reason at our discretion.

## 5. Limitation of Liability

To the fullest extent permitted by law, we are not liable for any indirect, incidental, or consequential damages arising from your use of our services.

## 6. Changes to These Terms

We may update these Terms from time to time. We will notify you of any significant changes by posting the new Terms on our website.

## 7. Contact Us

If you have any questions about these Terms, please contact us at support@acmecorp.com.

---

**Acme Corp**  
1234 Elm Street  
Springfield, IL 62704

`
    },
    {
        id: '3',
        title: 'Subscription Z',
        tos: `
# Terms of Service

**Effective Date: August 7, 2024**

Welcome to **Beta Solutions**! By using our website and services, you agree to these Terms of Service. Please read them carefully.

## 1. Access to Services

### 1.1. Eligibility Requirements
You must be at least 18 years old to use our services. By using our services, you represent that you meet these requirements.

### 1.2. Account Responsibilities
If you create an account, you agree to provide accurate information and keep your account secure. You are responsible for all activities that occur under your account.

## 2. User Conduct

### 2.1. Compliance with Laws
You agree to comply with all applicable laws and regulations while using our services.

### 2.2. Prohibited Conduct
You may not use our services to engage in unlawful activities or to harm others. This includes, but is not limited to, spamming, hacking, or distributing malware.

## 3. Intellectual Property Rights

### 3.1. Ownership
All content and materials provided through our services are owned by us or our licensors. You may not copy, modify, or distribute our content without permission.

### 3.2. User-Generated Content
You retain ownership of any content you submit to our services,
`
    }
]