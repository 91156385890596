import React, {FC} from 'react';
import {classNames} from "@modules/casting/String";

export type MessageProps = {
    message?: string,
    className?: string
}
const SuccessMessage: FC<MessageProps> = ({message, className}) => {
    return (<>
        <span
            className={classNames('font-semibold text-md text-center mx-auto w-full block py-2 rounded transition-opacity',
                message ? 'opacity-100' : 'opacity-0',
                className
            )}>
            {message}
        </span>
    </>);
};

export default SuccessMessage;
