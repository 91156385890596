import * as React from "react"

import {cn} from "@shadcn/lib/utils"
import {InputStyle} from "@templates/components/InputStyle";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({className, ...props}, ref) => {
        return (
            <textarea
                className={cn(InputStyle, 'min-h-[6rem]', className)}

                ref={ref}
                {...props}
            />
        )
    }
)
Textarea.displayName = "Textarea"

export {Textarea}
