import React, {Dispatch, SetStateAction, useState} from 'react'

import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import useGql from "@modules/graphql/useGql";
import {RiRefund2Line} from "react-icons/ri";
import {Button} from "@src/@/components/ui/button";
import {CustomerDetails} from "@pages/merchant/payment/show/components/PaymentDetails";

type FormTest = {
    amount: number;
    type: string;
    message?: string
}

const typeOptions = [
    {
        title: 'Euro',
        value: 'Euro'
    },
    {
        title: 'Percentage',
        value: 'Percentage'
    },
]

type TempType = {
    input: FormTest
}

interface RefundPulloverScreenProps {
    customer: CustomerDetails | undefined
    setCurrentCustomer: Dispatch<SetStateAction<CustomerDetails | undefined>>
}

export function RefundPulloverScreen({customer, setCurrentCustomer}: RefundPulloverScreenProps) {

    const [selectedType, setSelectedType] = useState<string | number>('Euro')
    const [showRefundPullover, setShowRefundPullover] = useState(false)

    const gql = useGql()

    const currDate = new Date()

    const formattedDate = currDate.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });

    function handleTypeChange(value: string | number) {

        /**
         *
         * Error shows up without setTimeout. Sorry but I honestly don't understand the issue, but according to my
         * research and testing it, this solves it...
         *
         * */

        setTimeout(() => {
            setSelectedType(value);
        }, 0);
    }

    const fields: FormFields<FormTest> = {
        amount: {
            zodType: z.coerce.number().min(1).max(selectedType === 'Euro' ? customer?.amount ?? 0 : 100),
            fieldType: 'input',
            placeholder: `From 1 to ${selectedType === 'Euro' ? `$${customer?.amount}` : '100%'}`,
            label: 'Amount',
        },
        type: {
            zodType: z.string(),
            fieldType: 'select',
            label: 'Type',
            defaultValue: 'Euro',
            options: typeOptions,
            onChange: handleTypeChange,
        },
        message: {
            zodType: z.string(),
            fieldType: 'textarea',
            placeholder: 'Add Message (Optional)',
            label: 'Message',
        },
    }

    async function handleSubmit(value: TempType) {
        await gql.GetUsers().then(() => {
            return setCurrentCustomer((prev) => {

                if (!prev) {
                    return prev
                }

                return {
                    ...prev,
                    status: 'reversed',
                    refundDate: formattedDate,
                    refundAmount: value.input.amount,
                    refundType: value.input.type
                }
            })
        }) // Temp
    }

    const onSubmitSuccess = () => {
        console.log('Success')
    }

    return (
        <PullOverForm
            trigger={
                {
                    title: <Button asChild
                        className={`flex items-center gap-x-0.5 bg-red-200 text-red-700 font-semibold`}>
                        <>
                            <RiRefund2Line size={'20px'}/> Refund
                        </>
                    </Button>,
                    variation: customer?.status !== 'paid' ? 'neutral' : 'negative',
                    disabled: customer?.status !== 'paid'
                }
            }
            openState={[showRefundPullover, setShowRefundPullover]}
            form={
                {
                    submit: handleSubmit,
                    fields,
                    onSubmitSuccess,
                    payloadInjection: {}
                }
            }
            title={'Name'}
            submit={
                {
                    title: 'Save'
                }
            }
            cancel={
                {
                    title: 'Cancel'
                }
            }


        />
    )
}