import React, {FC} from 'react';
import {Location as RRDLocation, Navigate, useLocation} from "react-router-dom";


type Props = Partial<Omit<RRDLocation, 'pathname'>> & {
    pathname: string
}

const Redirect: FC<Props> = ({pathname, ...props}) => {
    const {hash, search} = useLocation()
    return <Navigate
        to={{
            pathname,
            hash: props.hash ?? hash,
            search: props.search ?? search,
        }}
        state={props.state}
        replace={true}
        key={props.key}
    />;
};

export default Redirect;
