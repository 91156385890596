import React, {useEffect, useState} from 'react'
import {mockMerchantTOSData, MockMerchantTOSDataType} from "@app/mock/MockMerchantTosData";
import useParamId from "@hooks/useParamId";
import {Button} from "@src/@/components/ui/button";
import {ChevronRight} from "@mui/icons-material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import {useNavigate} from "react-router-dom";
import ShowPage from "@templates/page/ShowPage.template";

export function MerchantTOSShowPage() {
    const [data, setData] = useState<MockMerchantTOSDataType>() // I want to pass this data to the route

    const id = useParamId()

    const navigate = useNavigate()

    useEffect(() => {
        setData(mockMerchantTOSData.find(data => data.id === id))
    }, []);


    function handleOnClick() {
        navigate(`edit`)
    }

    return (
        <ShowPage>
            <div className={'space-y-4 -mx-shell'}>
                <button className={'border-y p-4 flex items-center text-neutral-900' +
                    ' bg-white'} onClick={handleOnClick}>
                    <div className={'flex-col'}>
                        <p className={'font-medium font-roboto text-lg text-primary text-left'}>{data?.title}</p>
                        <div className={'line-clamp-1 text-sm font-medium text-left'}>
                            <Markdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                className={'break-all hyphens-auto h-full'}
                            >
                                {data?.tos}
                            </Markdown>
                        </div>
                    </div>
                    <ChevronRight className={'text-primary'}/>
                </button>
                <div className={'flex gap-x-2 px-4'}>
                    <Button variant={'outline'} className={'flex-grow w-full'}>Edit ToS</Button>
                    <Button className={'flex-grow'}>Upload new ToS</Button>
                </div>
            </div>
        </ShowPage>
    )
}