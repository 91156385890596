import React, {useEffect, useRef, useState} from 'react';
import PullOver, {PullOverProps} from "@components/pullover/PullOver.component";
import Agreement, {ExtendAgreementProps} from "@components/agreement/Agreement.component";
import {MinimumTosFragment} from "@src/gql-schema";


export type ExtendPulloverAgreementProps = {
    onSubmit: () => Promise<void> | void,
    agreement?: MinimumTosFragment
    disabled?: boolean
}

interface Props extends PullOverProps {
    agreement: ExtendAgreementProps
}


const PullOverAgreement = (props: Props) => {

    const openState = useState(false);
    const setOpen = openState[1];

    const {
        title,
        description,
        trigger,
        submit,
        cancel,
        onClose
    } = props;

    const {children, ...agreement} = props.agreement

    const acceptClickedState = useState(false)
    const setAcceptClicked = acceptClickedState[1];
    const [canProceed, setCanProceed] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)

    const agreementRef = useRef<HTMLDivElement>(null)

    const handleTrigger = async () => {
        await trigger?.onClick?.()
    }


    const handleSubmitClick = () => {
        setAcceptClicked(true)
        if (!canSubmit) {
            if (agreementRef.current) {
                agreementRef.current.parentElement?.parentElement?.scrollTo({
                    top: Number.MAX_SAFE_INTEGER
                })
            }
        }
    }

    useEffect(() => {
        if (canProceed) {
            submit?.onClick?.()?.then(() => setOpen(false))
        }
    }, [canProceed]);


    return (<>
        <PullOver
            variations={'max-height'}
            trigger={trigger && {
                ...trigger,
                onClick: handleTrigger
            }}
            title={title}
            description={description}
            openState={openState}
            submit={{
                title: submit?.title,
                onClick: handleSubmitClick,
                disabled: !canSubmit,
                allowClickIfDisabled: true
            }}
            cancel={cancel && {
                ...cancel,
                dialog: {
                    title: cancel.dialog?.title ?? 'Cancel',
                    description: cancel.dialog?.description
                }
            }}
            onClose={onClose}
        >
            <Agreement
                ref={agreementRef}
                {...agreement}

                setCanProceed={setCanProceed}
                setCanSubmit={setCanSubmit}
                acceptClickedState={acceptClickedState}
            >
                {children}
            </Agreement>

        </PullOver>
    </>);
};

export default PullOverAgreement;
