import React, {FC} from 'react';
import {classNames} from "@modules/casting/String";
import BaseMessage, {MessageProps} from "@components/feedback/Message/BaseMessage";

const ErrorMessage: FC<MessageProps> = ({message, className}) => {
    return <BaseMessage
        message={message}
        className={classNames('bg-negative-300/20 text-negative-600', className)}
    />
};

export default ErrorMessage;