import React, {FC, InputHTMLAttributes} from 'react';
import {Search} from "@mui/icons-material";
import {Input} from "@src/@/components/ui/input";
import {classNames} from "@modules/casting/String";
import {InputStyle} from "@templates/components/InputStyle";

type Props = InputHTMLAttributes<HTMLInputElement>
const SearchInput: FC<Props> = (props) => {
    return <div className={'w-full relative'}>
        <Search className={'absolute top-1 left-1.5 text-neutral-500'}/>
        <Input {...props} className={classNames(InputStyle, '!pl-8', props.className)}/>
    </div>
};

export default SearchInput;
