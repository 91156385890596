import React, {FC, useEffect} from 'react';
import {useNavbarBottom} from "@components/navigation/NavbarBottom/NavbarBottom.context";
import BasePageTemplate, {ExtendPageProps} from "@templates/page/BasePage.template";


type Props = ExtendPageProps
const IndexPage: FC<Props> = (props) => {

    const navBottom = useNavbarBottom();
    const setHideNavbarBottom = navBottom.hide[1];

    useEffect(() => {
        setHideNavbarBottom(false)
    }, []);

    return <BasePageTemplate {...props} />;
};

export default IndexPage;
