import React, {useEffect} from 'react';
import NavbarTop from "@components/navigation/NavbarTop/NavbarTop.component";
import {motion, useAnimationControls} from "framer-motion";
import {useNavbarTop} from "@components/navigation/NavbarTop/NavbarTop.context";
import {classNames} from "@modules/casting/String";
import {useIsFirstRender} from "@uidotdev/usehooks";
import {useLocation} from "react-router-dom";

const Header = () => {

    const navbarTopCtx = useNavbarTop();
    const [hideNavbarTop] = navbarTopCtx.hide
    const headerAnimation = useAnimationControls()

    const isFirstRender = useIsFirstRender()

    const {pathname} = useLocation()

    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        headerAnimation.start({
            translateY: !hideNavbarTop ? '0%' : '-100%'
        })
    }, [hideNavbarTop]);

    return (
        <motion.header
            animate={headerAnimation}
            transition={{duration: 0.25}}
            className={classNames(
                `w-full p-shell bg-white shadow-md shadow-neutral-900/10 drop-shadow-xs fixed top-0 z-10 ${pathname === '/payments/list' && 'shadow-none z-50'}`,
                hideNavbarTop && 'translate-y-[-100%] opacity-0'
            )}
        >
            {<NavbarTop/>}
            {/* @todo searchbar support for index pages + context */}
        </motion.header>
    );
};

export default Header;
