import React, {FC} from 'react';
import {AddCircleOutline, Inventory2Outlined} from "@mui/icons-material";
import {Button, ButtonProps} from "@src/@/components/ui/button";
import {classNames} from "@modules/casting/String";


export const CreatButtonInner = () => <><AddCircleOutline className={'!text-[1rem] mr-1'}/> New</>

type Props = Omit<ButtonProps, 'children' | 'variant'>
const ArchiveButton: FC<Props> = (props) => {
    return (
        <Button {...props} variant={'link'}
            className={classNames('w-full !no-underline !text-neutral-600 border-t border-t-neutral-400 !rounded-none pt-5 pb-0', props.className)}>
            <Inventory2Outlined className={'!text-[1rem] mr-1'}/>
            Show archived
        </Button>
    );
};

export default ArchiveButton;
