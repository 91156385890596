import React, {useRef, useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {AddCompanyUserPulloverScreen} from "@pages/merchant/settings/company/components/AddCompanyUserPulloverScreen";
import {CompanyUser} from "@app/mock/MockCompanyData";

interface AddCompanyUserPulloverProps {
    addUser: (user: CompanyUser) => void
}

export type FormRef = {
    submit: () => void;
};

export function AddCompanyUserPullover({addUser}: AddCompanyUserPulloverProps) {
    const [showAddCompany, setShowAddCompany] = useState(false);
    const formRef = useRef<FormRef>(null);

    const handleSubmit = () => {
        formRef.current?.submit();
        setShowAddCompany(false);
    };

    return (
        <PullOver
            variations={'max-height'}
            openState={[showAddCompany, setShowAddCompany]}
            title={'Add User'}
            submit={
                {
                    title: 'Save',
                    onClick: () => handleSubmit()
                }
            }
            trigger={
                {
                    title: 'Add User',
                    onClick: () => setShowAddCompany(true)
                }
            }
            cancel={
                {
                    title: 'Cancel'
                }
            }
        >
            <AddCompanyUserPulloverScreen ref={formRef} addUser={addUser}/>
        </PullOver>
    )
}