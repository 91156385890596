import {ChartData, ScriptableContext} from "chart.js";
import moment from "moment";

export type ChartDataPoint = {
    x: string,
    y: number
}

type DateType = 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly';
type Config = {
    xType: 'date' | 'number' | 'string',
    dateType: DateType
}

const getDateLabelFormat = (dateType: DateType, nDataPoints: number) => {
    switch (dateType) {
        case 'monthly':
            if (nDataPoints <= 3) {
                return 'MMMM'
            }
            return nDataPoints > 6 ? 'MM' : 'MMM'

        case 'hourly':
            return 'HH';
        case 'daily':
            return 'dd';
        case 'weekly':
            return 'WW';
        case 'yearly':
            return 'YYYY';
        default:
            return 'MM'
    }
}


/**
 * Parse x,y data points to ChartJS data.
 * Only tested on line-charts.
 *
 * @param dataPoints
 * @param config
 */
export const getChartDataSet = (dataPoints: ChartDataPoint[], config?: Config): ChartData<'line'> => {

    const {
        xType,
        dateType
    } = {
        xType: 'date',
        dateType: 'monthly',
        ...config
    } as Config;

    const getData = () => {
        return dataPoints.map(dp => dp.y)
    }

    const getLabels = () => {
        if (xType == 'date') {
            const dateLabelFormat = getDateLabelFormat(dateType, dataPoints.length)
            return dataPoints.map(dp => moment(dp.x).format(dateLabelFormat))
        }
        return dataPoints.map(dp => dp.x);
    }

    return {
        labels: getLabels(),
        datasets: [
            {
                fill: 'start',
                data: getData(),
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.canvas.height - 168);
                    gradient.addColorStop(0, 'hsla(216,82%,63%, 100%)');
                    gradient.addColorStop(0.25, 'hsla(216,82%,63%, 75%)');
                    gradient.addColorStop(0.5, 'hsla(216,82%,63%, 75%)');
                    gradient.addColorStop(0.75, 'hsla(216,82%,63%, 25%)');
                    gradient.addColorStop(0.9, 'hsla(216,82%,63%, 10%)');
                    gradient.addColorStop(1, "hsla(216,82%,63%, 0%)");
                    return gradient;
                },
                borderColor: "hsl(216,59%,51%)"
            }
        ]
    }
}
