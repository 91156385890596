import React, {FC} from 'react';
import DescriptionCard, {DescriptionCardType} from "@components/card/DescriptionCard.component";
import {GroupsOutlined} from "@mui/icons-material";
import Pill from "@components/indicator/Pill.component";
import {toEuroString} from "@modules/casting/Number";
import {classNames} from "@modules/casting/String";


export type SubscriptionItemCardProps = Omit<DescriptionCardType, 'badge'> & {
    price: number,
    activeSubscribers: number,
}
const SubscriptionItemCard: FC<SubscriptionItemCardProps> = ({
    price,
    activeSubscribers,
    ...props
}) => {
    return <DescriptionCard
        {...props}
        className={classNames('px-3 py-shell h-fit w-full', props.className)}
        context={<span className={'font-medium text-primary'}>{toEuroString(price)}</span>}
        badge={<Pill variation={"secondary"} Icon={GroupsOutlined} title={activeSubscribers}/>}
    />
};

export default SubscriptionItemCard
