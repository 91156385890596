import React from "react";
import {AddCompanyUserPullover} from "@pages/merchant/settings/company/components/AddCompanyUserPullover";
import {CompanyUser} from "@app/mock/MockCompanyData";

interface FooterAddUserButtonProps {
    addUser: (user: CompanyUser) => void
}

export function FooterAddUserButton({addUser}: FooterAddUserButtonProps) {
    return (
        <div className={'px-4 mt-4'}>
            <AddCompanyUserPullover addUser={addUser}/>
        </div>
    )
}