import React, {useEffect, useState} from 'react'
import {Editor} from "@components/markdown/Editor";
import useParamId from "@hooks/useParamId";
import {mockMerchantTOSData} from "@app/mock/MockMerchantTosData";
import ListPage from "@templates/page/ListPage.template";

export function MerchantTOSEditor() {
    const [data, setData] = useState<string>('')

    const id = useParamId()

    useEffect(() => {
        const findData = mockMerchantTOSData.find(data => data.id === id)
        if (findData) {
            setData(findData.tos)
        }

    }, [id]);

    function saveMarkDown(value: string) {
        setData(value)
    }

    useEffect(() => {
        console.log(data)
    }, [data]);

    // @todo navbar should accept buttons witch onClick events
    return (
        <ListPage next={{
            title: 'Save',
            path: '#'
        }}>
            <Editor markdown={data} saveMarkDown={saveMarkDown}/>
        </ListPage>
    )
}