import React, {useState} from 'react'
import PullOver from "@components/pullover/PullOver.component";
import {SubscriberFilterPullover} from "@pages/merchant/subscriber/list/components/SubscriberFilterPullover";
import FilterButton from "@components/button/FilterButton";
import SearchBar from "@components/search/SearchBar.component";

export function SubscriberSearchFilterSection() {
    const [showFilterPullover, setShowFilterPullover] = useState(false)

    return (
        <div className={'flex items-center'}>
            <SearchBar
                className={'pt-shell w-full'}
                inputProps={{placeholder: 'Find payment'}}
                button={
                    <PullOver
                        variations={'max-height'}
                        title={'Filter'}
                        trigger={
                            {
                                title: <FilterButton asChild/>,
                                onClick: () => setShowFilterPullover(true)
                            }
                        }
                        openState={[showFilterPullover, setShowFilterPullover]}
                        submit={
                            {
                                title: 'Save',
                                onClick: () => {
                                    setShowFilterPullover(false)
                                }
                            }
                        }
                        cancel={
                            {
                                title: 'Cancel'
                            }
                        }
                    >
                        <SubscriberFilterPullover/>
                    </PullOver>
                }
            />

        </div>
    )
}