import {I18nDictionary} from "@config/i18n";


export default {
    'payments': {
        'title': {
            'singular': 'Betaling',
            'plural': 'Betalingen'
        }
    },
    'subscriptions': {
        'title': {
            'singular': 'Subscriptie',
            'plural': 'Subscripties'
        }
    },
    'subscribers': {
        'title': {
            'singular': 'Lid',
            'plural': 'Leden'
        }
    },
    'settings': {
        'title': {
            'singular': 'Instelling',
            'plural': 'Instellingen'
        }
    },
    'auth': {
        'login': 'Inloggen',
        'logout': 'Uitloggen'
    },
    'otp': {
        'text': {
            'verify': 'Vul uw authenticator code in.',
            'incorrect': 'De verificatie code is incorrect!',
            'block': 'Uw authenticator is geblokkeerd.',
            'enter': 'Vul de verification Code in',
            'qr': {
                'failure': 'Niet ingelogd of incorrect account.',
                'scan': 'Scan de QR-code hieronder met een ondersteunde authenticator-app. (Google Authenticator & Microsoft Authenticator)',
                'scant': 'Kan je de QR-code niet scannen? Voer in plaats daarvan deze code in je authenticator-app in:',
            }
        }
    }
} satisfies I18nDictionary